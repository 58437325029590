import React from 'react';
import PropTypes from 'prop-types';
import ConcentrationPopover from "./ConcentrationPopover";
import LiquidVaporPopover from "./LiquidVaporPopover";
import VaporPopover from "./VaporPoover";
import FluidInputType from "../FluidInputType.ts";
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import FluidsPropTypes from "../PropTypes/FluidsPropTypes";

const InfoPopover = ({
  anchorEl,
  isOpen,
  onSubmit,
  onAbort,
  userSelection,
  fluids,
  fluidInputType
}) => {
  if (!isOpen) return null;
  if (!userSelection) return null;

  const x = fluids.find(x => x.isLeaf === true && x.id === userSelection.fluidId);

  if (!x) return null;

  function getConcentrationPopover() {
    // openPop();
    return (<ConcentrationPopover
      userSelection={userSelection}
      anchorEl={anchorEl}
      onSubmit={onSubmit}
      onAbort={onAbort}
      isOpen={isOpen}
      concentrationProperties={x.concentrationProperties}/>);
  }

  // eslint-disable-next-line react/no-multi-comp
  function getLiquidVaporPopover() {
    return (<LiquidVaporPopover
      userSelection={userSelection}
      anchorEl={anchorEl}
      onSubmit={onSubmit}
      onAbort={onAbort}
      isOpen={isOpen}
      liquidProperties={x.liquidProperties}
      vaporProperties={x.vaporProperties}/>);
  }

  // eslint-disable-next-line react/no-multi-comp
  function getVaporPopover() {
    return (<VaporPopover
      userSelection={userSelection}
      anchorEl={anchorEl}
      onSubmit={onSubmit}
      onAbort={onAbort}
      isOpen={isOpen}
      vaporProperties={x.vaporProperties}/>);
  }

  function getNull() {
    return null;
  }

  const maps = new Map([
    [FluidInputType.SinglePhase, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getConcentrationPopover], ["LIQVAP", getLiquidVaporPopover], ["VAP", getVaporPopover]])],
    [FluidInputType.TwoPhase, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getNull], ["LIQVAP", getNull], ["VAP", getNull]])],
    [FluidInputType.FluidProperties, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getConcentrationPopover], ["LIQVAP", getVaporPopover], ["VAP", getVaporPopover]])]
  ]);

  return maps.get(fluidInputType).get(x.category)();
};

InfoPopover.propTypes = {
  anchorEl: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  userSelection: UserSelectionPropTypes,
  fluids: FluidsPropTypes.isRequired,
  fluidInputType: PropTypes.string
};

export default React.memo(InfoPopover);
