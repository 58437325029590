import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import {IconMulticalc} from "components/Icons";
import {connect} from "react-redux";
import {MC_REDUX_OPENFROMCALCWINDOW} from "../../constants/actionTypes";
import {useNavigate} from 'react-router-dom';
import {RouterPaths} from '../../containers/App/RouterPaths';

const OpenInMulticalcButton = props => {
  const navigate = useNavigate();


  const onClick = () => {
    props.openInMulticalc(props.calculation);
    navigate(RouterPaths.MULTICALC);
  };

  // console.log('OpenInMulticalcButton PROPS', props);

  return (
    <IconButton
      aria-describedby={"filters-actions"}
      tabIndex={props.tabIndex}
      title="Open in multicalc"
      onClick={onClick}
      size="large">
      <IconMulticalc size={28}/>
    </IconButton>
  );
};

OpenInMulticalcButton.propTypes = {
  path: PropTypes.string,
  tabIndex: PropTypes.number,
  iconProps: PropTypes.object,
  buttonClass: PropTypes.string,
  calculation: PropTypes.object,
  openInMulticalc: PropTypes.func
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    openInMulticalc: (calculation) => dispatch({type: MC_REDUX_OPENFROMCALCWINDOW, calculation })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenInMulticalcButton);

