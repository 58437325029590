import React from 'react';
import PropTypes from 'prop-types';
import ConcentrationInfoSection from "./ConcentrationInfoSection";
import LiquidVaporInfoSection from "./LiquidVaporInfoSection";
import VaporInfoSection from "./VaporInfoSection";
import FluidInputType from "../FluidInputType.ts";
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import FluidsPropTypes from "../PropTypes/FluidsPropTypes";

const InfoSection = ({
  userSelection,
  fluids,
  onValueChange,
  fluidInputType
}) => {

  const handleInputValueChange = (inputValue) => {
    onValueChange({...inputValue });
  };

  function getConcentrationInfoSection(x) {
    return (
      <ConcentrationInfoSection
        concentrationProperties={x.concentrationProperties}
        userSelection={userSelection}
        onUserSelectionChange={handleInputValueChange}/>
    );
  }

  // eslint-disable-next-line react/no-multi-comp
  function getLiquidVaporInfoSection(x) {
    return (
      <LiquidVaporInfoSection
        liquidProperties={x.liquidProperties}
        vaporProperties={x.vaporProperties}
        userSelection={userSelection}
        onUserSelectionChange={handleInputValueChange}/>
    );
  }

  // eslint-disable-next-line react/no-multi-comp
  function getVaporInfoSection(x) {
    return (
      <VaporInfoSection
        vaporProperties={x.vaporProperties}
        userSelection={userSelection}
        onUserSelectionChange={handleInputValueChange}/>
    );
  }

  function getNull() {
    return null;
  }

  const maps = new Map([
    [FluidInputType.SinglePhase, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getConcentrationInfoSection], ["LIQVAP", getLiquidVaporInfoSection], ["VAP", getVaporInfoSection]])],
    [FluidInputType.TwoPhase, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getNull], ["LIQVAP", getNull], ["VAP", getNull]])],
    [FluidInputType.FluidProperties, new Map([['LIQ', getNull], ['LIQ2', getNull], ["CONC", getConcentrationInfoSection], ["LIQVAP", getVaporInfoSection], ["VAP", getVaporInfoSection]])]
  ]);

  if (userSelection) {
    const x = fluids.find(x => x.isLeaf === true && x.id === userSelection.fluidId);
    return maps.get(fluidInputType).get(x.category)(x);
  } else {
    return null;
  }
};

InfoSection.propTypes = {
  fluids: FluidsPropTypes.isRequired,
  userSelection: UserSelectionPropTypes,
  onValueChange: PropTypes.func.isRequired,
  fluidInputType: PropTypes.string
};

export default React.memo(InfoSection);
