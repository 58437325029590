import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import {
  Operation,
  SimpleMessage,
  ValidationMessage
} from "./ValidationMessage.ts";

function isOneExchangerSelected(exchangers){
  if( exchangers.length === 1){
    if(exchangers[0].heatExchangerInputType === 'Child'){
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function AtLeastOneExchangerShouldBeSelected(fieldName, inputs, dv) {
  const validationField = dv[fieldName];
  if (inputs[fieldName].length > 0) {
    validationField.isValid = true;
  } else {
    validationField.isValid = false;
    validationField.validationMessages.push(new SimpleMessage("At least one heat exchanger must be selected"));
  }
}

export function OneHeatExchangerShouldBeSelected(fieldName, inputs, dv) {
  const validationField = dv[fieldName];
  if (isOneExchangerSelected(inputs[fieldName]) === true) {
    validationField.isValid = true;
  } else {
    validationField.isValid = false;
    validationField.validationMessages.push(new SimpleMessage("Exactly one heat exchanger must be selected"));
  }
}

export function OneFluidShouldBeSelected(fieldName, inputs, dv) {
  const validationField = dv[fieldName];
  if (inputs[fieldName]
    && Object.keys(inputs[fieldName]).length !== 0
    && inputs[fieldName].fluidId !== 0) {
    validationField.isValid = true;
  } else {
    validationField.isValid = false;
    validationField.validationMessages.push(new SimpleMessage("Fluid must be selected"));
  }
}

export function isNumberOfPlatesValid(numOfPlates){
  if (Number(numOfPlates) < 4) {
    return false;
  }
  if (((Number(numOfPlates) - 4) % 2) !== 0){
    return false;
  }
  return true;
}

export function isFormValid(validationObject){
  const isFormValid = !Object.values(validationObject).some(value => value.isValid === false);
  return isFormValid;
}

export function hasValue(value) {
  if (value=== null) return false;
  if (value === '') return false;
  if (typeof(value) === "undefined") return false;
  return true;
}

export function hasQuantityValue(quantityInput) {
  if(!quantityInput){
    return false;
  }
  const quantity = quantityInput.quantity;

  if (!quantity) return false;
  if (quantity.amount === null) return false;
  if (quantity.amount === '') return false;
  if (typeof(quantity.amount) === "undefined") return false;
  return true;
}

function hasTypeValue(value) {
  if (typeof value === 'number' || value === null || value === undefined) {
    return hasValue(value);
  } else {
    return hasQuantityValue(value);
  }
}

export function SomeFieldsShouldBeAdded(fieldNames, input, dv, numberOfValues) {
  const validationCombinationFieldThatNeedToBeChecked = [];
  fieldNames.forEach(fieldName => {
    if (!hasTypeValue(input[fieldName])) {
      const validationField = dv[fieldName];
      validationCombinationFieldThatNeedToBeChecked.push(validationField.translationKey);
      validationField.isValid = false;
      validationField.validationMessages.push(new ValidationMessage(Operation.Add, numberOfValues, validationCombinationFieldThatNeedToBeChecked));
    }
  });
}

export function SomeFieldsShouldBeRemoved(fieldNames, input, dv, numberOfValues) {
  const validationCombinationFieldThatNeedToBeChecked = [];
  fieldNames.forEach(fieldName => {
    if (hasTypeValue(input[fieldName])) {
      const validationField = dv[fieldName];
      validationCombinationFieldThatNeedToBeChecked.push(validationField.translationKey);
      validationField.isValid = false;
      validationField.validationMessages.push(new ValidationMessage(Operation.Remove, numberOfValues, validationCombinationFieldThatNeedToBeChecked));
    }
  });
}

export function ValidateWeightedLoadConditions(isWeightedLoadFieldName, valuesFieldName, inputs, dv) {
  if(!inputs || !dv || !isWeightedLoadFieldName || !valuesFieldName) {
    return false;
  }
  const isWeightedLoad = inputs[isWeightedLoadFieldName];
  const values = inputs[valuesFieldName];
  if (isWeightedLoad === true) {
    const sum = values.map(x => x.weight).reduce((prev, next) => prev + next);
    if (sum !== 1) {
      const validationField = dv[valuesFieldName];
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage("Sum of weights must equal 1!"));
    }
  }
}

export function NumberOfPlates(fieldName, inputs, dv) {
  const numberOfPlates = inputs[fieldName];
  const validationField = dv[fieldName];
  if (hasValue(numberOfPlates)) {
    let iValueLeft = (numberOfPlates - 4) % 2;
    if (!(iValueLeft === 0) && (numberOfPlates >= 4)) {
      const largerValue = numberOfPlates + (2 - iValueLeft);
      const smallerValue = largerValue - 2;
      if (smallerValue >= 6) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 4 + 2 * number of channels, that is either ${smallerValue} or ${largerValue} plates`));
      } else {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 4 + 2 * number of channels. For example ${largerValue} plates`));
      }
    } else if (numberOfPlates < 4) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage(`You need to have at least 4 plates`));
    }
  }
}

export function NormalDualNumberOfPlates(fieldName, inputs, dv) {
  const numberOfPlates = inputs[fieldName];
  const validationField = dv[fieldName];
  if (hasValue(numberOfPlates)) {
    const valueLeft = (numberOfPlates - 6) % 4;
    if ((valueLeft !== 0) && (numberOfPlates >= 6)) {
      const largerValue = numberOfPlates + (4 - valueLeft);
      const smallerValue = largerValue - 4;
      if (smallerValue >= 10) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 6 + 4 * number of channels, that is either ${smallerValue} or ${largerValue} plates`));
      } else {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 6 + 4 * number of channels. For example ${largerValue} plates`));
      }
    } else if (numberOfPlates < 6) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage(`You need to have at least 6 plates`));
    }
  }
}

export function NumberOfPlatesPreStage(fieldName, inputs, dv) {
  const numberOfPlates = inputs[fieldName];
  const validationField = dv[fieldName];
  if (hasValue(numberOfPlates)) {
    let iValueLeft = numberOfPlates % 2;
    if ((iValueLeft !== 0) && (numberOfPlates >= 6)) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage("Specify even number of plates for pre-stage (stage 1)"));
    } else if (numberOfPlates < 6) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage("You need to have at least 6 plates for pre-stage!"));
    }
  }
}

export function NumberOfPlateAfterStage(fieldName, inputs, dv) {
  const numberOfPlates = inputs[fieldName];
  const validationField = dv[fieldName];
  if (hasValue(numberOfPlates)) {
    let iValueLeft = numberOfPlates % 2;
    if ((iValueLeft !== 1) && (numberOfPlates >= 7)) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage("Specify odd number of plates for after-stage (stage 2)"));
    } else if (numberOfPlates < 6) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage("You need to have at least 7 plates for after-stage !"));
    }
  }
}

export function IsBackToBackDual(availableExchangers = [], exchanger) {
  if(exchanger && exchanger.length !== 1){
    return false;
  }
  const he = availableExchangers.find(x => x.id === exchanger[0].id && x.isLeaf === true);
  if (he && he.isLeaf === true && he.xpc && he.xpc.execution && he.xpc.execution.toUpperCase() === "D") return true;
  return false;
}

export function BackToBackNumberOfPlates(fieldName, inputs, dv) {
  const numberOfPlates = inputs[fieldName];
  const validationField = dv[fieldName];

  if (hasValue(numberOfPlates)) {
    const valueLeft = (numberOfPlates - 8) % 4;
    if ((valueLeft !== 0) && (numberOfPlates >= 8)) {
      const largerValue = numberOfPlates + (4 - valueLeft);
      const smallerValue = largerValue - 4;
      if (smallerValue >= 10) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 8 + 4 * number of channels, that is either ${smallerValue} or ${largerValue} plates`));
      } else {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`Wrong number of plates, need to follow this formula: Number of plates = 8 + 4 * number of channels. For example ${largerValue} plates`));
      }
    } else if (numberOfPlates < 8) {
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage(`You need to have at least 8 plates`));
    }
  }
}

export function CheckGivenPasses(fieldName, inputs, dv) {
  const passes = inputs[fieldName];
  if (hasValue(passes)) {
    if (passes < 1) {
      const validationField = dv[fieldName];
      validationField.isValid = false;
      validationField.validationMessages.push(new SimpleMessage("Number of passes should be greater or equal to 1"));
    }
  }
}

export function NumberOfPlatesAndPasses(numberOfPlatesFieldName, numberOfPassesFieldName, inputs, dv) {
  const numberOfPlates = inputs[numberOfPlatesFieldName];
  const numberOfPasses = inputs[numberOfPassesFieldName];
  const validationField = dv[numberOfPlatesFieldName];
  if (hasValue(numberOfPlates) && hasValue(numberOfPasses)) {
    if (numberOfPasses <= 1) {
      NumberOfPlates(numberOfPlatesFieldName, inputs, dv);
    } else {
      const minPlates = 2 * numberOfPasses + 1;
      const mod = ((numberOfPlates - 1) / numberOfPasses) % 2;
      if (!(mod === 0) && (numberOfPlates >= minPlates)) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage("If you have more than one pass, (number of plates - 1) must be even dividable by number of passes"));
      } else if (numberOfPlates < minPlates) {
        validationField.isValid = false;
        validationField.validationMessages.push(new SimpleMessage(`You need to have at least ${minPlates} plates when using ${numberOfPasses} Passes`));
      }
    }
  } else if (hasValue(numberOfPlates) && !hasValue(numberOfPasses)) {
    NumberOfPlates(numberOfPlatesFieldName, inputs, dv);
  }
}

export function TextMustBeFilled(fieldName, input, dv) {
  const text = input[fieldName];
  const validationField = dv[fieldName];
  if (isString(text) && !isEmpty(text)) {
    validationField.isValid = true;
    validationField.validationMessages = [];
  } else {
    validationField.isValid = false;
    validationField.validationMessages.push(new SimpleMessage(`${validationField.translationKey} is required`));
  }
}

export function QuantityIsRequired(fieldName, input, dv) {
  const quantity = input[fieldName];
  const validationField = dv[fieldName];

  if (hasQuantityValue(quantity)) {
    validationField.isValid = true;
    validationField.validationMessages = [];
  } else {
    validationField.isValid = false;
    validationField.validationMessages.push(new SimpleMessage(`${validationField.translationKey} is required`));
  }
}

export function EvapInVapQualityIsZeroWarning(fieldName, input, dv) {
  const validationField = dv[fieldName];
  validationField.isValid = false;
  validationField.validationMessages.push(new SimpleMessage(`\nUse Liquid evaporator calculation instead when calculating with Inlet vapor quality = 0!`));
}

export function isTwoStack(availableExchangers = [], exchanger) {
  if(exchanger && exchanger.length !== 1){
    return false;
  }
  const he = availableExchangers.find(x => x.id === exchanger[0].id);
  if (he && he.isLeaf === true && he.calculationMethod === "SingleMix") return true;
  return false;
}

export function shouldPortSwitchBeVisible(availableExchangers = [], exchanger){
  if(exchanger && exchanger.length !== 1){
    return false;
  }
  const he = availableExchangers.find(x => x.id === exchanger[0].id);
  if (he && he.isLeaf === true && he.calculationMethod !== "SingleMix" && he.portSwitchAllowed === true) return true;
  return false;
}

export function isPortSwitchAllowed(availableExchangers = [], exchanger) {
  if(exchanger && exchanger.length !== 1){
    return false;
  }
  const he = availableExchangers.find(x => x.id === exchanger[0].id);
  if (he && he.isLeaf === true && he.portSwitchAllowed === true) return true;
  return false;
}

export const NewLine = "\n\r";

export const attachMessageToFields = (message, fields, dv) => {
  fields.forEach(field => {
    dv[field].isValid = false;
    dv[field].validationMessages.push(new SimpleMessage(message));
  });
};
