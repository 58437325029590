export const SAVED_CALCULATION_LIST_LOAD_ALL = 'SAVED_CALCULATION_LIST_LOAD_ALL';
export const SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS = 'SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS';
export const SAVED_CALCULATION_LIST_LOAD_ALL_ERROR = 'SAVED_CALCULATION_LIST_LOAD_ALL_ERROR';

export const SAVED_CALCULATION_LIST_DELETE_ONE = 'SAVED_CALCULATION_LIST_DELETE_ONE';
export const SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS = 'SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS';
export const SAVED_CALCULATION_LIST_DELETE_ONE_ERROR = 'SAVED_CALCULATION_LIST_DELETE_ONE_ERROR';

export const SAVED_CALCULATION_LIST_SAVE_CHANGES = 'SAVED_CALCULATION_LIST_SAVE_CHANGES';
export const SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS = 'SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS';
export const SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR = 'SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR';

export const CALCULATION_INPUTS_TAGS_AUTOCOMPLETE = 'CALCULATION_INPUTS_TAGS_AUTOCOMPLETE';
export const CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS = 'CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS';
export const CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR = 'CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR';