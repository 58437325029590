import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { UnitSystemAwareQuantityInputValue, UnitSystemAwareQuantityInputUnit } from "../../calculation/result/TechnicalData/createDisplayObject";

const RangeInfo = ({
  minQuantityInput,
  maxQuantityInput,
  densities = null
}) => {
  const {t} = useTranslation();

  return (
    <Typography variant="subtitle2">
      <span>{t("Range")} </span>
      <UnitSystemAwareQuantityInputValue quantityInput={minQuantityInput} densities={densities} />
      <span> - </span>
      <UnitSystemAwareQuantityInputValue quantityInput={maxQuantityInput} densities={densities} />
      <span> </span>
      <UnitSystemAwareQuantityInputUnit quantityInput={maxQuantityInput} />
    </Typography>
  );
};

RangeInfo.propTypes = {
  minQuantityInput: PropTypes.object.isRequired,
  maxQuantityInput: PropTypes.object.isRequired,
  densities: PropTypes.object,
};

export default React.memo(RangeInfo);
