import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import checkStore from './checkStore';
import createReducer from '../reducers';
import storage from 'redux-persist-indexeddb-storage';
import {persistReducer} from 'redux-persist';



export function injectReducerFactory(store, isValid) {
  return function injectReducer(key, reducer, isPersistent = false) {
    // console.log('injectReducer', key);
    if (!isValid) checkStore(store);

    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      '(app/utils...) injectReducer: Expected `reducer` to be a reducer function',
    );

    // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
    if (
      Reflect.has(store.injectedReducers, key) &&
      store.injectedReducers[key] === reducer
    )
      return;

    isPersistent = false;

    if (isPersistent) {
      const persistConfig = {
        key: key,
        storage: storage('DX')
      };
      store.injectedReducers[key] = persistReducer(persistConfig, reducer);
    }
    else {
      store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    }

    store.replaceReducer(createReducer(store.injectedReducers));
  };
}

export function ejectReducerFactory(store, isValid) {
  return function ejectReducer(key) {
    // console.log('ejectReducer', key);
    if (!isValid) checkStore(store);
    invariant(
      isString(key) && !isEmpty(key),
      '(app/utils...) injectReducer: Expected `reducer` to be a reducer function',
    );

    // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
    if (!Reflect.has(store.injectedReducers, key))
      return;

    delete store.injectedReducers[key]; // eslint-disable-line no-param-reassign
    if (store.hydratationState) {
      delete store.hydratationState[key];
    }

    store.replaceReducer(createReducer(store.injectedReducers));
  };
}

export default function getInjectors(store) {
  checkStore(store);

  return {
    injectReducer: injectReducerFactory(store, true),
    ejectReducer: ejectReducerFactory(store, true),
  };
}
