export const CALCULATION_ADD = 'CALCULATION_ADD';
export const CALCULATION_CLONE = 'CALCULATION_CLONE';
export const CALCULATION_XREF_LOAD = 'CALCULATION_XREF_LOAD';
export const CALCULATION_LOAD = 'CALCULATION_LOAD';
export const CALCULATION_INPUTS_LOAD = 'CALCULATION_INPUTS_LOAD';
export const CALCULATION_INPUTS_LOAD_SUCCESS = 'CALCULATION_INPUTS_LOAD_SUCCESS';
export const CALCULATION_INPUTS_LOAD_ERROR = 'CALCULATION_INPUTS_LOAD_ERROR';
export const CALCULATION_INPUTS_CHANGE = 'CALCULATION_INPUTS_CHANGE';
export const CALCULATION_SET_VALIDITY = 'CALCULATION_SET_VALIDITY';
export const CALCULATION_RELOAD_HEAT_EXCHANGERS = 'CALCULATION_RELOAD_HEAT_EXCHANGERS';
export const CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS = 'CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS';
export const CALCULATION_RELOAD_HEAT_EXCHANGERS_ERROR = 'CALCULATION_RELOAD_HEAT_EXCHANGERS_ERROR';
export const CALCULATION_FILTERS_CHANGE = 'CALCULATION_FILTERS_CHANGE';
export const CALCULATION_CALCULATE = 'CALCULATION_CALCULATE';
export const CALCULATION_CALCULATE_SUCCESS = 'CALCULATION_CALCULATE_SUCCESS';
export const CALCULATION_CALCULATE_ERROR = 'CALCULATION_CALCULATE_ERROR';
export const CALCULATION_SELECTED_RESULT_CHANGE = 'CALCULATION_SELECTED_RESULT_CHANGE';
export const CALCULATION_SELECTED_SUB_RESULT_CHANGE = 'CALCULATION_SELECTED_SUB_RESULT_CHANGE';
export const CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD = 'CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD';
export const CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS = 'CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS';
export const CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE = 'CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE';
export const CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE = 'CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE';
export const CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS = 'CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS';
export const CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE = 'CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE';
export const CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE = 'CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE';
export const CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS = 'CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS';
export const CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS = 'CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS';

export const CALCULATION_FROM_BASKET_LOAD = 'CALCULATION_FROM_BASKET_LOAD';
export const CALCULATION_FROM_BASKET_SUCCESS = 'CALCULATION_FROM_BASKET_SUCCESS';
