import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const SavedCalculationInputsShareDialog = ({ url, isOpen, onClose}) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(url);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth={false} >
      <DialogTitle id="simple-dialog-title">Would you like to share calculation?</DialogTitle>
      <DialogContent>
        <div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <IconButton onClick={handleCopyToClipboard} size="large">
                <FileCopyOutlinedIcon/>
              </IconButton>
            </Grid>
            <Grid item>
              <Paper sx={{
                padding: theme => theme.spacing(2),
                textAlign: 'center',
                color: theme => theme.palette.text.secondary,
              }}>
                <span>{url}</span>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

SavedCalculationInputsShareDialog.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SavedCalculationInputsShareDialog;
