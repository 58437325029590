import Dexie from 'dexie';

const db = new Dexie('calculations');
db.version(1).stores({
  calculations: 'uuid, timestampCreated'
});

const saveCalculation = async (calculation) => {
  try {
    await db.calculations.put(calculation, calculation.uuid);
  } catch (e) {
    // console.error('An error occurred: ', e);
  }
};

const saveCalculations = async (calculations) => {
  await db.calculations.bulkPut(calculations);
};

const loadCalculation = async (uuid) => {
  try {
    const calculation = await db.calculations.get(uuid);
    return calculation;
  } catch (e) {
    // console.error('An error occurred: ', e);
  }
};

const deletePouchDB = async () => {
  await db.calculations.clear();
};

// const cleanPouchDB = async () => {
//   const now = new Date();
//   const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
//
//   await db.calculations.where('timestampCreated').below(weekAgo).delete();
// };

// keep only 10 latest calculation by timestampCreated
const cleanPouchDB = async () => {
  const calculations = await db.calculations.orderBy('timestampCreated').reverse().offset(10).toArray();
  const uuids = calculations.map(c => c.uuid);

  await db.calculations.bulkDelete(uuids);
};

export {
  saveCalculation,
  saveCalculations,
  loadCalculation,
  deletePouchDB,
  cleanPouchDB
};
