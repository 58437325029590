import useUnitSystemState from './useUnitSystemState';
import useUnitSystemDispatch from './useUnitSystemDispatch';

const useUnitSystem = () => {
  const unitSystem = useUnitSystemState();
  const setUnitSystem = useUnitSystemDispatch();

  return [unitSystem, setUnitSystem];
};

export default useUnitSystem;
