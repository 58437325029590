import React from 'react';
import PropTypes from 'prop-types';
import QuantityInput from './QuantityInput';

const  handleConversion = (amount) => {
    return Promise.resolve(amount);
};

const PercentageQuantityInput = ({quantityInput, isValid, onChange, ...others}) => {
    return (
        <QuantityInput
            quantityInput={quantityInput}
            isValid={isValid}
            onChange={onChange}
            conversionFunction={handleConversion}
            {...others} />
    );
};

PercentageQuantityInput.propTypes = {
  quantityInput: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readonly: PropTypes.bool,
  amountComponentProps: PropTypes.object,
  unitComponentProps: PropTypes.object
};

export default React.memo(PercentageQuantityInput);
