import React, {useState, useMemo, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import InfoPopover from "./InfoSection/InfoPopover";
import {
  mapFluid
} from 'utils/calculationParamsHelper';
import '../../styles/fluid-select.scss';
import ValidationTooltip from "../calculation/inputForms/validations/ValidationTooltip";
import InfoSection from "./InfoSection/InfoSection";
import FluidInputType from "./FluidInputType.ts";
import UserSelectionPropTypes from "./PropTypes/UserSelectionPropTypes";
import TreeSelect, {listToTree} from 'components/common/TreeSelect/TreeSelect';
import {useTranslation} from "react-i18next";

export const copyMissingFluidsToAllFluids = (parentId, fluids) => {
  const allFluids = fluids.concat(fluids.filter(f => f.isLeaf && f.parentId !== 0).map(f => {
    const duplicate = {...f};
    duplicate.parentId = parentId;
    duplicate.pId = parentId;
    duplicate.key = 'all_' + f.key;
    duplicate.value = duplicate.key;
    // duplicate.value = 'all_' + f.key;
    return duplicate;
  }));
  return allFluids;
};

// const setValues = (arrayData, val) => {
//   arrayData.forEach((x, index, theArray) => {
//     if(val){
//       theArray[index].checked = x.originId === val.fluidId && x.parentId !== 19;
//     } else {
//       theArray[index].checked = false;
//     }
//     if(x.children.length > 0) {
//       setValues(x.children, val);
//     }
//   });
// };

const FluidSelect = ({
  fluids,
  value,
  onFluidChange,
  isValid,
  messages,
  fluidInputType = FluidInputType.SinglePhase,
  tabIndex
}) => {
  const { t: tf } = useTranslation('fluids');
  const [fluidsTree, setFluidsTree] = useState([]);
  const anchorEl = useRef(null);

  const availableFluids = useMemo(() => {
    const mappedFluids = fluids.map(mapFluid);
    const allFluids = copyMissingFluidsToAllFluids(19, mappedFluids);
    // console.log(allFluids);
    const remmapped = allFluids.map((x, index) => {
      const fluidGridTypeClass = x.isGrid? 'grid-fluid': 'classic-fluid';
      let initialCheck = {};
      if(value.fluidId === x.id){
        initialCheck = {checked : true};
      }
      return {...x, ...initialCheck,
        id: x.isLeaf ? index + 100: x.id,
        disabled: !x.isLeaf,
        className: `${x.isLeaf? 'fluid-item': 'fluid-parent'} ${fluidGridTypeClass}`,
        originId: x.id,
        label: tf(x.name),
        children: []};
    });
    setFluidsTree(listToTree(remmapped));
    return remmapped;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fluids, value, tf]);


  const mappedValue = useMemo(() => {
    if(availableFluids.length > 0 && value.fluidId > 0) {
      const tmp1 = availableFluids.find(f => f.originId === value.fluidId && f.isLeaf === true);
      tmp1.inputValue = Number(value.inputValue);
      tmp1.isGas = value.isGas;
      return tmp1;
    }
    return { title:'', category: 'LIQ'};
  }, [availableFluids, value]);

  const [showPopper, setShowPopper] = useState(false);
  //const addInfoSection = useMemo(() => ['CONC', 'LIQVAP', 'VAP'].includes(mappedValue.category) && !twoPhase, [mappedValue.category, twoPhase]);

  const addInfoSection = useMemo(() => {
    switch (fluidInputType) {
      case FluidInputType.SinglePhase:
        return ['CONC', 'LIQVAP', 'VAP'].includes(mappedValue.category);
      case FluidInputType.TwoPhase:
        return false;
      case FluidInputType.FluidProperties:
        return ['CONC', 'LIQVAP', 'VAP'].includes(mappedValue.category);
      default:
        throw "Unsupported fluid input type";
    }
  }, [fluidInputType, mappedValue.category]);


  const [userSelectionTemp, setUserSelectionTemp] = useState(null);

  const onChange = useCallback((value) => {
    if (!value) {
      onFluidChange({ fluidId: 0, inputValue: null, isGas: false });
      return;
    }
    if (value && value.isLeaf){
      switch (fluidInputType) {
        case FluidInputType.SinglePhase:
          if (['LIQ', "LIQ2"].includes(value.category)) {
            onFluidChange({ fluidId: value.originId, inputValue: null, isGas: false });
          } else if (['CONC'].includes(value.category)) {
            setUserSelectionTemp({ fluidId: value.originId, inputValue: null, isGas: false });
            setShowPopper(true);
          } else if (['LIQVAP'].includes(value.category)) {
            setUserSelectionTemp({ fluidId: value.originId, inputValue: null, isGas: true });
            setShowPopper(true);
          } else if (['VAP'].includes(value.category)) {
            setUserSelectionTemp({ fluidId: value.originId, inputValue: null, isGas: false });
            setShowPopper(true);
          } else {
            throw "Fluid select unknown fluid category";
          }
          break;
        case FluidInputType.TwoPhase:
          onFluidChange({ fluidId: value.originId, inputValue: null, isGas: false });
          break;
        case FluidInputType.FluidProperties:
          if (['LIQ', "LIQ2"].includes(value.category)) {
            onFluidChange({ fluidId: value.originId, inputValue: null, isGas: false });
          } else if (['CONC'].includes(value.category)) {
            setUserSelectionTemp({ fluidId: value.originId, inputValue: null, isGas: false });
            setShowPopper(true);
          } else if (['LIQVAP', "VAP"].includes(value.category)) {
            setUserSelectionTemp({ fluidId: value.originId, inputValue: null, isGas: true });
            setShowPopper(true);
          } else {
            throw "Fluid select unknown fluid category";
          }
          break;
      }
    } else {
      onFluidChange({ fluidId: 0, inputValue: null, isGas: false });
    }
  }, [fluidInputType, onFluidChange]);

  const handleSubmit = (value) => {
    setShowPopper(false);
    onFluidChange(value);
  };

  const handleAbort = () => {
    setShowPopper(false);
  };

  const handleInfoChange = useCallback((value) => {
    onFluidChange(value);
  },[onFluidChange]);

  // console.log('%cfluid select props','color:red', fluidInputType, availableFluids, fluidsTree, '\n\n', value, mappedValue, '\n\t\t\t', isValid, messages)

  return (
    <ValidationTooltip messages={messages}>
      <div className="d-flex" ref={anchorEl}>
        <div className={clsx('w-100',{'fluid-tree-60': addInfoSection}, { 'rc-tree-error': !isValid })}>
        <TreeSelect data={fluidsTree}
                    className={clsx('dtherm-ddl-select')}
                    placeholder=" "
                    mode="single"
                    onChange={onChange}
                    searchType={"fluid"}
                    tabIndex={tabIndex}
                    inlineSearchInput={true}
        />
        </div>
          {addInfoSection && (<InfoSection fluidInputType={fluidInputType} userSelection={value} fluids={fluids} onValueChange={handleInfoChange}/>)}
        <div id="additional-info">
          <InfoPopover isOpen={showPopper} fluidInputType={fluidInputType} userSelection={userSelectionTemp} fluids={fluids} anchorEl={anchorEl.current} onSubmit={handleSubmit} onAbort={handleAbort}/>
        </div>
      </div>
    </ValidationTooltip>
  );
};

FluidSelect.propTypes = {
  fluids: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string,
    id: PropTypes.number.isRequired,
    parentId: PropTypes.number.isRequired,
    isLeaf: PropTypes.bool.isRequired
  })).isRequired,
  value: UserSelectionPropTypes.isRequired,
  onFluidChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  messages: PropTypes.array,
  twoPhase: PropTypes.bool,
  fluidInputType: PropTypes.string,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

FluidSelect.defaultProps = {
  isValid: true
};

export default React.memo(FluidSelect);
