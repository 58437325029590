import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button} from "@mui/material";
import {useSelector} from 'react-redux';
import {makeSelectUserProfileFeatures} from '../../selectors/userProfileSelectors';
import {OutConditions} from '../../constants/calculationMethods';

const CalculationMethodSelector = ({ availableMethods, value, onChange, tabIndex }) => {
  const { t } = useTranslation();
  const features = useSelector(makeSelectUserProfileFeatures());

  const allowedMethod = availableMethods.filter(x => {
    if (x === OutConditions) {
      return !!features.includes('OUTCONDCALCULATION');
    }

    return true;
  });


  return (
      <ButtonGroup color="primary" size="small" variant="outlined" fullWidth={true} tabIndex={tabIndex} >
        {allowedMethod.map(m => (
          <Button key={m} onClick={() => onChange(m)} color="primary" variant={m === value ? "contained" : "outlined"}>{t(`CalcForm_${m}`)}</Button>
        ))}
      </ButtonGroup>
  );
};

CalculationMethodSelector.propTypes = {
  availableMethods: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default React.memo(CalculationMethodSelector);
