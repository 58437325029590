import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {processCalculation} from 'actions/calculationActions';
import { makeSelectIsCalcLoaded, makeSelectInputParamsValidity} from 'selectors/calculationSelectors';
import { useTranslation } from 'react-i18next';
import {Button} from "@mui/material";
import useUserProfile from '../common/hooks/useUserProfile';
import {ALLOW_CALCULATE_INVALID_INPUTS} from '../../constants/LicenseFeatures.ts';
import Typography from '@mui/material/Typography';

const CalculateButton = ({ calculationType, calcMethod }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCalcLoaded = useSelector(makeSelectIsCalcLoaded(calculationType));
  const isFormValid = useSelector(makeSelectInputParamsValidity(calculationType));
  const { features } = useUserProfile();

  const allowCalculateInvalidInputs = useMemo(() => {
    return features.includes(ALLOW_CALCULATE_INVALID_INPUTS);
  }, [features]);

  const calculate = useCallback(() => {
    dispatch(processCalculation(calculationType, calcMethod));
  }, [calcMethod, calculationType, dispatch]);

  const disabled = !isCalcLoaded || (allowCalculateInvalidInputs ? false : !isFormValid);

  return (
    <div className="ms-3 me-3">
      <Button tabIndex={100} color="primary" variant="contained" size="small" fullWidth={true} disabled={disabled} onClick={calculate}>{t("CalcForm_Calculate")}</Button>
      {allowCalculateInvalidInputs && <Typography variant="subtitle2" paragraph={true}>Calculation allowed with invalid inputs!</Typography>}
    </div>
   );
};

CalculateButton.propTypes = {
  calculationType: PropTypes.string.isRequired,
  calcMethod: PropTypes.string.isRequired,
};

export default React.memo(CalculateButton);
