import React from 'react';
import PropTypes from "prop-types";
import TotalsDataRow from './TotalsDataRow';
import { useTranslation } from 'react-i18next';

const Totals = (data) => {
		const { t } = useTranslation();
		const d = ["singlephase", "singlephasedual", "twostacks"].includes(data.calculation) ? data.data.filter(x => x.name !== "Totalweight") : data.data.filter(x => x.name !== "Totalweightempty" && x.name !== "Totalweightfilled");
    //const dataRows = d.filter(x => x.name !== "Totalweight" && (x.minValue || x.maxValue)).map(x => {
		const dataRows = d.filter(x => x.minValue || x.maxValue).map(x => {
        let sortNumber = 100;
        if(x.name.startsWith('Total')){
          sortNumber = 1;
        } else if(x.name.startsWith('Hold')) {
          sortNumber = 2;
        } else if (x.name.startsWith('Carbon')){
          sortNumber = 101;
        }
        return {
          ...x,
          sortNumber
        };
      }).sort((a,b) => a.sortNumber - b.sortNumber)
      .map(x => <TotalsDataRow key={x.name} dataRow={x}  />);

   // console.log(data)

    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="container">
              <table className="table table-condesed">
                <thead>
                <tr className="text-uppercase">
                  <th>{t("SWEP.SSP.Calculation_Dimensions.TOTALS")}</th>
                  <th>{t("SWEP.SSP.Calculation_Dimensions.DataUnit")}</th>
                  <th>{t("SWEP.SSP.Calculation_Dimensions.DataValue")}</th>
                </tr>
                </thead>
                <tbody className="tbl-totals">
                {dataRows}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <i>*{t("WeightSelectedProduct")}</i>
          </div>
        </div>
      </div>
    );
};

Totals.propTypes = {
		data: PropTypes.array.isRequired,
		calculation: PropTypes.string.isRequired
};

export default Totals;
