import React from "react";
import PropTypes from 'prop-types';
import messagesMapping from "../../ResultMessagesMapping";
import ResultMessages from "../../ResultMessages";
import WikiDialog from "../../../../WikiDialog/WikiDialog";

const Messages = ({ messages }) => {
  const [wikiAnchorElement, setWikiAnchorElement] = React.useState(null);
  const [wikiMessage, setWikiMessage] = React.useState(null);

  const onMessageIconClick = (anchorEl, message) => {
    if(anchorEl && messagesMapping[message]){
      setWikiAnchorElement(anchorEl);
      setWikiMessage(messagesMapping[message]);
      return anchorEl;
    }
    return null;
  };

  if(messages) {
    const msgs = messages.map(x => {
      return {...x, hasLink: !!messagesMapping[x.code]};
    });
    return (
      <div>
        <ResultMessages
          messages={msgs}
          onIconClick={onMessageIconClick}/>
        <WikiDialog
          anchorElement={wikiAnchorElement}
          setAnchorElement={setWikiAnchorElement}
          wikiMessage={wikiMessage}/>
      </div>
    );
  }
  return null;
};

Messages.propTypes = {
  messages: PropTypes.array,
};

export default React.memo(Messages);
