import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import {Button, Fade, Paper, Popper} from "@mui/material";
import {useTranslation} from "react-i18next";
import PressureQuantityInput from "../../common/QuantityInputs/PressureQuantityInput";
import get from 'lodash/get';
import VaporPropertiesPropTypes from "../PropTypes/VaporPropertiesPropTypes";
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import {UnitGroups, UnitGroupsNames, Pressure } from "../../../utils/unitGroups.ts";
import RangeInfo from "./RangeInfo";

const VaporPopover = ({
  isOpen,
  userSelection,
  onSubmit,
  // onAbort,
  vaporProperties,
  anchorEl
}) => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(userSelection.inputValue);
  }, [userSelection.inputValue]);
  const {t} = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({...userSelection, inputValue: value });
  };

  const hasValueError = useCallback((quantity) => {
    const value = get(quantity, 'amount', null);
    if (value == null) return true;
    if (value === "") return true;

    const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Pressure);
    const unit = unitGroup.getUnit(quantity.unit);
    const siValue = unit.convertToSI(quantity.amount);

    return !((vaporProperties.pVapMin <= Number(siValue)) && (Number(siValue) <= vaporProperties.pVapMax));
  }, [vaporProperties.pVapMax, vaporProperties.pVapMin]);

  const quantityInput = {
    quantity: value,
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const minQuantityInput = {
    quantity: {
      amount: vaporProperties.pVapMin,
      unit: Pressure.Pa
    },
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const maxQuantityInput = {
    quantity: {
      amount: vaporProperties.pVapMax,
      unit: Pressure.Pa
    },
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const handleQuantityInputChange = (quantityInput) => {
    setValue(quantityInput.quantity);
  };

  return (
    <Popper container={document.getElementById("additional-info")} open={isOpen} anchorEl={anchorEl} transition placement="right-start" style={{zIndex:1500}}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <Paper sx={{ p: 1 }}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <div className="p-1">
                  <Typography variant="button" color="primary" display="block">{t("Set")} {t("pressure")}</Typography>
                </div>
                <div className="p-1">
                  <PressureQuantityInput
                    tabIndex={0}
                    // amountComponentProps={useConstant({ autoFocus: true })}
                    quantityInput={quantityInput}
                    onChange={handleQuantityInputChange}
                    isValid={!hasValueError(value)}
                    messages={[]} />
                </div>
                <div className="p-1">
                  <RangeInfo minQuantityInput={minQuantityInput} maxQuantityInput={maxQuantityInput} />
                </div>
                <div className="p-1 d-flex justify-content-end">
                  <Button type="submit" tabIndex={0} color="primary" variant={"contained"} size="small" disabled={hasValueError(value)}>OK</Button>
                </div>
              </div>
            </form>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

VaporPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userSelection: UserSelectionPropTypes,
  onSubmit: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  vaporProperties: VaporPropertiesPropTypes.isRequired,
  anchorEl: PropTypes.object
};

export default VaporPopover;
