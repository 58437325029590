import React, {memo, Suspense, useCallback} from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/LoadingIndicator';
import './inputs.scss';
import UnitSystemProvider from "./unitSystem/UnitSystemProvider";
const AHRIInputs = React.lazy(() => import('./AHRIInputs'));
const SinglePhaseInputs = React.lazy(() => import('./SinglePhaseInputs'));
const EvaporatorInputs = React.lazy(() => import('./EvaporatorInputs'));
const LiquidEvaporatorInputs = React.lazy(() => import('./LiquidEvaporatorInputs'));
const EvaporatorDualInputs = React.lazy(() => import('./EvaporatorDualInputs'));
const CondenserInputs = React.lazy(() => import('./CondenserInputs'));
const CondenserDualInputs = React.lazy(() => import('./CondenserDualInputs'));
const SinglePhaseDualInputs = React.lazy(() => import('./SinglePhaseDualInputs'));
const CascadeInputs = React.lazy(() => import('./CascadeInputs'));
const AirDryerInputs = React.lazy(() => import('./AirDryerInputs'));
const TwoStageInputs = React.lazy(() => import('./TwoStageInputs'));
const DistrictEnergyInputs = React.lazy(() => import('./DistrictEnergyInputs'));
const HypertwainInputs = React.lazy(() => import('./HypertwainInputs'));

import {
  AHRI,
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  Hypertwain
} from '../../../constants/calculationTypes';

const CalculationInputsBase = ({ calculationType, calculationData, userId, onChangeInputs, onSetInputsValidity, loadCalculationInputsHeatExchangers, handleOnMethodChange, loadingInputs, hideButtons }) => {


  const handleChange = useCallback((values) => onChangeInputs(calculationType, values), [calculationType, onChangeInputs]);
  const setFormValidity = useCallback((isValid) =>  onSetInputsValidity(calculationType, isValid), [calculationType, onSetInputsValidity]);
  const handleReloadHeatExchangers = useCallback((modelRestrictions, coCurrent, selectedFluidSide1, selectedFluidSide2, calculationType) => {
    loadCalculationInputsHeatExchangers(calculationType.toLowerCase(), userId, modelRestrictions, coCurrent, selectedFluidSide1, selectedFluidSide2);
  }, [loadCalculationInputsHeatExchangers, userId]);

  const handleCalcMethodChange = useCallback((value) => {
    if(handleOnMethodChange) {
      handleOnMethodChange(value);
    } else {
      handleChange([{fieldName: "calculationMethod", value: value}]);
    }
  }, [handleOnMethodChange, handleChange]);

  if(loadingInputs){
    return <LoadingIndicator/>;
  }

  let calculationInputsFormComponent = null;
    switch(calculationType)
    {
        case AHRI:
          calculationInputsFormComponent = (<AHRIInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} />);
          break;
        case SinglePhase:
          calculationInputsFormComponent = <SinglePhaseInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />;
          break;
        case SinglePhaseDual:
          calculationInputsFormComponent = (<SinglePhaseDualInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case Evaporator:
        case EvaporatorHeatPump:
          calculationInputsFormComponent = (<EvaporatorInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case LiquidEvaporator:
          calculationInputsFormComponent = (<LiquidEvaporatorInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case EvaporatorDual:
          calculationInputsFormComponent = (<EvaporatorDualInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case Condenser:
        case CondenserHeatPump:
          calculationInputsFormComponent = (<CondenserInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case CondenserDual:
          calculationInputsFormComponent = (<CondenserDualInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case Cascade:
          calculationInputsFormComponent = (<CascadeInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} hideButtons={hideButtons} />);
          break;
        case AirDryer:
          calculationInputsFormComponent = (<AirDryerInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} />);
          break;
        case TwoStage:
          calculationInputsFormComponent = (<TwoStageInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} />);
          break;
        case DistrictEnergy:
          calculationInputsFormComponent = (<DistrictEnergyInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} />);
          break;
        case Hypertwain:
          calculationInputsFormComponent = (<HypertwainInputs calculationData={calculationData} onChange={handleChange} onCalcMethodChange={handleCalcMethodChange} setFormValidity={setFormValidity} onReloadHeatExchangers={handleReloadHeatExchangers} />);
          break;
        default:
          calculationInputsFormComponent = null;
          break;
    }

  return (
      <UnitSystemProvider>
        <Suspense fallback={<LoadingIndicator/>}>
          {calculationInputsFormComponent}
        </Suspense>
      </UnitSystemProvider>
    );
};

CalculationInputsBase.propTypes = {
  calculationType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  calculationData: PropTypes.object,
  loadingInputs: PropTypes.bool,
  onChangeInputs: PropTypes.func.isRequired,
  onSetInputsValidity: PropTypes.func.isRequired,
  loadCalculationInputsHeatExchangers: PropTypes.func.isRequired,
  hideButtons: PropTypes.bool,
  handleOnMethodChange: PropTypes.func,
};

export default memo(CalculationInputsBase);
