import React from "react";
import PropTypes from 'prop-types';
import  {
  Wrapper as Wrap,
  UnitSystemAwareValue1 as Usav,
  UnitSystemAwareUnit as Usau
} from "./TechnicalData/createDisplayObject";

const DimensionDataRow = ({ rowData }) => {
    if(!rowData.minValue && !rowData.maxValue){
        return null;
    }

    let val = rowData.minValue === rowData.maxValue
      ? <Usav unitGroup={rowData.unitType} value={rowData.minValue} unit={rowData.unit} />
      : <Wrap><Usav unitGroup={rowData.unitType} value={rowData.minValue} unit={rowData.unit} /> to <Usav unitGroup={rowData.unitType} value={rowData.maxValue}  unit={rowData.unit} /></Wrap>;
    let optionalVal = '';
    if(rowData.minValueOptional !== null){
        optionalVal = rowData.minValueOptional === rowData.maxValueOptional
          ? <Usav unitGroup={rowData.unitType} value={rowData.minValueOptional} unit={rowData.unit} />
          : <Wrap><Usav unitGroup={rowData.unitType} value={rowData.minValueOptional} unit={rowData.unit} /> to <Usav unitGroup={rowData.unitType} value={rowData.maxValueOptional} unit={rowData.unit} /></Wrap>;
        val = <Wrap>{val} (opt. {optionalVal})</Wrap>;
    }

    let tolerance = '';
    if(rowData.totalPositiveMm){
				let percentSign = rowData.name === "F" ? "%" : "";
        if(rowData.totalPositiveMm === rowData.totalNegativeMm){
				tolerance = <Wrap>+/- <Usav unitGroup={rowData.unitType} value={rowData.totalPositiveMm} unit={rowData.unit} />{percentSign}</Wrap>;
        } else {
          tolerance = <Wrap>+ <Usav unitGroup={rowData.unitType}  value={rowData.totalPositiveMm} unit={rowData.unit} />{percentSign} /- <Usav unitGroup={rowData.unitType} value={rowData.totalNegativeMm} unit={rowData.unit} />{percentSign}</Wrap>;
        }
		}

    return (
        <tr>
            <td>{rowData.name}</td>
            <td><Usau sspValue={{ unitGroup: rowData.unitType }} /></td>
            <td>{val}</td>
            <td>{tolerance}</td>
        </tr>
    );
};

DimensionDataRow.propTypes = {
    rowData: PropTypes.object.isRequired
};

export default DimensionDataRow;
