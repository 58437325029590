import React from 'react';
import PropTypes from 'prop-types';

const TabsWrapper = ({ children, value, index, className }) => {
  return (
    <div
      className={className}
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}>
      {children}
    </div>
  );
};

TabsWrapper.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
  className: PropTypes.string
};

export default TabsWrapper;
