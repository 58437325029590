import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { produce } from 'immer';
import ConcentrationPopover from "./ConcentrationPopover";
import get from 'lodash/get';
import PercentageQuantityInput from "../../common/QuantityInputs/PercentageQuantityInput";
import {UnitGroups, UnitGroupsNames, Percentage } from "../../../utils/unitGroups.ts";
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import ConcentrationPropertiesPropTypes from "../PropTypes/ConcentrationPropertiesPropTypes";

const buttonStyle = {
  height: "25px",
  fontSize: "14px",
  padding: "1px 6px"
};

const ConcentrationInfoSection = ({
  concentrationProperties,
  userSelection,
  onUserSelectionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSubmit = (value) => {
    setIsOpen(false);
    onUserSelectionChange(value);
  };

  const handleAbort = () => {
    setIsOpen(false);
  };

  const hasValueError = useCallback((quantity) => {
    const value = get(quantity, 'amount', null);
    if (value === "") return true;
    if (value == null) return true;

    const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Percentage);
    const unit = unitGroup.getUnit(quantity.unit);
    const siValue = unit.convertToSI(quantity.amount);

    return !((concentrationProperties.xMin <= Number(siValue)) && (Number(siValue) <= concentrationProperties.xMax));
  }, [concentrationProperties.xMax, concentrationProperties.xMin]);

  const handleQuantityInputChange = (quantityInput) => {
    onUserSelectionChange(produce(userSelection, draft => {
      draft.inputValue = quantityInput.quantity;
    }));
  };

  const quantityInput = {
    quantity: userSelection.inputValue,
    unitGroup: UnitGroupsNames.Percentage,
    defaultMetricUnit: Percentage.percent,
    defaultUsUnit: Percentage.percent
  };

  return (
    <div ref={anchorEl} className="fs-add-info">
      <div style={{width: '70%'}} >
        <div className={clsx('input-group input-group-sm')}>
          <PercentageQuantityInput
            quantityInput={quantityInput}
            onChange={handleQuantityInputChange}
            isValid={!hasValueError(userSelection.inputValue)}
            messages={[]} />
        </div>
      </div>
      <div style={{width: '30%'}}>
        <button
          className="btn btn-light quantity-inputs"
          style={buttonStyle}
          onClick={handleClick}
          type="button">{concentrationProperties.concentrationType}</button>

      </div>
      {isOpen && <ConcentrationPopover
        anchorEl={anchorEl.current}
        isOpen={isOpen}
        userSelection={userSelection}
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        concentrationProperties={concentrationProperties} />}
    </div>
  );
};


ConcentrationInfoSection.propTypes = {
  concentrationProperties: ConcentrationPropertiesPropTypes.isRequired,
  userSelection: UserSelectionPropTypes,
  onUserSelectionChange: PropTypes.func.isRequired,
};

export default React.memo(ConcentrationInfoSection);
