import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import useUserProfile from '../../common/hooks/useUserProfile';

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  backgroundColor: theme.palette.background.paper,
}));

const CalculationInputsActions = ({
  onSaveToMyCalculations,
  onSaveToCalculationType,
  onResetForCalculationType,
  onResetForAllCalculationTypes,
  onSaveFilterToCalculationType,
  onResetFilterToCalculationType,
  onSaveFilterToAllCalculations,
  onResetFilterToAllCalculations,
  tabIndex
}) => {
  const anchor = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const userProfile = useUserProfile();

  const handleSaveToMyCalculation = useCallback(() => {
    setIsOpen(false);
    onSaveToMyCalculations();
  }, [onSaveToMyCalculations]);

  const handleSaveForThis = useCallback(() => {
    setIsOpen(false);
    onSaveToCalculationType();
  }, [onSaveToCalculationType]);

  const handleResetForThis = useCallback(() => {
    setIsOpen(false);
    onResetForCalculationType();
  }, [onResetForCalculationType]);

  const handleResetForAllCalculationTypes = useCallback(() => {
    setIsOpen(false);
    onResetForAllCalculationTypes();
  }, [onResetForAllCalculationTypes]);

  return (<>
    <IconButton
      aria-describedby={"filters-actions"}
      tabIndex={tabIndex}
      ref={anchor}
      onClick={() => setIsOpen(true)}
      size="large">
      <MoreVertIcon />
    </IconButton>
    <Popover
      id={"filters-actions"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorEl={anchor.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <StyledList
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t('CalculationInputs.CalculationInputsActions', "Calculation inputs actions")}
          </ListSubheader>
        }>
        {userProfile.isSavingCalculationsEnabled &&
          <ListItem button onClick={handleSaveToMyCalculation}>
            <ListItemIcon>
              <SaveIcon />
            </ListItemIcon>
            <ListItemText primary={t("CalculationInputs.SaveCalculation", "Save to my calculations")} />
          </ListItem>
        }
        <Divider />
        <ListItem button onClick={handleSaveForThis}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary={t("CalculationInputs.SaveAsDefault", "Save calculation parameters as default")} />
        </ListItem>
        <ListItem button onClick={handleResetForThis}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t("CalculationInputs.ResetToDefault", "Reset calculation parameters to default")} />
        </ListItem>
        <Divider />
        <ListItem button onClick={handleResetForAllCalculationTypes}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t("CalculationInputs.ResetToDefaultAll", "Reset all calculation parameters to default")} />
        </ListItem>
        <ListSubheader>{t('CalculationInputs.FilterActions', 'Filters actions')}</ListSubheader>
        <ListItem button onClick={onSaveFilterToCalculationType}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary={t('CalculationInputs.SaveForThisCalculationType', 'Save for this calculation type')} />
        </ListItem>
        <ListItem button onClick={onResetFilterToCalculationType}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('CalculationInputs.ResetForThisCalculationType', 'Reset for this calculation type')} />
        </ListItem>
        <Divider />
        <ListItem button onClick={onSaveFilterToAllCalculations}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText primary={t('CalculationInputs.SaveForAllCalculationTypes', 'Save for all calculation types')} />
        </ListItem>
        <ListItem button onClick={onResetFilterToAllCalculations}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('CalculationInputs.ResetForAllCalculationTypes', 'Reset for all calculation types')} />
        </ListItem>
      </StyledList>
    </Popover>
  </>);
};

CalculationInputsActions.propTypes = {
  onSaveToMyCalculations: PropTypes.func.isRequired,
  onSaveToCalculationType: PropTypes.func.isRequired,
  onResetForCalculationType: PropTypes.func.isRequired,
  onResetForAllCalculationTypes: PropTypes.func.isRequired,
  onSaveFilterToCalculationType: PropTypes.func.isRequired,
  onResetFilterToCalculationType: PropTypes.func.isRequired,
  onSaveFilterToAllCalculations: PropTypes.func.isRequired,
  onResetFilterToAllCalculations: PropTypes.func.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default React.memo(CalculationInputsActions);
