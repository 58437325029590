import React from 'react';
import PropTypes from 'prop-types';

import TDahri from './TechnicalData/AHRI/Loadable';
import TDSinglePhase from './TechnicalData/SinglePhase/Loadable';
import TDSinglePhaseDual from './TechnicalData/SinglePhaseDual/Loadable';
import TDEvaporator from './TechnicalData/Evaporator/Loadable';
import TDEvaporatorDual from './TechnicalData/EvaporatorDual/Loadable';
import TDCondenser from './TechnicalData/Condenser/Loadable';
import TDCondenserDual from './TechnicalData/CondenserDual/Loadable';
import TDCascade from './TechnicalData/Cascade/Loadable';
import TDRadiator from './Application/twoStage/tabs/Radiator/Loadable';
import TDTwoStageHxDisplay from './Application/twoStage/tabs/TwoStageHxDisplay/Loadable';
import TDRefToAir from './Application/airDryer/tabs/RefToAir/Loadable';
import TDAirToAir from './Application/airDryer/tabs/AirToAir/Loadable';
import TDDistrictEnergySinglePhase from './Application/districtEnergy/tabs/DistrictEnergySinglePhase/Loadable';
import TDHyertwain from './TechnicalData/Hypertwain/Loadable';

import {
  AHRI,
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  AirDryer,
  DistrictEnergy,
  Hypertwain
} from '../../../constants/calculationTypes';

const TechnicalDataUni = ({ calculationType, data, inputs }) => {
    if(!data){
        return null;
    }

    switch (calculationType.toLowerCase()) {
        case SinglePhase:
            return <TDSinglePhase data={data} inputs={inputs} />;
        case SinglePhaseDual:
            return <TDSinglePhaseDual data={data} inputs={inputs} />;
        case Evaporator:
        case LiquidEvaporator:
            return <TDEvaporator data={data} inputs={inputs} />;
        case EvaporatorDual:
            return <TDEvaporatorDual data={data} inputs={inputs} />;
        case EvaporatorHeatPump:
          return <TDEvaporator data={data} inputs={inputs} />;
        case Condenser:
            return <TDCondenser data={data} inputs={inputs} />;
        case CondenserDual:
            return <TDCondenserDual data={data} inputs={inputs} />;
        case CondenserHeatPump:
          return <TDCondenser data={data} inputs={inputs} />;
        case Cascade:
            return <TDCascade data={data} inputs={inputs} />;
        case AHRI:
          return <TDahri data={data} inputs={inputs} /> ;
        case "twostageradiator":
          return <TDRadiator data={data} inputs={inputs}/>;
        case "singlephasetwostage": // todo: improve infrastructure
            return <TDTwoStageHxDisplay data={data} inputs={inputs} />;
        case "reftoair":
          return <TDRefToAir data={data} inputs={inputs}/>;
        case "airtoair":
          return <TDAirToAir data={data} inputs={inputs}/>;
        case "districtenergysinglephase":
          return <TDDistrictEnergySinglePhase data={data} inputs={inputs} />;
        case Hypertwain:
          return <TDHyertwain data={data} inputs={inputs} />;
        case AirDryer:
          return null; // todo: to be implemented
        case DistrictEnergy:
          return null; // todo: to be implemented
        default:
            return null;
    }
};

TechnicalDataUni.propTypes = {
    data: PropTypes.object,
    inputs: PropTypes.object,
    calculationType: PropTypes.string.isRequired
};

export default TechnicalDataUni;
