import axios from 'axios';
import {
    CALCULATIONS_CALCULATE,
    CALCULATIONS_SAVED,
    CALCULATIONS_INPUTS,
    CALCULATIONS_INPUTS_TAGS_AUTOCOMPLETE,
    CALCULATIONS_SAVE,
    CALCULATIONS_DELETE,
    USERPROFILE_FILTER_SAVE,
    USERPROFILE_FILTER_RESET,
    USERPROFILE_DEFAULT_CALCULATION_INPUTS_SAVE,
    USERPROFILE_DEFAULT_CALCULATION_TYPE_CALCULATION_INPUTS_DELETE,
    USERPROFILE_DEFAULT_ALL_CALCULATION_INPUTS_DELETE,
    CALCULATION_OUTPUT_COLUMN_SETTINGS,
    CALCULATIONS_SAVED_INPUTS
} from '../constants/links';
import {dthermXBaseAddress} from './baseAddresses';

class CalculationsApi {

    static saveCalculationInputs(savedCalculationInputs) {
        if (!savedCalculationInputs) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_SAVE}`,
            data: savedCalculationInputs
        });
    }

    static deleteCalculationInput(calculationInputId) {
        if (!calculationInputId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_DELETE}`,
            params:  {
                calculationInputId: calculationInputId
            }
        });
    }

    static saveDefaultCalculationInputs(userId, calculationType, userCalculationInputs) {
      if (!userId || !calculationType || !userCalculationInputs) {
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }

      return axios({
        method: 'POST',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_CALCULATION_INPUTS_SAVE}`,
        data: {
          userId: userId,
          calculationType: calculationType,
          userCalculationInputs: userCalculationInputs
        }
      });
    }

    static deleteDefaultCalculationTypeCalculationInputs(userId, calculationType) {
      if (!userId || !calculationType) {
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }

      return axios({
        method: 'POST',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_CALCULATION_TYPE_CALCULATION_INPUTS_DELETE}`,
        data: {
          userId: userId,
          calculationType: calculationType
        }
      });
    }

    static deleteAllDefaultCalculationInputs(userId) {
      if (!userId) {
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }

      return axios({
        method: 'POST',
        url:  `${dthermXBaseAddress()}${USERPROFILE_DEFAULT_ALL_CALCULATION_INPUTS_DELETE}`,
        data: {
          userId: userId
        }
      });
  }


  static GetSavedCalculation(userId) {
        if (!userId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_SAVED}`,
            params: {
                userId: userId
            }
        });
    }

    static getUserAutocomplete(userId) {
        if (!userId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({
            method: 'GET',
            url:  `${dthermXBaseAddress()}${CALCULATIONS_INPUTS_TAGS_AUTOCOMPLETE}`,
            params: {
                userId: userId
            }
        });
    }

    static getCalculationInputs(userId, calculationType, calculationInputId = null) {
        if (!userId || !calculationType) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({
            method: 'GET',
            url: `${dthermXBaseAddress()}${CALCULATIONS_INPUTS}`,
            params: {
                userId: userId,
                calculationType: calculationType,
                calculationInputId: calculationInputId
            }
        });
    }

    static getSavedCalculationInputs(userId, calculationInputId) {
        if (!userId || !calculationInputId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'GET',
            params: {
              userId: userId,
              calculationInputId: calculationInputId
            },
            url: `${dthermXBaseAddress()}${CALCULATIONS_SAVED_INPUTS}`,
        });
    }

    static saveFilterSettings(userId, calculationFilters, targetCalculationType = null) {
        if (!userId || !calculationFilters) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${USERPROFILE_FILTER_SAVE}`,
            data: {
                userId: userId,
                calculationType: targetCalculationType,
                filters: calculationFilters
            }
        });
    }

    static resetFilterSettings(userId, targetCalculationType = null) {
        if (!userId) {
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }

        return axios({
            method: 'POST',
            url:  `${dthermXBaseAddress()}${USERPROFILE_FILTER_RESET}`,
            data: {
                userId: userId,
                calculationType: targetCalculationType
            }
        });
    }

    static calculate(request){
        if(!request){
            return new Promise((resolve, reject) => {
                reject('Request validation error');
            });
        }
        return axios({ method: 'POST',
            url: `${dthermXBaseAddress()}${CALCULATIONS_CALCULATE}`,
            data: request
        });
    }

    static getCalculationColumnSettings(userId, calculationType) {
      if(!userId || !calculationType){
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }
      return axios({ method: 'GET',
        url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
        params: {
          userId: userId,
          calculationType: calculationType
        }
      });
    }

    static saveCalculationColumnSettings(userId, calculationType, userDefaultCalculationOutputColumns) {
      if(!userId ||!calculationType || !userDefaultCalculationOutputColumns){
        return new Promise((resolve, reject) => {
          reject('Request validation error');
        });
      }
      return axios({ method: 'POST',
        url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
        data: {
          userId: userId,
          calculationType: calculationType,
          calculationOutputColumns: userDefaultCalculationOutputColumns
        }
      });
    }

  static resetCalculationColumnSettings(userId, calculationType) {
    if(!userId ||!calculationType){
      return new Promise((resolve, reject) => {
        reject('Request validation error');
      });
    }
    return axios({ method: 'DELETE',
      url: `${dthermXBaseAddress()}${CALCULATION_OUTPUT_COLUMN_SETTINGS}`,
      data: {
        userId: userId,
        calculationType: calculationType
      }
    });
  }
}

export default CalculationsApi;
