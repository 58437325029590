import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import {Button, Container} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useStore} from 'react-redux';
import { useNavigate } from "react-router-dom";
import {deletePouchDB} from '../../containers/calculations/DexieJsDBApi';

const GeneralFallback = ({ error, resetErrorBoundary }) => {
  const store = useStore();
  const navigate = useNavigate();

  const handleReset = useCallback(async () => {

    if (store && store.persistor) {
      store.persistor.purge();
    }

    localStorage.clear();
    sessionStorage.clear();
    await deletePouchDB();

    resetErrorBoundary();
    navigate('/login');
  }, [navigate, resetErrorBoundary, store]);

  return (
    <Container maxWidth="md">
      <Typography variant="h5" component="h2" color="error" sx={{marginBottom: '20px'}}>
        Something went wrong! Try to reset the application.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        If the problem persists, please contact the administrator at dthermx@swep.net
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        {error ? error.message : ''}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleReset}>
        Reset
      </Button>
    </Container>
  );
};

GeneralFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
};

export default GeneralFallback;
