import {all, put, call, takeLatest, select, take } from 'redux-saga/effects';
import * as calcActions from 'actions/calculationActions';
import {
  makeSelectCalculation,
  makeSelectCalculationInputParamsUserCalculationInputs,
  makeSelectCalculationFilters
} from 'selectors/calculationSelectors';
import {
  makeSelectUserId,
  makeSelectUserProfile,
  makeSelectFilters
} from 'selectors/userProfileSelectors';
import {
  makeSelectCalculation as makeSelectCalculationNew
} from '../containers/calculations/selector';
import { makeSelectDefaultSelection } from 'selectors/filterSelectors';
import calculationsApi from 'api/calculationsApi';
import exchangersApi from 'api/exchangersApi';
import CalculationsApi from '../api/calculationsApi';
import {
  Calculation, PRODUCT_NUMBER_GET,
} from "../constants/actionTypes";
import {enqueueSnackbar} from "../containers/Notifier/actions";
import {CalculationResponseType} from "../constants/CalculationResponseType.ts";

function* getOrWaitForUserId() {
  while (true) {
    const userId = yield select(makeSelectUserId());
    if (userId !== undefined && userId !== null && userId !== "") {
      return userId;
    }
    yield take('*');
  }
}

export function* getCalculationInputs(action) {
    try {
      let calculationInputsResponse = {};
      const sessionsInputs = JSON.parse(window.sessionStorage.getItem(action.sessionId));
      if(action.sessionId && sessionsInputs) {
        calculationInputsResponse = sessionsInputs;
        window.sessionStorage.removeItem(action.sessionId);
      } else {
        const res = yield call(calculationsApi.getCalculationInputs, action.userId, action.calculationType, action.calculationInputId);
        calculationInputsResponse = res.data;
      }

      if(action.exchangers){
        const foundHXs = [];
        action.exchangers.split(';').forEach(p => {
          const hx = calculationInputsResponse.availableHeatExchangers.find(x => x.name.toLowerCase() === p.toLowerCase());
          if(hx) {
            foundHXs.push({
              id: hx.id,
              name: p,
              heatExchangerInputType: hx.isLeaf ? 'Child': 'Parent'
            });
          }
        });
        calculationInputsResponse = {...calculationInputsResponse,
          userCalculationInputs: {
            ...calculationInputsResponse.userCalculationInputs,
            selectedHeatExchangers: foundHXs,
            heatLoad: { ...calculationInputsResponse.userCalculationInputs.heatLoad,
              quantity: {
                ...calculationInputsResponse.userCalculationInputs.heatLoad.quantity,
                amount: null
              }
            }
        }};
      }
      if(action.inputParams) {
        for (const [key, value] of Object.entries(action.inputParams)) {
          if(value) {
            calculationInputsResponse = {
              ...calculationInputsResponse,
              userCalculationInputs: {
                ...calculationInputsResponse.userCalculationInputs,
                [key]: {
                  ...calculationInputsResponse.userCalculationInputs[key],
                  'quantity.amount': value
                }
              }
            };
          }
        }
      }
      yield put(calcActions.loadCalculationInputsSuccess(action.calculationType, action.calculationInputId, calculationInputsResponse));
    } catch (e) {
      yield put(calcActions.loadCalculationInputsError(e.message));
      yield put(enqueueSnackbar({ message: "Loading calculation inputs error", options: { variant: "error" }}));
    }
}

export function* getCalculationHeatExchangers(action) {
    try {
        const calculationHeatExchangersResponse = yield call(exchangersApi.getExchangers, action.userId, action.modelRestrictions, action.isCoCurrent, action.fluid1, action.fluid2);
        yield put(calcActions.loadCalculationInputsHeatExchangersSuccess(action.calculationType, calculationHeatExchangersResponse.data));
    } catch (e) {
        yield put(calcActions.loadCalculationInputsHeatExchangersError(e.message));
    }
}
export function* saveCalculationInputs(action) {
    try {
        const userId = yield getOrWaitForUserId();
        const calculation = yield select(makeSelectCalculationNew(action.uuid));
        const userCalculationInputs = calculation.inputParams.userCalculationInputs;
        const calculationFilters = calculation.inputParams.filterSettings;

        const asdf = {
            id: null,
            userId: userId,
            customName: action.customCalculationName,
            createdOn: new Date(),
            note: action.note,
            tags: action.tags,
            userCalculationInputs: userCalculationInputs,
            filterSettings: calculationFilters
        };
        const saveCalculationInputsResponse = yield call(calculationsApi.saveCalculationInputs, asdf);
        yield put(calcActions.saveCalculationInputsSuccess(saveCalculationInputsResponse.data));

        if (action.callback) {
          yield call(action.callback, saveCalculationInputsResponse.data);
        }
    } catch (e) {
        yield put(calcActions.saveCalculationInputsError(e.message));
    }
}

export function* notifySaveCalculationSuccess() {
  yield put(enqueueSnackbar({ message: "Saved!", options: { variant: "success" }}));
}

export function* notifySaveCalculationError() {
  yield put(enqueueSnackbar({ message: "An error occured during saving inputs", options: { variant: "error" }}));
}

export function* processCalculation(action) {
    try {
        const calculation = yield select(makeSelectCalculation(action.calculationType));
        const userProfile = yield select(makeSelectUserProfile());
        yield put(calcActions.calculateBegin(action.calculationType));
        const calculationRequest = createRequest(calculation, action.calculationType, action.calculationMethod, userProfile);
        const calcResponse = yield call(calculationsApi.calculate, calculationRequest);
				const processedResponse = processCalculationResponse(calcResponse.data);

        yield put(calcActions.calculateSuccess(action.calculationType, {...processedResponse, inputParams: calculation.inputParams}));
    } catch(e){
      yield put(enqueueSnackbar({ message: "Calculation was not succesfull!", options: { variant: "error" }}));
    }
}

export function createRequest(formData, calculationType, calculationMethod, userProfile) {
    let inputs = formData.inputs;
    let request = {
        calculationData: {
            applicationName: calculationType,
            calculationType: calculationMethod,
            inputValues: [],
            userCalculationInputs: formData.inputParams.userCalculationInputs
        },
        userDetails: {
            userId: userProfile.userId,
            unit: userProfile.units
        },
        strLang: userProfile.applicationLanguage,
        nosResults: "7",
        bCulture: "false",
        unitSystem: userProfile.settings.units
    };
    //set filters
    request.userSetfilter = {
        factoriesFilterSelection: formData.inputParams.filterSettings.factorySelections,
        materialsFilterSelection: formData.inputParams.filterSettings.materialSelections,
        pressuresFilterSelection: formData.inputParams.filterSettings.pressureSelections,
        warehousesFilterSelection: formData.inputParams.filterSettings.warehouseSelections,
        showOnStockItemsOnly: formData.inputParams.filterSettings.showOnStockItemsOnly
    };

    for (let property in inputs) {
        if (Object.prototype.hasOwnProperty.call(inputs, property)) {
            request.calculationData.inputValues.push({
                name: inputs[property].name,
                unit: inputs[property].unit,
                siValue: inputs[property].siValue,
                value: inputs[property].value,
                warningMessage: null
            });
        }
    }
    return request;
}

function mapHeatExchangerResult(lowerize) {
  return (value) => {

    const getAdditionalInfoValue = (property, getFirstValue) => {
      let val = value.additionalInfo.find((item) => {
        return item.key === property;
      });
      if (val) {
        if (getFirstValue) {
          return val.value[0] === "True";
        }
        return val.value;
      }
      return null;
    };

    let response = {
      ...value,
      name: value.bphe,
      description: [],
      calcMethod: value.calcMethod || '',
      channels: value.channels || [],
      counterCurrent: getAdditionalInfoValue('CounterCurrent', true),
      dualTypes: getAdditionalInfoValue('DualTypes', true),
      crossConnect: getAdditionalInfoValue('CrossConnect', true),
    };

    response.ciData = value.connectionImpactData.reduce(function (acc, cur) {
      acc[lowerize(cur.key)] = cur.value;
      return acc;
    }, {});
    response.componentsResults = value.componentResults;
    response.componentCalculationResults = value.componentResults;
    response.outputValues = value.calcResult;

    return response;
  };
}

function transformCalculationResponse(data) {
  const lowerize = function (strval) {
    return strval.charAt(0).toLowerCase() + strval.slice(1);
	};
  let exchangers = data.calcResultsCollection.map(mapHeatExchangerResult(lowerize));

  const outputs = {
    exchangers: exchangers,
    messages: data.messages,
    calculationOutputColumns: data.calculationOutputColumns,
    error: data.error,
    calculationType: data.calculationType,
    calculationResponseType: data.calculationResponseType,
  };

  return outputs;
}

function transformApplicationResponse(data) {
  if(data.calculationType === "Hypertwain") {
    return transformCalculationResponse(data);
  }
  return data;
}

export function processCalculationResponse(data) {
  switch (data.calculationResponseType) {
    case CalculationResponseType.Calculation:
      return transformCalculationResponse(data);
    case CalculationResponseType.Application:
      return transformApplicationResponse(data);
    default:
      return transformCalculationResponse(data);
      //throw "Unknown calculation response type!";
  }
}

export function* getProductNumber(action) {
    try {
        const response = yield call(exchangersApi.getProductNumber, action.xpc);
        if(response && response.data.productNumber){
            yield call(action.callback, response.data.productNumber);
        } else {
          yield put(enqueueSnackbar({ message: "Product number not found", options: { variant: "warning" }}));
        }
    } catch(e){
      yield put(enqueueSnackbar({ message: "Get product number error", options: { variant: "error" }}));
    }
}

export function* saveFilterSettings(action) {
    try {
        const calculationFilters = yield select(makeSelectCalculationFilters(action.sourceCalculationType));
        const userId = yield select(makeSelectUserId());
        yield call(CalculationsApi.saveFilterSettings, userId, calculationFilters, action.targetCalculationType);
        yield put(calcActions.saveFilterSettingsSuccess(calculationFilters, action.targetCalculationType));
        yield put(enqueueSnackbar({ message: "Saved!", options: { variant: "success" }}));
    } catch (e) {
        yield put(calcActions.saveFilterSettingsError(e));
        yield put(enqueueSnackbar({ message: "Unable to save filters!", options: { variant: "error" }}));
    }
}


export function* resetFilterSettings(action) {
    try {
        const userId = yield select(makeSelectUserId());
        yield call(CalculationsApi.resetFilterSettings, userId, action.targetCalculationType);
        yield put(calcActions.resetFilterSettingsSuccess(action.targetCalculationType));
        yield put(enqueueSnackbar({ message: "Filter has been reset!", options: { variant: "success" }}));
        let filterSelection = yield select(makeSelectFilters(action.calculationType));
        if (!filterSelection) {
            filterSelection = yield select(makeSelectDefaultSelection());
        }
        yield put(calcActions.changeCalculationFiltersSelection(action.sourceCalculationType, filterSelection));

    } catch (e) {
        yield put(calcActions.resetFilterSettingsError(e));
        yield put(enqueueSnackbar({ message: "Unable to reset filters", options: { variant: "error" }}));
    }
}

export function* saveDefaultCalculationInputs(action) {
  try {
    const userId = yield select(makeSelectUserId());
    const calculationType = action.calculationType.toLowerCase();
    const userCalculationInputs = yield select(makeSelectCalculationInputParamsUserCalculationInputs(calculationType));
    yield call(CalculationsApi.saveDefaultCalculationInputs, userId, calculationType, userCalculationInputs);
    yield put(calcActions.saveDefaultCalculationInputsSuccess());
  } catch (e) {
    yield put(calcActions.saveDefaultCalculationInputsError(e));
  }
}

export function* deleteDefaultCalculationInputs(action) {
  try {
    const userId = yield select(makeSelectUserId());
    const calculationType = action.calculationType.toLowerCase();
    yield call(CalculationsApi.deleteDefaultCalculationTypeCalculationInputs, userId, action.calculationType);
    yield put(calcActions.deleteDefaultCalculationInputsSuccess());
    yield put(calcActions.loadCalculationInputs(userId, calculationType, null));
  } catch (e) {
    yield put(calcActions.deleteDefaultCalculationInputsError(e));
  }
}

export function* deleteAllDefaultCalculationInputs(action) {
  try {
    const userId = yield select(makeSelectUserId());
    const calculationType = action.openedCalculationType.toLowerCase();
    yield call(CalculationsApi.deleteAllDefaultCalculationInputs, userId);
    yield put(calcActions.deleteAllDefaultCalculationInputsSuccess());
    yield put(calcActions.loadCalculationInputs(userId, calculationType, null));
  } catch (e) {
    yield put(calcActions.deleteAllDefaultCalculationInputsError(e));
  }
}

export default function* calculationsData() {
    yield all([
        takeLatest(Calculation.INPUTS_LOAD, getCalculationInputs),
        takeLatest(Calculation.INPUTS_HEATEXCHANGERS_LOAD, getCalculationHeatExchangers),
        takeLatest(Calculation.INPUTS_SAVE, saveCalculationInputs),
        takeLatest(Calculation.INPUTS_SAVE_SUCCESS, notifySaveCalculationSuccess),
        takeLatest(Calculation.INPUTS_SAVE_ERROR, notifySaveCalculationError),
        takeLatest(Calculation.CALCULATE, processCalculation),
        takeLatest(PRODUCT_NUMBER_GET, getProductNumber),
        takeLatest(Calculation.FILTERS_SAVE, saveFilterSettings),
        takeLatest(Calculation.FILTERS_RESET, resetFilterSettings),
        takeLatest(Calculation.INPUTS_DEFAULT_SAVE, saveDefaultCalculationInputs),
        takeLatest(Calculation.INPUTS_DEFAULT_DELETE, deleteDefaultCalculationInputs),
        takeLatest(Calculation.INPUTS_ALL_DEFAULT_DELETE, deleteAllDefaultCalculationInputs)
    ]);
}
