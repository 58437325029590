import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import FilterNoneIcon from '@mui/icons-material/FilterNone';

const CloneCalculationButton = props => {

  return (
    <IconButton
      aria-describedby={"filters-actions"}
      tabIndex={props.tabIndex}
      title="Clone calculation"
      onClick={props.onClick}
      size="large">
      <FilterNoneIcon/>
    </IconButton>
  );
};

CloneCalculationButton.propTypes = {
  path: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  iconProps: PropTypes.object,
  buttonClass: PropTypes.string,
  calculation: PropTypes.object
};

export default CloneCalculationButton;
