import {convertToSI, useUnitSystem } from "./useUnitConversion";
import Tooltip from "@mui/material/Tooltip";
import AllUnitsTooltip from "../AllUnitsTooltip";
import React from "react";
import PropTypes from 'prop-types';
import {UnitGroups, UnitSystem} from "utils/unitGroups.ts";
import FluidInputType from "../../../fluidSelector/FluidInputType.ts";
import clsx from "clsx";
import get from 'lodash/get';
import {useTranslation} from "react-i18next";


const GetFluidDescription = (fluid, selectedFluid, fluidInputType) => {
  const { t: tf } = useTranslation('fluids');

  switch (fluidInputType) {
    case FluidInputType.TwoPhase:
      return tf(fluid.name);
    case FluidInputType.SinglePhase: {
      switch (fluid.category.toUpperCase()) {
        case "LIQ":
        case "LIQ2":
          return tf(fluid.name);
        case "LIQVAP": {
          if (selectedFluid.isGas === false) {
            return `${tf(fluid.name)} (liquid)`;
          } else {
            return `${tf(fluid.name)} (${selectedFluid.inputValue.amount} ${selectedFluid.inputValue.unit})`;
          }
        }
        case "CONC":
          if (fluid.concentrationProperties.concentrationType.includes("volume")) {
            return `${tf(fluid.name)} ${selectedFluid.inputValue.amount} ${selectedFluid.inputValue.unit} vol`;
          } else {
            return `${tf(fluid.name)} ${selectedFluid.inputValue.amount} ${selectedFluid.inputValue.unit} mass`;
          }
        case "VAP":
          return `${tf(fluid.name)} (${selectedFluid.inputValue.amount} ${selectedFluid.inputValue.unit})`;
      }

      break;
    }
    default:
      throw `getFluidDescription for ${fluid.fluidInputType}`;
  }
};

const createDisplayFluid = (name, availableFluidsSide1, selectedFluidSide1, fluidInputTypeSide1, availableFluidsSide2, selectedFluidSide2, fluidInputTypeSide2) => {
  const fluidSide1 = availableFluidsSide1.find(x => x.id === selectedFluidSide1.fluidId && x.isLeaf === true);
  const fluidSide2 = availableFluidsSide2.find(x => x.id === selectedFluidSide2.fluidId && x.isLeaf === true);

  return {
    name: name,
    unit: "",
    side1: GetFluidDescription(fluidSide1, selectedFluidSide1, fluidInputTypeSide1),
    middle: "",
    side2: GetFluidDescription(fluidSide2, selectedFluidSide2, fluidInputTypeSide2),
  };
};

const createDisplayFlowTypeObject = (name, value) => {
  return {
    name: name,
    unit: "",
    side1: "",
    middle: value,
    side2: ""
  };
};

const createDisplayObject = (item, side1, middle, side2, translationName, unit) => {
  return {
    name: translationName ? translationName: item.name,
    unit: unit ? unit : item.unit,
    side1,
    middle,
    side2
  };
};

const createExtraDutyRequirements = (bpheType, alias, portSwitched, execution) => {
  bpheType = bpheType.toLowerCase();
  alias = alias.toLowerCase();
  const regEx = new RegExp("/d");

  const isNw = bpheType.includes("n-w") || alias.includes("n-w");
  const isWn = bpheType.includes("w-n") || alias.includes("w-n");
  const isAsOrLas = bpheType.includes("las") || bpheType.includes("as") || alias.includes("las") || alias.includes("as");
  const isLh = bpheType.includes("l-h") || alias.includes("l-h");
  const isHl = bpheType.includes("h-l") || alias.includes("h-l");
  const isDual = bpheType.startsWith("db") || bpheType.startsWith("dfx") || bpheType.startsWith("dp") || bpheType.startsWith("ds") || bpheType.startsWith("dv") || regEx.test(bpheType) || alias.startsWith("db") || alias.startsWith("dfx") || alias.startsWith("dp") || alias.startsWith("ds") || alias.startsWith("dv") || regEx.test(alias);
  const isMultiPass = execution !== "1P";

  const result = [];

  if (isNw || isWn || isAsOrLas)
  {
    result.push({ name: "Circuit", unit: "", side1: isWn ? "Outer" : "Inner", middle: "", side2: !isWn ? "Outer" : "Inner" });
    result.push({ name: "Channel", unit: "", side1: isWn ? "Wide" : "Narrow", middle: "", side2: !isWn ? "Wide" : "Narrow" });
  }
  else if (isLh || isHl)
  {
    result.push({ name: "Circuit", unit: "", side1: isHl ? "Inner" : "Outer", middle: "", side2: isLh ? "Inner" : "Outer" });
    result.push({ name: "Theta", unit: "", side1: isHl ? "High" : "Low", middle: "", side2: isLh ? "High" : "Low" });
  }
  else if (isDual)
  {
    result.push({ name: "Circuit", unit: "", side1: "Inner", middle: "", side2: "Outer" });
  }
  else if (isMultiPass)
  {
    result.push({ name: "Circuit", unit: "", side1: "Primary", middle: "", side2: "Secondary" });
  }
  else
  {
    result.push({ name: "Circuit", unit: "", side1: !portSwitched ? "Inner" : "Outer", middle: "", side2: portSwitched ? "Inner" : "Outer" });
  }

  return result;
};

const Wrapper = ({ children }) => {
  return (
    <span>
      {children}
    </span>
  );
};
Wrapper.propTypes = {
  children: PropTypes.array
};

// eslint-disable-next-line react/no-multi-comp
const Value = ({ sspValue }) => {
  const isUserInputValue = get(sspValue, 'isUserInputValue', false);

  return (
    <span className={clsx({"fw-bold": isUserInputValue})}>{sspValue.formattedValue}</span>
  );
};
Value.propTypes = {
  sspValue: PropTypes.object.isRequired
};

const convert = (unitGroupName, siAmount, targetUnitName, densities = null) => {
  const unitGroup = UnitGroups.getUnitGroup(unitGroupName);
  const targetUnit = unitGroup.getUnit(targetUnitName);
  return targetUnit.convertFromSI(siAmount, densities);
};

// eslint-disable-next-line react/no-multi-comp
const UnitSystemAwareQuantityInputValue = ({ quantityInput, densities }) => {
  const { convertFromSI, format, unitSystem } = useUnitSystem();

  const unitGroup = UnitGroups.getUnitGroup(quantityInput.unitGroup);
  const quantityUnit = unitGroup.getUnit(quantityInput.quantity.unit);
  const siValue = quantityUnit.convertToSI(quantityInput.quantity.amount, densities);

  let value = null;
  if (quantityInput.defaultUserSelectedUnit) {
    value = convert(quantityInput.unitGroup, siValue, quantityInput.defaultUserSelectedUnit, densities);
  } else if (quantityInput.defaultMetricUnit && unitSystem === UnitSystem.Metric) {
    value = convert(quantityInput.unitGroup, siValue, quantityInput.defaultMetricUnit, densities);
  } else if (quantityInput.defaultUSUnit && unitSystem === UnitSystem.US) {
    value = convert(quantityInput.unitGroup, siValue, quantityInput.defaultUSUnit, densities);
  } else {
    value = convertFromSI(quantityInput.unitGroup, siValue, densities);
  }

  const formattedValue = format(quantityInput.unitGroup, value);

  return (
    <Tooltip title={<AllUnitsTooltip unitGroup={quantityInput.unitGroup} siValue={Number(siValue)} densities={densities}/>} disableInteractive={false} >
      <span>{formattedValue}</span>
    </Tooltip>
  );
};

UnitSystemAwareQuantityInputValue.propTypes = {
  quantityInput: PropTypes.object.isRequired,
  densities: PropTypes.object
};

const UnitSystemAwareQuantityInputUnit = ({ quantityInput }) => {// eslint-disable-line react/no-multi-comp
  const { getDefaultUnit, unitSystem } = useUnitSystem();

  if (quantityInput.defaultUserSelectedUnit) {
    return quantityInput.defaultUserSelectedUnit;
  } if (quantityInput.defaultMetricUnit && unitSystem === UnitSystem.Metric) {
    return quantityInput.defaultMetricUnit;
  } else if (quantityInput.defaultUSUnit && unitSystem === UnitSystem.US) {
    return quantityInput.defaultUSUnit;
  } else {
    return getDefaultUnit(quantityInput.unitGroup);
  }
};

UnitSystemAwareQuantityInputUnit.propTypes = {
  quantityInput: PropTypes.object.isRequired
};


const UnitSystemAwareValue = ({ sspValue, defaultMetricUnit, defaultUSUnit }) => {// eslint-disable-line react/no-multi-comp
  const { convertFromSI, format, unitSystem } = useUnitSystem();

  let value = null;
  if (sspValue.defaultUserSelectedUnit) {
    value = convert(sspValue.unitGroup, sspValue.siValue, sspValue.defaultUserSelectedUnit, sspValue.densities);
  } else if (defaultMetricUnit && unitSystem === UnitSystem.Metric) {
    value = convert(sspValue.unitGroup, sspValue.siValue, defaultMetricUnit, sspValue.densities);
  } else if (defaultUSUnit && unitSystem === UnitSystem.US) {
    value = convert(sspValue.unitGroup, sspValue.siValue, defaultUSUnit, sspValue.densities);
  } else {
    value = convertFromSI(sspValue.unitGroup, sspValue.siValue, sspValue.densities);
  }

  const formattedValue = format(sspValue.unitGroup, value);
  const isUserInputValue = get(sspValue, 'isUserInputValue', false);

  return (
    <Tooltip title={<AllUnitsTooltip unitGroup={sspValue.unitGroup} siValue={Number(sspValue.siValue)} densities={sspValue.densities}/>} interactive={true} >
      <span className={clsx({"fw-bold": isUserInputValue})}>{formattedValue}</span>
    </Tooltip>
  );
};
UnitSystemAwareValue.propTypes = {
  sspValue: PropTypes.object.isRequired,
  defaultMetricUnit: PropTypes.string,
  defaultUSUnit: PropTypes.string
};

const UnitSystemAwareUnit = ({ sspValue, defaultMetricUnit, defaultUSUnit }) => {// eslint-disable-line react/no-multi-comp
  const { getDefaultUnit, unitSystem } = useUnitSystem();

  if (sspValue.defaultUserSelectedUnit) {
    return sspValue.defaultUserSelectedUnit;
  } if (defaultMetricUnit && unitSystem === UnitSystem.Metric) {
    return defaultMetricUnit;
  } else if (defaultUSUnit && unitSystem === UnitSystem.US) {
    return defaultUSUnit;
  } else {
    return getDefaultUnit(sspValue.unitGroup);
  }
};

UnitSystemAwareUnit.propTypes = {
  sspValue: PropTypes.object.isRequired,
  defaultMetricUnit: PropTypes.string,
  defaultUSUnit: PropTypes.string
};

const UnitSystemAwareValue1 = ({ unitGroup, value, unit, densities = null, overrideDefaultMetricUnit = null, overrideDefaultUSUnit = null }) => {// eslint-disable-line react/no-multi-comp
  const { convertFromSI, format, unitSystem } = useUnitSystem();

  let v;
  let f;

  const siValue = convertToSI(unitGroup, value, unit, densities);

  if (overrideDefaultMetricUnit && unitSystem === UnitSystem.Metric) {
    v = convert(unitGroup, siValue, overrideDefaultMetricUnit, densities);
  } else if (overrideDefaultUSUnit && unitSystem === UnitSystem.US) {
    v = convert(unitGroup, siValue, overrideDefaultUSUnit, densities);
  } else {
    v = convertFromSI(unitGroup, siValue, densities);
  }

  f = format(unitGroup, v);

  return (
    <Tooltip title={<AllUnitsTooltip unitGroup={unitGroup} siValue={siValue} densities={densities} />}>
      <span>{f}</span>
    </Tooltip>
  );
};
UnitSystemAwareValue1.propTypes = {
  unitGroup: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  densities: PropTypes.object,
  overrideDefaultMetricUnit: PropTypes.string,
  overrideDefaultUSUnit: PropTypes.string
};

const UnitSystemAwareUnits = ({ sspValue1, sspValue2 }) => {// eslint-disable-line react/no-multi-comp
  const { getDefaultUnit } = useUnitSystem();

  let unitSide1 = '';
  let unitSide2 = '';
  if (sspValue1.defaultUserSelectedUnit) {
    unitSide1 = sspValue1.defaultUserSelectedUnit;
  } else {
    unitSide1 = getDefaultUnit(sspValue1.unitGroup);
  }

  if (sspValue2.defaultUserSelectedUnit) {
    unitSide2 = sspValue2.defaultUserSelectedUnit;
  } else {
    unitSide2 = getDefaultUnit(sspValue2.unitGroup);
  }

  if (unitSide1 === unitSide2) {
    return unitSide1;
  } else {
    return `${unitSide1} | ${unitSide2}`;
  }
};

UnitSystemAwareUnits.propTypes = {
  sspValue1: PropTypes.object.isRequired,
  sspValue2: PropTypes.object.isRequired
};

export {
  createExtraDutyRequirements,
  createDisplayFlowTypeObject,
  createDisplayObject,
  createDisplayFluid,
  UnitSystemAwareQuantityInputValue,
  UnitSystemAwareQuantityInputUnit,
  UnitSystemAwareValue,
  UnitSystemAwareValue1,
  UnitSystemAwareUnit,
  UnitSystemAwareUnits,
  Wrapper,
  Value
};
