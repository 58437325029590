import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import NumericInput from '../NumericInput';
import UnitInput from '../UnitInput';
import {UnitGroups, unitGroupPrecisions } from "utils/unitGroups.ts";
import {RoundToSignificantDigitsWithOffset} from "../../calculation/result/TechnicalData/useUnitConversion";

const Quantity = ({
  amount = null,
  unit = null,
  unitGroupName = null,
  onAmountChange,
  onUnitChange,
  isValid,
  messages,
  className,
  tabIndex,
  readonly,
  amountComponentProps,
  unitComponentProps,
}) => {
  const unitGroup = useMemo(() => UnitGroups.getUnitGroup(unitGroupName), [unitGroupName]);
  const unitsNames = useMemo(() => (unitGroup || []).units.map(u => u.name), [unitGroup]);
  const handleDisplayFormat = useCallback((amount) =>  RoundToSignificantDigitsWithOffset(amount, unitGroup.signDigits, unitGroup.offset), [unitGroup]);
  const unitInfo = useMemo(() => unitGroupPrecisions.getPrecision(unitGroupName, unit), [unitGroupName, unit]);

  return (
    <div className="input-group">
      <NumericInput
        amount={amount}
        onChange={onAmountChange}
        displayFormat={handleDisplayFormat}
        isValid={isValid}
        messages={messages}
        min={unitInfo.minValue}
        max={unitInfo.maxValue}
        step={unitInfo.incrementStep}
        tabIndex={tabIndex}
        readonly={readonly}
        {...amountComponentProps}

      />
      <UnitInput
        className={className}
        units={unitsNames}
        value={unit}
        onChange={onUnitChange}
        readonly={readonly}
        {...unitComponentProps} />
    </div>
  );
};

Quantity.propTypes = {
  amount: PropTypes.number,
  unit: PropTypes.string,
  unitGroupName: PropTypes.string,
  onAmountChange:PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  messages: PropTypes.array,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readonly: PropTypes.bool,
  amountComponentProps: PropTypes.object,
  unitComponentProps: PropTypes.object
};

export default React.memo(Quantity);
