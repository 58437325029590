import * as calcMethods from '../constants/calculationMethods';
import {
  hasQuantityValue,
  SomeFieldsShouldBeRemoved,
  SomeFieldsShouldBeAdded,
  NumberOfPlates,
  hasValue,
  AtLeastOneExchangerShouldBeSelected,
  OneFluidShouldBeSelected,
  isFormValid,
} from './common';
import { produce } from "immer";

function initializeValidationObject() {
  return {
    selectedFluidSide1: {hidden: false, isValid: true, translationKey: "Fluid side 1", validationMessages: []},
    selectedFluidSide2: {hidden: false, isValid: true, translationKey: "Fluid side 1", validationMessages: []},
    selectedHeatExchangers: {hidden: false, isValid: true, translationKey: "Heat exchanger", validationMessages: []},
    heatLoad: {hidden: false, isValid: true, translationKey: "Heat load", validationMessages: []},
    subCooledLiquidTemperature: {hidden: false, isValid: true, translationKey: "Sub-cooled liquid temperature", validationMessages: []},
    inletTemperatureSide2: {hidden: false, isValid: true, translationKey: "Inlet temperature side 2", validationMessages: []},
    evaporationTemperature: {hidden: false, isValid: true, translationKey: "Evaporation temperature", validationMessages: []},
    outletVaporQuality: {hidden: false, isValid: true, translationKey: "Outlet vapor quality", validationMessages: []},
    superHeatingTemperature: {hidden: false, isValid: true, translationKey: "Super-heating temperature", validationMessages: []},
    outletTemperatureSide2: {hidden: false, isValid: true, translationKey: "Outlet temperature side 2", validationMessages: []},
    flowSide2: {hidden: false, isValid: true, translationKey: "Flow side 2", validationMessages: []},
    maxPressureDropSide2: {hidden: false, isValid: true, translationKey: "Max pressure drop side 2", validationMessages: []},
    numberOfPlates: {hidden: false, isValid: true, translationKey: "Number of plates", validationMessages: []},
    isValid: true
  };
}

const validationObject = initializeValidationObject();

export function validateForm(method, calculationData){
  return produce(validationObject, draft => {
    switch (method) {
      case calcMethods.Design:
        return validateDesignForm(calculationData, draft);
      case calcMethods.Performance:
        return validatePerformanceForm(calculationData, draft);
      case calcMethods.Rating:
        return validateRatingForm(calculationData, draft);
      default:
        return validateDesignForm(calculationData, draft);
    }
  });
}

export function validateDesignForm(calculationData, validationObject){
  let dv = validationObject;
  const inputs = calculationData.userCalculationInputs;

  // Five values must be specified
  const total =
    hasQuantityValue(inputs.flowSide2) +
    hasQuantityValue(inputs.heatLoad);
  // There must only be one value missing on either side
  const side1 =
    hasQuantityValue(inputs.subCooledLiquidTemperature) +
    hasQuantityValue(inputs.evaporationTemperature);
  const side2 =
    hasQuantityValue(inputs.inletTemperatureSide2) +
    hasQuantityValue(inputs.outletTemperatureSide2);

  if (total !== 1) {// Remove one value from side 2
    SomeFieldsShouldBeAdded([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (total === 2) {// Remove one value from side 2
    SomeFieldsShouldBeRemoved([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (side1 < 2) {// Add values to side 1
    SomeFieldsShouldBeAdded([
      'evaporationTemperature',
      'subCooledLiquidTemperature',
    ], inputs, dv, 2 - side1);
  }
  if (side2 < 2) {// Add values to side 2
    SomeFieldsShouldBeAdded([
      'inletTemperatureSide2',
      'outletTemperatureSide2',
    ], inputs, dv, 2 - side2);
  }

  const outletVaportAndSuperheating =
    hasQuantityValue(inputs.superHeatingTemperature) +
    hasValue(inputs.outletVaporQuality);

  if(outletVaportAndSuperheating === 0){
    SomeFieldsShouldBeAdded([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }
  if(outletVaportAndSuperheating === 2){
    SomeFieldsShouldBeRemoved([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }

  SomeFieldsShouldBeRemoved(['numberOfPlates'], inputs, dv, 1);

  OneFluidShouldBeSelected('selectedFluidSide1', inputs, dv);
  OneFluidShouldBeSelected('selectedFluidSide2', inputs, dv);
  AtLeastOneExchangerShouldBeSelected('selectedHeatExchangers', inputs, dv);

  dv.isValid = isFormValid(dv);
  return dv;
}

export function validatePerformanceForm(calculationData, validationObject){
  let dv = validationObject;
  const inputs = calculationData.userCalculationInputs;

  // Five values must be specified
  const total =
    hasQuantityValue(inputs.flowSide2) +
    hasQuantityValue(inputs.heatLoad);
  // There must only be one value missing on either side
  const side1 =
    hasQuantityValue(inputs.subCooledLiquidTemperature) ? 1 : 0;
    // hasQuantityValue(inputs.evaporationTemperature);
  const side2 =
    hasQuantityValue(inputs.inletTemperatureSide2) +
    hasQuantityValue(inputs.outletTemperatureSide2);

  if (total !== 1) {// Remove one value from side 2
    SomeFieldsShouldBeAdded([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (total === 2) {// Remove one value from side 2
    SomeFieldsShouldBeRemoved([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (side1 < 1) {// Add values to side 1
    SomeFieldsShouldBeAdded([
      'evaporationTemperature',
    ], inputs, dv, 2 - side1);
  }
  if (side2 < 2) {// Add values to side 2
    SomeFieldsShouldBeAdded([
      'inletTemperatureSide2',
      'outletTemperatureSide2',
    ], inputs, dv, 2 - side2);
  }

  SomeFieldsShouldBeRemoved(['evaporationTemperature'], inputs, dv, 1);

  SomeFieldsShouldBeAdded(['numberOfPlates'], inputs, dv, 1);
  NumberOfPlates(['numberOfPlates'], inputs, dv);

  const outletVaportAndSuperheating =
    hasQuantityValue(inputs.superHeatingTemperature) +
    hasValue(inputs.outletVaporQuality);

  if(outletVaportAndSuperheating === 0){
    SomeFieldsShouldBeAdded([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }
  if(outletVaportAndSuperheating === 2){
    SomeFieldsShouldBeRemoved([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }

  OneFluidShouldBeSelected('selectedFluidSide1', inputs, dv);
  OneFluidShouldBeSelected('selectedFluidSide2', inputs, dv);
  AtLeastOneExchangerShouldBeSelected('selectedHeatExchangers', inputs, dv);

  dv.isValid = isFormValid(dv);
  return dv;
}

export function validateRatingForm(calculationData, validationObject){
  let dv = validationObject;
  const inputs = calculationData.userCalculationInputs;

  // Five values must be specified
  const total =
    hasQuantityValue(inputs.flowSide2) +
    hasQuantityValue(inputs.heatLoad);
  // There must only be one value missing on either side
  const side1 =
    hasQuantityValue(inputs.subCooledLiquidTemperature) ? 1 : 0;
  // hasQuantityValue(inputs.evaporationTemperature);
  const side2 =
    hasQuantityValue(inputs.inletTemperatureSide2) +
    hasQuantityValue(inputs.outletTemperatureSide2);

  if (total !== 1) {// Remove one value from side 2
    SomeFieldsShouldBeAdded([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (total === 2) {// Remove one value from side 2
    SomeFieldsShouldBeRemoved([
      'heatLoad',
      'flowSide2',
    ], inputs, dv, 1);
  }
  if (side1 < 1) {// Add values to side 1
    SomeFieldsShouldBeAdded([
      'evaporationTemperature',
    ], inputs, dv, 2 - side1);
  }
  if (side2 < 2) {// Add values to side 2
    SomeFieldsShouldBeAdded([
      'inletTemperatureSide2',
      'outletTemperatureSide2',
    ], inputs, dv, 2 - side2);
  }

  SomeFieldsShouldBeAdded(['numberOfPlates'], inputs, dv, 1);
  NumberOfPlates(['numberOfPlates'], inputs, dv);

  const outletVaportAndSuperheating =
    hasQuantityValue(inputs.superHeatingTemperature) +
    hasValue(inputs.outletVaporQuality);

  if(outletVaportAndSuperheating === 0){
    SomeFieldsShouldBeAdded([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }
  if(outletVaportAndSuperheating === 2){
    SomeFieldsShouldBeRemoved([
      'outletVaporQuality',
      'superHeatingTemperature',
    ], inputs, dv, 1);
  }

  OneFluidShouldBeSelected('selectedFluidSide1', inputs, dv);
  OneFluidShouldBeSelected('selectedFluidSide2', inputs, dv);
  AtLeastOneExchangerShouldBeSelected('selectedHeatExchangers', inputs, dv);

  dv.isValid = isFormValid(dv);
  return dv;
}
