import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { produce } from "immer";
import VaporPopover from "./VaporPoover";
import PressureQuantityInput from "../../common/QuantityInputs/PressureQuantityInput";
import {UnitGroupsNames, UnitGroups, Pressure } from "../../../utils/unitGroups.ts";
import get from 'lodash/get';
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import VaporPropertiesPropTypes from "../PropTypes/VaporPropertiesPropTypes";

const buttonStyle = {
  height: "25px",
  fontSize: "14px",
  padding: "1px 6px"
};

const VaporInfoSection = ({
  vaporProperties,
  userSelection,
  onUserSelectionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSubmit = (value) => {
    setIsOpen(false);
    onUserSelectionChange({...value});
  };

  const handleAbort = () => {
    setIsOpen(false);
  };

  const hasValueError = useCallback((quantity) => {
    const value = get(quantity, 'amount', null);
    if (value == null) return true;
    if (value === "") return true;

    const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Pressure);
    const unit = unitGroup.getUnit(quantity.unit);
    const siValue = unit.convertToSI(quantity.amount);

    return !((vaporProperties.pVapMin <= Number(siValue)) && (Number(siValue) <= vaporProperties.pVapMax));
  }, [vaporProperties.pVapMax, vaporProperties.pVapMin]);

  const quantityInput = {
    quantity: userSelection.inputValue,
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const handleQuantityInputChange = (quantityInput) => {
    onUserSelectionChange(produce(userSelection, draft => {
      draft.inputValue = quantityInput.quantity;
    }));
  };

  return (
    <div ref={anchorEl} className="fs-add-info">
      <div style={{width: '85%'}}>
        <div className={clsx('input-group input-group-sm')}>
          <PressureQuantityInput
            quantityInput={quantityInput}
            onChange={handleQuantityInputChange}
            isValid={!hasValueError(userSelection.inputValue)}
            messages={[]}/>

        </div>
      </div>
      <div style={{width: '15%'}}>
        <button
          className="btn btn-light quantity-inputs"
          style={buttonStyle}
          onClick={handleClick}
          type="button" > gas</button>
      </div>
      {isOpen && <VaporPopover
        anchorEl={anchorEl.current}
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        isOpen={isOpen}
        userSelection={userSelection}
        vaporProperties={vaporProperties}/>}
    </div>
  );
};

VaporInfoSection.propTypes = {
  vaporProperties: VaporPropertiesPropTypes.isRequired,
  userSelection: UserSelectionPropTypes,
  onUserSelectionChange: PropTypes.func.isRequired,
};

export default React.memo(VaporInfoSection);
