/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useUnitSystemConversion from 'components/calculation/inputForms/unitSystem/useUnitSystemConversion';
import Quantity from "./Quantity";
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import useQueue from "../hooks/jobs.ts";
import usePrevious from "../hooks/usePrevious";

const parse = (quantityInput) => {
  let amount = null;
  let unit = null;
  if (quantityInput && quantityInput.quantity) {
    amount = quantityInput.quantity.amount;
    unit = quantityInput.quantity.unit;
  } else {
    amount = null;
    unit = quantityInput ? (quantityInput.defaultUserSelectedUnit || quantityInput.defaultMetricUnit || quantityInput.defaultUsUnit) :null;
  }
  return [amount, unit];
};

const QuantityInput = ({ quantityInput, isValid, onChange, conversionFunction: conversionFunctionAsync, className, ...others }) => {
  const [amount, unit] = parse(quantityInput);
  const [currentAmount, setCurrentAmount] = useState(amount);
  const [currentUnit, setCurrentUnit] = useState(unit);
  const [unitSystem, cf] = useUnitSystemConversion();
  const Queue = useQueue();

  const previous = usePrevious({prevUnitSystem: unitSystem, prevDefaultUserSelectedUnit: quantityInput.defaultUserSelectedUnit, prevQuantityInput: quantityInput});

  useEffect( () => {

    async function ffff() {
      // execute only one of the side effects
      if (isNil(get(previous, 'prevDefaultUserSelectedUnit')) && !isNil(quantityInput.defaultUserSelectedUnit)) {       // first run and user default selected unit is provided
        if (amount != null && unit != null) {
          const newAmount = await conversionFunctionAsync(amount, unit, quantityInput.defaultUserSelectedUnit);
          onChange({...quantityInput, quantity: { amount:newAmount, unit: quantityInput.defaultUserSelectedUnit }});
        } else {
          setCurrentUnit(quantityInput.defaultUserSelectedUnit);
        }
      } else if (isNil(get(previous, 'prevUnitSystem')) && !isNil(unitSystem)) { // first run and unit system specified
        const quantity = await cf(quantityInput, conversionFunctionAsync);
        if (quantity === null) {
          setCurrentUnit(unitSystem.toLowerCase() === "metric" ? quantityInput.defaultMetricUnit : quantityInput.defaultUsUnit);
        } else {
          onChange({...quantityInput, quantity : quantity});
        }
      } else if (!isNil(previous.prevDefaultUserSelectedUnit) && !isNil(quantityInput.defaultUserSelectedUnit) && !isEqual(previous.prevDefaultUserSelectedUnit, quantityInput.defaultUserSelectedUnit)) {
        if (amount != null && unit != null) {
          const newAmount = await conversionFunctionAsync(amount, unit, quantityInput.defaultUserSelectedUnit);
          onChange({...quantityInput, quantity: { amount:newAmount, unit: quantityInput.defaultUserSelectedUnit }});
        } else {
          setCurrentUnit(quantityInput.defaultUserSelectedUnit);
        }
      } else if (!isNil(get(previous, 'prevUnitSystem')) && !isNil(unitSystem) && !isEqual(get(previous, 'prevUnitSystem'), unitSystem)) {
        const quantity = await cf(quantityInput, conversionFunctionAsync);
        if (quantity === null) {
          setCurrentUnit(unitSystem.toLowerCase() === "metric" ? quantityInput.defaultMetricUnit : quantityInput.defaultUsUnit);
        } else {
          onChange({...quantityInput, quantity : quantity});
        }
      }
    }

//    ffff();
    Queue.addJob({
      task: ffff
    });

  }, [unitSystem, quantityInput.defaultUserSelectedUnit]);

  useEffect(() => {
    setCurrentAmount(amount);
    if (quantityInput.quantity == null && currentUnit == null) {
      setCurrentUnit(quantityInput.defaultUserSelectedUnit || (unitSystem.toLowerCase() === "metric" ?  quantityInput.defaultMetricUnit : quantityInput.defaultUsUnit));
    // } else if (quantityInput.quantity == null && currentUnit != null) {
      //setCurrentUnit(currentUnit);
    } else if (typeof quantityInput.quantity === 'object' && quantityInput.quantity != null){
      setCurrentUnit(quantityInput.quantity.unit);
    }
  }, [quantityInput]);

  const handleAmountChange = useCallback((amount, unit) => {

    let quantity = null;
    if (amount != null && unit != null) {
      quantity = { amount: amount, unit: unit };
    }

    onChange({...quantityInput, quantity: quantity});
    setCurrentAmount(amount);
  }, [onChange, quantityInput]);

  const handleUnitChange = useCallback(async (amount, fromUnit, toUnit) => {
    setCurrentUnit(toUnit);
    let quantity = null;
    if (amount != null && fromUnit != null && toUnit != null) {
      let newAmount = await conversionFunctionAsync(amount, fromUnit, toUnit);
      quantity = { amount: newAmount, unit: toUnit };
    }
    onChange({...quantityInput, defaultUserSelectedUnit: toUnit, quantity: quantity });
  }, [onChange, conversionFunctionAsync, quantityInput]);

  return (
    <Quantity
      amount={currentAmount}
      unit={currentUnit}
      unitGroupName={quantityInput.unitGroup}
      onAmountChange={(newAmount) => handleAmountChange(newAmount, currentUnit)}
      onUnitChange={(newUnit) => handleUnitChange(currentAmount, currentUnit, newUnit)}
      isValid={isValid}
      className={className}
      {...others}/>
  );
};

QuantityInput.propTypes = {
  quantityInput: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  conversionFunction: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readonly: PropTypes.bool,
  amountComponentProps: PropTypes.object,
  unitComponentProps: PropTypes.object
};

export default React.memo(QuantityInput);
