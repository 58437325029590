// import redux-persist hydrate action
import { REHYDRATE } from 'redux-persist/es/constants';

const preventHydratationMiddleware = store => next => action => {
  if (action.type === REHYDRATE) {
    if (!store.hydratationState) {
      store.hydratationState = {};
    }
    // console.log("preventHydratationMiddleware action: ", hydratationState  );
    // rehydrate only once for each key
    if (!store.hydratationState[action.key]) {
      store.hydratationState[action.key] = { isHydrated: false };
    }

    // console.log(hydratationState);

    if (store.hydratationState[action.key].isHydrated) {
      return;
    } else {
      store.hydratationState[action.key].isHydrated = true;
      next(action);
    }

  } else {
    next(action);
  }
};

export default preventHydratationMiddleware;
