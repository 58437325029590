import React, { Suspense } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import {ErrorBoundary} from 'react-error-boundary';
import GeneralFallback from '../../../../../../Errors/GeneralFallback';

const LazyComponent = React.lazy(() => import('./TDTwoStageHxDisplay'));

const Loadable = props => {
  return (
    <ErrorBoundary FallbackComponent={GeneralFallback}>
      <Suspense fallback={<LoadingIndicator />}>
        <LazyComponent {...props}/>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Loadable;
