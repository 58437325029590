import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import throttle from 'lodash/throttle';
import round from 'lodash/round';
import ValidationTooltip from "../calculation/inputForms/validations/ValidationTooltip";
import './NumericInput.scss';

const NumericInput = ({amount, onChange, isValid, displayFormat, readonly, messages, className, ...others}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = useCallback((e) => {
      return throttle((number) => {
        if (number === "") {
          number = null;
        } else {
          number = parseFloat(number);
        }
        if (number !== amount) {
          onChange(number);
        }
      }, 400)(e.target.value);
    }, [onChange, amount]);

    const handleOnFocus = useCallback(() => setIsFocused(true), []);
    const handleOnBlur = useCallback(() => setIsFocused(false), []);

    let displayValue = '';
    if (amount != null) {
        if (displayFormat && !isFocused) {
            displayValue = displayFormat(amount);
        }
        else {
             displayValue = round(amount, 13);
        }
    }

    return (
      <ValidationTooltip messages={messages}>
        <input
          type="number"
          className={clsx("form-control",{'is-invalid': !isValid}, className)}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onWheel={() => {}}
          readOnly={readonly || false}
          {...others} />
      </ValidationTooltip>
    );
};

NumericInput.propTypes = {
    amount: PropTypes.number,
    onChange: PropTypes.func,
    isValid: PropTypes.bool,
    displayFormat: PropTypes.func,
    readonly: PropTypes.bool,
    messages: PropTypes.array,
    className: PropTypes.string
};

export default React.memo(NumericInput);
