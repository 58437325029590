import React, { StrictMode } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import r from '../containers/App/rrr';
import {PersistGate} from 'redux-persist/integration/react';
import LoadingIndicator from './LoadingIndicator';

const Root = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={<LoadingIndicator/>} persistor={store.persistor}>
          <RouterProvider router={r}/>
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired
};


export default Root;
