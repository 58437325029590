import React from 'react';
import PropTypes from 'prop-types';
import {calculationTypesNames} from '../../../constants/calculationTypes';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';

const CalculationHeader = ({ calculationType }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography align="center" color="primary" variant="h5" paragraph>{t('Calculation')} - {t(calculationTypesNames[calculationType])}</Typography>
    </div>

  );
};

CalculationHeader.propTypes = {
  calculationType: PropTypes.string.isRequired,
};

export default React.memo(CalculationHeader);
