export function mapFluid(f) {
  let x = {...f};
  if (f.isLeaf === true) {
        x.title = f.name;
        x.value = `f${f.parentId}_${f.id}`;
        x.pId = f.parentId * 1;
        x.parentID = f.parentId * 1;
        x.key = `f${f.parentId}_${f.id}`;
        x.isLeaf = true;
    } else {
        x.title = f.name;
        x.value = `${f.id}.${f.name}`;
        x.value = f.id;
        x.pId = f.parentId * 1;
        x.key =  f.id;
        x.isLeaf = false;
    }
    return x;
}

export const getTralationKey = (calculationType) => {
    switch(calculationType.toLowerCase()) {
        case "singlephase": return "SinglePhase";
        case "singlephasedual": return "SinglePhaseDual";
        case "cascade": return "Cascade";
        case "condenser": return "Condenser";
        case "condenserdual": return "CondenserDual";
        case "evaporator": return "Evaporator";
        case "liquidevaporator": return "LiquidEvaporator";
        case "evaporatordual": return "EvaporatorDual";
        case "nhpcondenser": return "NHPCondenser";
        case "nhpevaporator": return "NHPEvaporator";
        case "ahri": return "AHRI";
        case "twostage": return "TwoStage";
        case "airdryer": return "AirDryer";
        case "districtenergy": return "DistrictEnergy";
        case "hypertwain": return "Hypertwain";
        default: throw new Error("Invalid calculation type given!");
    }
};
