import React from 'react';
import PropTypes from 'prop-types';
import {IconLab} from "components/Icons";
import {useTranslation} from "react-i18next";

const FluidPropertiesButton = (props) => {
  const { t } = useTranslation();
  const onClick = () => {
    const {fluidId, isGas} = props.selectedFluid;
    const amount = props.selectedFluid.inputValue ? props.selectedFluid.inputValue.amount: 0;
    const unit = props.selectedFluid.inputValue ? encodeURI(props.selectedFluid.inputValue.unit): '';

    window.open(`/fluidProperties/${fluidId}/${amount}/${unit}/${isGas === 'gas'}`, "_blank");
  };

  return (
    <a className="btn-fluid-props" title={t("HamburgerFluidProperties")} onClick={onClick}><IconLab size={props.size}/></a>
  );
};

FluidPropertiesButton.propTypes = {
  size: PropTypes.number,
  selectedFluid: PropTypes.object
};

export default React.memo(FluidPropertiesButton);
