import { initialState } from './reducer';
import {createSelector} from 'reselect';

const selectCalculations = state => state.newCalculations || initialState;

const makeSelectCalculation = (uuid) =>
  createSelector(selectCalculations, state => state.calculations.find(x => x.uuid === uuid));


const makeSelectCalculationInputs = (uuid) =>
  createSelector(selectCalculations, s => {
    const calculation = s.calculations.find(x => x.uuid === uuid);
    return calculation ? calculation.inputParams : null;
  });

const makeSelectCalculationResult = (uuid) =>
  createSelector(selectCalculations, s => {
    const calculation = s.calculations.find(x => x.uuid === uuid);
    return calculation ? calculation.response : null;
});

const makeSelectSelectedResult = (uuid) => {
  return createSelector(selectCalculations, s => {
    const calculation = s.calculations.find(x => x.uuid === uuid);
    return calculation ? calculation.selectedExchanger : null;
  });
};

const makeSelectSelectedSubResult = (uuid) =>
  createSelector(selectCalculations, s => {
    const calculation = s.calculations.find(x => x.uuid === uuid);
    return calculation ? calculation.selectedSubResult : null;
  });

export {
  makeSelectCalculation,
  makeSelectCalculationInputs,
  makeSelectCalculationResult,
  makeSelectSelectedResult,
  makeSelectSelectedSubResult
};
