import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {createApplicationResultPrintout, createCalculationResultPrintout} from '../../../actions/printoutActions';
import get from 'lodash/get';
import {CalculationResponseType} from '../../../constants/CalculationResponseType.ts';
import CalculationOutputs from '../../../components/calculation/result/Calculation/CaculationOutputsLoadable';
import ApplicationOutputs from '../../../components/calculation/result/Application/ApplicationOutputsLoadable';
import {
  makeSelectUserProfile
} from '../../../selectors/userProfileSelectors';
import {
  makeSelectCalculationResult,
  makeSelectSelectedResult,
  makeSelectSelectedSubResult
} from '../selector';
import {
  changeSelectedResult,
  changeSelectedSubResult,
  downloadProductSheet
} from '../actions';
import {
  generateCalculationPathWithSelectedResult,
  generateProductSelectorArticlesPath,
} from '../../App/RouterPaths';
import {filterDefaults} from '../../../reducers/productSelectorReducer';

const CalculationResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { calculationType, uuid } = useParams();

  const response = useSelector(makeSelectCalculationResult(uuid));
  const inputs = response.inputParams;
  const selectedResult = useSelector(makeSelectSelectedResult(uuid));
  const selectedSubResult = useSelector(makeSelectSelectedSubResult(uuid));
  const userProfile = useSelector(makeSelectUserProfile());

  const handleGetProductNumber = useCallback((xpc) => {
    dispatch(downloadProductSheet(xpc));
  }, [dispatch]);

  const handleOnExchangerChange = useCallback((result) => {
    navigate(generateCalculationPathWithSelectedResult({
      calculationType: calculationType,
      uuid: uuid,
      resultId: result.guid
    }));
    dispatch(changeSelectedResult(uuid, result));
  }, [calculationType, dispatch, navigate, uuid]);

  const handleOnSelectedSubResultChange = useCallback((calculationType, result, subResult) => {
    navigate(generateCalculationPathWithSelectedResult({
      calculationType: calculationType,
      uuid: uuid,
      resultId: result.guid,
      subResultId: subResult ? subResult.guid : null
    }));
    dispatch(changeSelectedSubResult(uuid, result, subResult));
  }, [dispatch, navigate, uuid]);

  const handleCreateCalculationResultPrintout = useCallback((selectedPrintoutFormat) => {
    dispatch(createCalculationResultPrintout(calculationType, null, [], null, selectedPrintoutFormat, false, null, null, uuid));
  }, [calculationType, dispatch, uuid]);

  const handleCreateApplicationResultPrintout = useCallback((selectedPrintoutFormat) => {
    dispatch(createApplicationResultPrintout(calculationType, selectedPrintoutFormat, false, null, null, selectedSubResult, uuid));
  }, [calculationType, dispatch, selectedSubResult, uuid]);

  const handleProductSelectorOpen = useCallback(() => {
    const j = JSON.stringify({...filterDefaults, ...inputs.filterSettings});
    const p = encodeURIComponent(j);
    navigate(generateProductSelectorArticlesPath({
      uuid: uuid,
      queryParams: `initf=${p}`
    }));
  }, [inputs.filterSettings, navigate, uuid]);

  if (!response) return null;

  const calculationResponseType = get(response, 'calculationResponseType', null);

  let component = null;
  switch (calculationResponseType) {
    case CalculationResponseType.Calculation: {
      component = (<CalculationOutputs
        uuid={uuid}
        onExchangerChange={handleOnExchangerChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        onCreatePrintout={handleCreateCalculationResultPrintout}
        onProductSelectorOpen={handleProductSelectorOpen}
        userProfile={userProfile}
        getProductNumber={handleGetProductNumber}/>);
      break;
    }
    case CalculationResponseType.Application: {
      component = (<ApplicationOutputs
        uuid={uuid}
        calculationType={calculationType}
        onSelectedSubResultChange={handleOnSelectedSubResultChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        selectedSubResult={selectedSubResult}
        userProfile={userProfile}
        onCreatePrintout={handleCreateApplicationResultPrintout}
        getProductNumber={handleGetProductNumber}/>);
      break;
    }
    default:
      component = null;
      break;
  }

  return (
    <div>
      <div>{component}</div>
      <div><Outlet/></div>
    </div>
  );
};

export default React.memo(CalculationResults);
