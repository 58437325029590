import React from 'react';
import PropTypes from 'prop-types';
import CalculationResultDetail from "./CalculationResultDetails";

const CalculationResultData = ({
  selectedExchanger,
  inputs,
  calculationType,
  getProductNumber,
  workingLanguage,
}) => {
  let selectedRow = null;

  if (selectedExchanger && selectedExchanger.name) {
    selectedRow = (<CalculationResultDetail data={selectedExchanger}
                                            inputs={inputs}
                                            calculationType={calculationType}
                                            getProductNumber={getProductNumber}
                                            workingLanguage={workingLanguage}/>);
  }
  return selectedRow;
};

CalculationResultData.propTypes = {
  selectedExchanger: PropTypes.object,
  inputs: PropTypes.object,
  calculationType: PropTypes.string,
  getProductNumber: PropTypes.func,
  workingLanguage: PropTypes.string
};

export default React.memo(CalculationResultData);
