/* eslint-disable import/default */
import React from 'react';
import { createRoot } from 'react-dom/client';
import configureStore from './store/configureStore';
require('./favicon.ico'); // Tell webpack to load favicon.ico
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Root from 'components/Root';
import 'api/axiosDefaults';
import 'autotrack';

if (process.env.NODE_ENV !== 'development') {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        // eslint-disable-next-line no-console
        registration.onupdatefound = function () {
          // eslint-disable-next-line no-console
          console.log('An update has been found...');

          const installingWorker = registration.installing;

          installingWorker.onstatechange = function () {
            switch (installingWorker.state) {
              case 'installed':
                // eslint-disable-next-line no-console
                console.log('New Service Worker installed.');
                break;

              case 'redundant':
                // eslint-disable-next-line no-console
                console.error('The installing service worker became redundant.');
                break;

              default:
                // eslint-disable-next-line no-console
                console.log('Installing Service Worker state:', installingWorker.state);
            }
          };
        };

        // eslint-disable-next-line no-console
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        // eslint-disable-next-line no-console
        console.log('SW registration failed: ', registrationError);
      });
    });
  }
}


const {store, persistor} = configureStore();


const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE);
root.render(
  <Root store={store} persistor={persistor} />
);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
