import { combineReducers } from 'redux';
import history from '../utils/history';
import application from './applicationReducer';
import unitConversion from './unitConversionReducer';
import loadItems from './loadItemsReducer';
import calculations from '../containers/calculations/calculationsReducer';
import newCalculations from '../containers/calculations/reducer';
import filters from './filtersReducer';
import userProfile from './userProfileReducer';
import connections from './connectionsReducer';
import {createReduxHistoryContext} from "redux-first-history";
const {
  routerReducer
} = createReduxHistoryContext({ history });
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

const persistConfig = {
  key: 'root',
  storage: storage('myDB')
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: routerReducer,
    application,
    unitConversion,
    loadItems,
    calculations,
    filters,
    userProfile,
    connections,
    newCalculations,
    ...injectedReducers,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return persistedReducer;
}
