import * as types from '../constants/actionTypes';

export function selectHeatExchanger(calculationType, selectedExchanger) {
    return { type: types.SELECT_HEAT_EXCHANGER, calculationType, selectedExchanger };
}

export function selectSubResult(calculationType, selectedResult, selectedSubResult) {
  return {
    type: types.SELECT_SUB_RESULT,
    calculationType: calculationType,
    selectedResult: selectedResult,
    selectedSubResult: selectedSubResult
  };
}

export function calculateBegin(calculationType) {
    return { type: types.Calculation.CALCULATE_BEGIN, calculationType };
}

export function calculateSuccess(calculationType, calculationResult) {
    return { type: types.Calculation.CALCULATE_SUCCESS, calculationType, value: calculationResult };
}

export function processCalculation(calculationType, calculationMethod) {
    return {type: types.Calculation.CALCULATE, calculationType, calculationMethod};
}

export function changeFilters(calculationType, name, selection) {
    return {
        type: types.FILTERS_SELECTION_CHANGED1,
        calculationType: calculationType,
        name: name,
        selection: selection
    };
}

export function changeCalculationInputFields(calculationType, values) {
    return {
        type: types.Calculation.UPDATE_PARAMETERS,
        calculationType: calculationType,
        values: values
    };
}

export function loadCalculationInputs(userId, calculationType, calculationInputId = null, sessionId = null, exchangers= null, inputParams = null) {
  return {
        type: types.Calculation.INPUTS_LOAD,
        userId: userId,
        calculationType: calculationType,
        calculationInputId: calculationInputId,
        sessionId: sessionId,
        exchangers: exchangers,
        inputParams: inputParams
    };
}

export function loadCalculationInputsSuccess(calculationType, calculationId, calculationInputs) {
    return {
        type: types.Calculation.INPUTS_LOAD_SUCCESS,
        calculationType: calculationType,
        calculationId: calculationId,
        value: calculationInputs
    };
}

export function loadCalculationInputsError(error) {
    return {
        type: types.Calculation.INPUTS_LOAD_ERROR,
        error: error
    };
}

export function loadCalculationInputsHeatExchangers(calculationType, userId, modelRestrictions, isCoCurrent, fluid1, fluid2) {
    return {
        type: types.Calculation.INPUTS_HEATEXCHANGERS_LOAD,
        calculationType: calculationType,
        userId: userId,
        modelRestrictions: modelRestrictions,
        isCoCurrent: isCoCurrent,
        fluid1: fluid1,
        fluid2: fluid2
    };
}

export function loadCalculationInputsHeatExchangersSuccess(calculationType, exchangers) {
    return {
        type: types.Calculation.INPUTS_HEATEXCHANGERS_SUCCESS,
        calculationType: calculationType,
        exchangers: exchangers
    };
}

export function loadCalculationInputsHeatExchangersError(error) {
    return {
        type: types.Calculation.INPUTS_HEATEXCHANGERS_ERROR,
        error: error
    };
}

export function saveCalculationInputs(uuid, name, note, tags, callback) {
    return {
        type: types.Calculation.INPUTS_SAVE,
        uuid: uuid,
        customCalculationName: name,
        note: note,
        tags: tags,
        callback: callback
    };
}

export function saveCalculationInputsSuccess() {
    return {
        type: types.Calculation.INPUTS_SAVE_SUCCESS
    };
}

export function saveCalculationInputsError(error) {
    return {
        type: types.Calculation.INPUTS_SAVE_ERROR,
        error: error
    };
}

export function getProductNumber(xpc, callback) {
    return {
        type: types.PRODUCT_NUMBER_GET,
        xpc,
        callback
    };
}

export function setInputFormValidity(calculationType, isFormValid) {
    return {
        type: types.Calculation.SET_INPUTS_VALIDITY,
        calculationType: calculationType,
        value: isFormValid
    };
}

export function saveFilterSettings(sourceCalculationType, targetCalculationType ) {
    return {
        type: types.Calculation.FILTERS_SAVE,
        sourceCalculationType: sourceCalculationType,
        targetCalculationType: targetCalculationType
    };
}

export function saveFilterSettingsSuccess(calculationFilters, targetCalculationType) {
    return {
        type: types.Calculation.FILTERS_SAVE_SUCCESS,
        calculationFilters: calculationFilters,
        calculationType: targetCalculationType
    };
}

export function saveFilterSettingsError(error) {
    return {
        type: types.Calculation.FILTERS_SAVE_ERROR,
        error: error
    };
}

export function resetFilterSettings(sourceCalculationType, targetCalculationType) {
    return {
        type: types.Calculation.FILTERS_RESET,
        sourceCalculationType: sourceCalculationType,
        targetCalculationType: targetCalculationType
    };
}

export function resetFilterSettingsSuccess(targetCalculationType) {
    return {
        type: types.Calculation.FILTERS_RESET_SUCCESS,
        calculationType: targetCalculationType
    };
}

export function resetFilterSettingsError() {
    return {
        type: types.Calculation.FILTERS_RESET_ERROR
    };
}

export function saveDefaultCalculationInputs(calculationType) {
  return {
    type: types.Calculation.INPUTS_DEFAULT_SAVE,
    calculationType: calculationType
  };
}

export function saveDefaultCalculationInputsSuccess() {
  return {
    type: types.Calculation.INPUTS_DEFAULT_SAVE_SUCCESS
  };
}

export function saveDefaultCalculationInputsError(error) {
  return {
    type: types.Calculation.INPUTS_DEFAULT_SAVE_ERROR,
    error: error
  };
}

export function deleteDefaultCalculationInputs(calculationType) {
  return {
    type: types.Calculation.INPUTS_DEFAULT_DELETE,
    calculationType: calculationType
  };
}

export function deleteDefaultCalculationInputsSuccess() {
  return {
    type: types.Calculation.INPUTS_DEFAULT_DELETE_SUCCESS
  };
}

export function deleteDefaultCalculationInputsError(error) {
  return {
    type: types.Calculation.INPUTS_DEFAULT_DELETE_ERROR,
    error: error
  };
}

export function deleteAllDefaultCalculationInputs(openedCalculationType) {
  return {
    type: types.Calculation.INPUTS_ALL_DEFAULT_DELETE,
    openedCalculationType: openedCalculationType
  };
}

export function deleteAllDefaultCalculationInputsSuccess() {
  return {
    type: types.Calculation.INPUTS_ALL_DEFAULT_DELETE_SUCCESS
  };
}

export function deleteAllDefaultCalculationInputsError(error) {
  return {
    type: types.Calculation.INPUTS_ALL_DEFAULT_DELETE_ERROR,
    error: error
  };
}

export function changeCalculationFiltersSelection(calculationType, filterSelection) {
    return {
        type: types.Calculation.FILTERS_CHANGE,
        calculationType: calculationType,
        filterSelection: filterSelection
    };
}

export function changeCalculationOutputColumns(calculationType, userDefaultCalculationOutputColumns) {
  return {
    type: types.Calculation.OUTPUT_COLUMNS_CHANGE,
    calculationType: calculationType,
    userDefaultCalculationOutputColumns: userDefaultCalculationOutputColumns
  };
}
