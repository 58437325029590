enum Operation {
  Add,
  Remove,
}

interface IMessage {
  composeString(translate: any): string;
}

class ValidationMessage implements IMessage {
  private readonly _operation: Operation;
  private readonly _numberOfValues: number;
  private readonly _fieldNames: Array<string>;

  constructor(operation: Operation, numberOfValues: number, fieldNames: Array<string>) {
    this._operation = operation;
    this._numberOfValues = numberOfValues;
    this._fieldNames = fieldNames;
  }

  get operation(): Operation {
    return this._operation;
  }

  get numberOfValues(): number {
    return this._numberOfValues;
  }

  get fieldNames(): Array<string> {
    return this._fieldNames;
  }

  composeString(translate: (arg0: string) => string): string {
    const fieldNames = this.fieldNames.map(fieldName => translate(fieldName)).join("\n\r");
    return `${this.operation === 0 ? 'Add' : 'Remove'} ${this.numberOfValues} of following inputs: \n\r${fieldNames}`;
  }
}

class SimpleMessage implements IMessage {
  private readonly _text: string;

  constructor(text: string) {
    this._text = text;
  }


  get text(): string {
    return this._text;
  }

  composeString(translate: any): string {
    return this._text;
  }
}

export {
  Operation,
  ValidationMessage,
  SimpleMessage
};
