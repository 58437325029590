import { produce } from 'immer';
import { 
    SAVED_CALCULATION_LIST_LOAD_ALL,
    SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS,
    SAVED_CALCULATION_LIST_LOAD_ALL_ERROR,
    SAVED_CALCULATION_LIST_DELETE_ONE,
    SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS,
    SAVED_CALCULATION_LIST_DELETE_ONE_ERROR,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR

} from './constants';

// The initial state of the App
export const initialState = {
    loading: false,
    error: false,
    calculations: [],
    autocompleteTags: []
  };

const savedCalculationListReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch(action.type) {
        case SAVED_CALCULATION_LIST_LOAD_ALL:
            draft.loading = true;
            draft.error= false;
            draft.calculations = [];
            break;
        case SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS:
            draft.loading = false;
            draft.error= false;
            draft.calculations = action.calculations;
            break;
        case SAVED_CALCULATION_LIST_LOAD_ALL_ERROR:
            draft.loading = false;
            draft.error= action.error;
            draft.calculations = [];
            break;
        case SAVED_CALCULATION_LIST_DELETE_ONE:
            draft.loading = false;
            draft.error= false;
            break;
        case SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS:
            draft.loading = false;
            draft.error= false;
            draft.calculations = [...state.calculations.filter(x => x.id !== action.id)];
            break;
        case SAVED_CALCULATION_LIST_DELETE_ONE_ERROR:
            draft.loading = false;
            draft.error= action.error;
            break;
        case SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS:
            draft.calculations = [...state.calculations.filter(x => x.id !== action.savedCalculationInputs.id), action.savedCalculationInputs];
            break;
        case SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR:

        break;
        case CALCULATION_INPUTS_TAGS_AUTOCOMPLETE:
            draft.autocompleteTags = [];
            break;
        case CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS:
            draft.autocompleteTags = action.tags;
            break;
        case CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR:
            draft.autocompleteTags = [];
            break;
    }
  });

  export default savedCalculationListReducer;