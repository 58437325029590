import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import {useTranslation} from "react-i18next";

const AutocompleteTagInput = ({ options, values, onChange}) => {
    const { t } = useTranslation();
    const handleChange = (event, value) => {
        onChange(value);
    };

    return (
        <Autocomplete
          autoComplete={true}
          freeSolo={true}
          multiple
          id="fixed-tags-demo"
          options={options}
          value={values}
          onChange={handleChange}
          getOptionLabel={option => option}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} label={option} {...getTagProps({ index })} />
            ))
          }

          renderInput={params => (
            <TextField
              {...params}
              label={t('Calculation.SaveCalculation.Content.CustomTags.Label.Text', 'Custom tags')}
              variant="standard"
              placeholder={t('Calculation.SaveCalculation.Content.EnterTags.Placeholder.Text', 'Enter tags')}
              fullWidth />
          )}
        />
      );
};

AutocompleteTagInput.propTypes = {
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default AutocompleteTagInput;
