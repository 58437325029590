import React from "react";
import UnitSystemDispatchContext from './UnitSystemDispatchContext';

const useUnitSystemDispatch = () => {
  const context = React.useContext(UnitSystemDispatchContext);
  if (context === undefined) {
    throw new Error('useUnitSystemDispatch must be used within a UnitSystemProvider');
  }
  return context;
};

export default useUnitSystemDispatch;
