import { useMemo } from 'react';

import { UnitGroups } from 'utils/unitGroups.ts';
import round from 'lodash/round';
import {default as UUS} from "../../inputForms/unitSystem/useUnitSystem";

const convertFromSI = (unitSystem, unitGroupName, siAmount, densities = null) => {
  let toUnit = UnitGroups.GetDefaultUnitForMeasuringSystem(unitGroupName, unitSystem);
  return toUnit.convertFromSI(siAmount, densities);
};

const convert = (unitSystem, unitGroupName, amount, unit, densities = null) => {
  let toUnit = UnitGroups.GetDefaultUnitForMeasuringSystem(unitGroupName, unitSystem);
  return UnitGroups.Convert(unitGroupName, amount, unit, toUnit.name, densities);
};

const convertToSI = (unitGroupName, value, unitName, densities) => {
  const unitGroup = UnitGroups.getUnitGroup(unitGroupName);
  const unit = unitGroup.getUnit(unitName);
  return unit.convertToSI(value, densities);
};

const convertFromSIToAllUnits = (unitGroupName, siAmount, densities = null) => {
  return UnitGroups.getUnitGroup(unitGroupName).units.map(u => {
    return {
      amount: u.convertFromSI(siAmount, densities),
      unit: u.name,
      unitGroup: unitGroupName
    };
  });
};

const getDefaultUnit = (unitSystem, unitGroupName) => {
  let toUnit = UnitGroups.GetDefaultUnitForMeasuringSystem(unitGroupName, unitSystem);
  return toUnit.name;
};

const format = (unitGroupName, amount) => {
  let unitGroup = UnitGroups.getUnitGroup(unitGroupName);
  return RoundToSignificantDigitsWithOffset(amount, unitGroup.signDigits, unitGroup.offset);
};

const RoundToSignificantDigitsWithOffset = (x, significantDigits, offset) => {
  let exp10;
  let sci = 0;
  if ((Math.abs(x) + offset) === 0) exp10 = 0;
  else exp10 = Math.floor(Math.log10(Math.abs(x) + offset)) + 1;
  if ((exp10 <= -2) || (exp10 > 6)) sci = Math.floor((exp10 - 1) / 3) * 3;
  //else if (exp10 > 6) sci = Math.Floor(exp10 / 3) * 3;
  let rounded = round(x / Math.pow(10, exp10), significantDigits) * Math.pow(10, exp10 - sci);

  if (significantDigits < (exp10 - sci)) {
    return rounded.toFixed(0);
  }
  else
  {
    if (sci === 0) {
      return rounded.toFixed(significantDigits - exp10);
    } else {
      return rounded.toFixed(significantDigits - exp10 + sci)+ "e" + sci;
    }
  }
};

const useUnitSystem = () => {
  const [unitSystem] = UUS();

  return useMemo(() => {
    return {
      unitSystem: unitSystem,
      convertFromSI: (unitGroupName, siAmount, densities = null) => convertFromSI(unitSystem, unitGroupName, siAmount, densities),
      convert: (unitGroupName, amount, unit, densities = null) => convert(unitSystem, unitGroupName, amount, unit, densities),
      getDefaultUnit: (unitGroupName) => getDefaultUnit(unitSystem, unitGroupName),
      format: (unitGroupName, amount) => format(unitGroupName, amount)
    };
  }, [unitSystem]);
};

export {
  useUnitSystem,
  convertFromSIToAllUnits,
  convertToSI,
  convertFromSI,
  format,
  RoundToSignificantDigitsWithOffset,
  getDefaultUnit
};
