import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import browserHistory from 'utils/history';
import {ClickAnalyticsPlugin} from '@microsoft/applicationinsights-clickanalytics-js';

const reactPlugin = new ReactPlugin();
// // *** Add the Click Analytics plug-in. ***
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
 autoCapture: true
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.applicationInsightConnectionString,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    },
    /* ...Other Configuration Options... */
    //autoTrackPageVisitTime: true,
    //enableAutoRouteTracking: true,
    disableTelemetry: false
  }
});

appInsights.loadAppInsights();

export default appInsights;
export {
  reactPlugin,
  appInsights
};
