import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectCalculationResponse,
  makeSelectSelectedResult,
  makeSelectSelectedSubResult,
} from "selectors/calculationSelectors";
import {makeSelectUserProfile} from "selectors/userProfileSelectors";
import {getProductNumber, selectHeatExchanger, selectSubResult } from 'actions/calculationActions';
import get from 'lodash/get';
import 'styles/styles.scss';
import {createApplicationResultPrintout, createCalculationResultPrintout} from "../../../actions/printoutActions";
import {CalculationResponseType} from "../../../constants/CalculationResponseType.ts";
import CalculationOutputs from './Calculation/CaculationOutputsLoadable';
import ApplicationOutputs from './Application/ApplicationOutputsLoadable';

const ResultDispatcher = ({ calculationType }) => {
  const dispatch = useDispatch();

  const response = useSelector(makeSelectCalculationResponse(calculationType));
  const inputs = response.inputParams;
  const selectedResult = useSelector(makeSelectSelectedResult(calculationType));
  const selectedSubResult = useSelector(makeSelectSelectedSubResult(calculationType));
  const userProfile = useSelector(makeSelectUserProfile());

  const handleGetProductNumber = useCallback((xpc, callback) => {
    dispatch(getProductNumber(xpc, callback));
  }, [dispatch]);

  const handleOnExchangerChange = useCallback((result) => {
    dispatch(selectHeatExchanger(calculationType, result));
  }, [calculationType, dispatch]);

  const handleOnSelectedSubResultChange = useCallback((calculationType, result, subResult) => {
    dispatch(selectSubResult(calculationType, result, subResult));
  }, [dispatch]);

  const handleCreateCalculationResultPrintout = useCallback((selectedPrintoutLanguage) => {
    dispatch(createCalculationResultPrintout(calculationType, null, [], null,  selectedPrintoutLanguage, false, null, null));
  }, [calculationType, dispatch]);

  const handleCreateApplicationResultPrintout = useCallback((selectedPrintoutFormat) => {
    dispatch(createApplicationResultPrintout(calculationType, selectedPrintoutFormat, false, null, null, selectedSubResult));
  }, [calculationType, dispatch, selectedSubResult]);

  const handleProductSelectorOpen = useCallback(() => {
    alert("Does not work anymore");
  }, []);

  const calculationResponseType = get(response, 'calculationResponseType', null);
  switch (calculationResponseType) {
    case CalculationResponseType.Calculation: {
      return (<CalculationOutputs
        onExchangerChange={handleOnExchangerChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        onCreatePrintout={handleCreateCalculationResultPrintout}
        onProductSelectorOpen={handleProductSelectorOpen}
        userProfile={userProfile}
        getProductNumber={handleGetProductNumber}/>);
    }
    case CalculationResponseType.Application: {
      return (<ApplicationOutputs
        calculationType={calculationType}
        onSelectedSubResultChange={handleOnSelectedSubResultChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        selectedSubResult={selectedSubResult}
        userProfile={userProfile}
        onCreatePrintout={handleCreateApplicationResultPrintout}
        getProductNumber={handleGetProductNumber}/>);
    }
    default:
      return null;
  }
};

ResultDispatcher.propTypes = {
  calculationType: PropTypes.string,
};

export default React.memo(ResultDispatcher);
