import useUnitSystem from "./useUnitSystem";
import get from "lodash/get";

const fsss = async (unitSystem, quantityInput, conversionFunctionAsync) => {
  if (quantityInput.quantity === null) return null;

  let toAmount = null;
  let toUnit = null;
  const fromAmount = get(quantityInput, "quantity.amount", null);
  const fromUnit = get(quantityInput, "quantity.unit", null);

  switch (unitSystem.toLowerCase()) {
    case "metric":
      toUnit = quantityInput.defaultMetricUnit;
      break;
    case "us":
      toUnit = quantityInput.defaultUsUnit;
      break;
  }

  if (fromAmount != null && fromUnit != null && toUnit != null && fromUnit !== toUnit) {
    toAmount = await conversionFunctionAsync(fromAmount, fromUnit, toUnit);
  } else {
    toAmount = fromAmount;
  }

  return { amount: toAmount, unit: toUnit };
};


const useUnitSystemConversion = () => {
  const [unitSystem] = useUnitSystem();
  return [
    unitSystem,
    (quantityInput, conversionFunctionAsync) => fsss(unitSystem, quantityInput, conversionFunctionAsync)
  ];
};

export default useUnitSystemConversion;
