import React, {memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import ValidationTooltip from "./calculation/inputForms/validations/ValidationTooltip";
import TreeSelect, {listToTree} from 'components/common/TreeSelect/TreeSelect';
import 'styles/hx-select.scss';

const mapToSelected = (h) => {
  if (h.isLeaf === true) {
    return {
      id: h.realId,
      heatExchangerInputType: "Child"
    };
  } else if (h.isLeaf === false) {
      return {
        id: h.realId,
        heatExchangerInputType: "Parent",
        sort: h.sort
      };
  }
};

const mapHeatExchangers = (h) => {
    if (h.isLeaf === true) {
        return {
            title: h.name,
            value: `${h.id}`,
            pId: `F${h.parentId}`,
            id: `${h.id}`,
            realId: h.id,
            isLeaf: true,
            disabled: false,
            className: h.areBypassedRestrictions === true ? "iconHE restrictedHE" : "iconHE allowedHE",
            tagClassName: h.areBypassedRestrictions === true ? " iconHE restrictedHE" : "iconHE allowedHE",
        };
    } else if (h.isLeaf === false) {
        return {
            title: h.name,
            value: `F${h.id}`,
            pId: `F${h.parentId}`,
            id: `F${h.id}`,
            realId: h.id,
            isLeaf: false,
            className: 'hx-parent iconHE folderHE',
            disabled: true,
            sort: h.sort
        };
    }
};

const sortHex = ( a, b ) => {
  if(a.sort){
    return a.sort - b.sort;
  }
  else {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
};

const equalsType = (selectedItemType, availableItemType) => {
  if (availableItemType.isLeaf === true && selectedItemType.heatExchangerInputType === "Child") return true;
  if (availableItemType.isLeaf === false && selectedItemType.heatExchangerInputType === "Parent") return true;
  return false;
};


const HeatExchangerSelect = ({
  exchangers,
  value,
  onChange,
  name,
  isValid,
  messages,
  isSingleSelect,
  inlineSearchInput
}) => {


  const availableHeatExchangers = useMemo(() => {
    const mappedHeatExchangers = exchangers
      .map(mapHeatExchangers)
      .map((x, index) => {
        let initialCheck = {};
        if(value.some(v => (v.id === x.realId) && (equalsType(v, x)))){
          initialCheck = {checked : true};
        }

        return {...x, ...initialCheck,
          disabled: isSingleSelect ? x.disabled: false,
          id: x.isLeaf ? index + 100: x.id,
          originId: x.id,
          parentId: x.pId,
          label: x.title,
          children: []};
      }).sort(sortHex);
    return listToTree(mappedHeatExchangers, null, null, { id: 'F0' }) || [];
  }, [exchangers, isSingleSelect, value]);

  const handleOnChange = useCallback((value) => {
    let selectedHEs = [];
    if(Array.isArray(value)) {
      selectedHEs = value.map(mapToSelected);
    } else {
      selectedHEs = [mapToSelected(value)];
    }
    onChange(name, [...selectedHEs]);
  }, [name, onChange]);

  // that <div> must be present due to ref forwarding
  return (
    <ValidationTooltip messages={messages}>
      <div className={clsx("hx-select", { 'rc-tree-error': !isValid })}>
        <TreeSelect
            data={availableHeatExchangers}
            className="dtherm-ddl-select"
            placeholder="  "
            mode={isSingleSelect ? "single": "multi"}
            onChange={handleOnChange}
            showPartiallySelected={true}
            deleteAllButton={true}
            inlineSearchInput={inlineSearchInput}
        />
      </div>
    </ValidationTooltip>
  );
};

HeatExchangerSelect.propTypes = {
  exchangers: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  messages: PropTypes.array,
  isSingleSelect: PropTypes.bool,
  inlineSearchInput: PropTypes.bool
};

HeatExchangerSelect.defaultProps = {
  isValid: true
};

export default memo(HeatExchangerSelect);
