import React from "react";
import PropTypes from 'prop-types';
import AllUnitsTooltipContent from "./AllUnitsTooltipContent";
import { convertFromSIToAllUnits } from "./TechnicalData/useUnitConversion";

const AllUnitsTooltip = ({unitGroup, siValue, densities}) => {
  const quantities = convertFromSIToAllUnits(unitGroup, siValue, densities);

  return (
    <AllUnitsTooltipContent quantities={quantities}/>
  );
};

AllUnitsTooltip.propTypes = {
  unitGroup: PropTypes.string.isRequired,
  siValue: PropTypes.number.isRequired,
  densities: PropTypes.object
};

export default AllUnitsTooltip;
