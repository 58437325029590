import React from 'react';
import UnitSystemStateContext from './UnitSystemStateContext';

const useUnitSystemState = () => {
  const context = React.useContext(UnitSystemStateContext);
  if (context === undefined) {
    throw new Error('useUnitSystemState must be used within a UnitSystemProvider');
  }
  return context;
};

export default useUnitSystemState;
