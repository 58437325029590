import React from 'react';
import PropTypes from 'prop-types';
import { UnitGroups, UnitGroupsNames } from 'utils/unitGroups.ts';
import QuantityInput from './QuantityInput';

const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Temperatures);

const handleConversion = (amount, sourceUnit, targetUnit) => {
  const siValue = unitGroup.getUnit(sourceUnit).convertToSI(amount);
  const targetValue = unitGroup.getUnit(targetUnit).convertFromSI(siValue);
  return Promise.resolve(targetValue);
};

const TemperatureQuantityInput = ({quantityInput, isValid, onChange, ...others}) => {
    return (
        <QuantityInput
            quantityInput={quantityInput}
            isValid={isValid}
            onChange={onChange}
            conversionFunction={handleConversion}
            {...others} />
    );
};

TemperatureQuantityInput.propTypes = {
  quantityInput: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readonly: PropTypes.bool,
  amountComponentProps: PropTypes.object,
  unitComponentProps: PropTypes.object
};

export default React.memo(TemperatureQuantityInput);
