import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from "clsx";

const ResultMessages = ({ messages, onIconClick }) => {

  if (messages && messages.length > 0) {
      return messages.map((m, index) => {
          let icon = null;
          switch (m.messageType) {
              case "Information":
                    icon = <InfoIcon sx={{ color: theme => theme.palette.info.main }} fontSize="small" />;
                  break;
              case "Warning":
                  icon = <WarningIcon sx={{ color: theme => theme.palette.warning.main }} fontSize="small"/>;
                  break;
          }
        return (
          <div key={index}>
            <span
              className={clsx({"result-message": m.hasLink})}
              onClick={(e) => onIconClick(e.currentTarget, m.code)}>
              {icon} {m.text}
            </span>
          </div>
        );
      });
  } else {
      return null;
  }
};

ResultMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  onIconClick: PropTypes.func.isRequired,
};

export default ResultMessages;
