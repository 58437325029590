import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import {
    SAVED_CALCULATION_LIST_LOAD_ALL,
    SAVED_CALCULATION_LIST_DELETE_ONE,
    SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS,
    SAVED_CALCULATION_LIST_DELETE_ONE_ERROR,
    SAVED_CALCULATION_LIST_SAVE_CHANGES,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE
} from './constants';
import {
    loadCalculationListSuccess,
    loadCalculationListError,
    deleteOneCalculationSuccess,
    deleteOneCalculationError,
    saveChangesSuccess,
    saveChangesError,
    GetUserAutocompleteTagsSuccess,
    GetUserAutocompleteTagsError
} from './actions';
import { makeSelectUserId } from 'selectors/userProfileSelectors';
import CalculationsApi from '../../api/calculationsApi';
import {enqueueSnackbar} from "../Notifier/actions";

export function* getCalculations(action) {
    try {
        const response = yield call(CalculationsApi.GetSavedCalculation, action.userId);
        yield put(loadCalculationListSuccess(response.data));
    } catch (error) {
        yield put(loadCalculationListError(error));
    }
}

export function* deleteOneCalculation(action) {
    try {
        yield call(CalculationsApi.deleteCalculationInput, action.id);
        yield put(deleteOneCalculationSuccess(action.id));
    } catch (error) {
        yield put(deleteOneCalculationError(error.message));
    }
}

export function* notifyDeleteOneSuccess() {
  yield put(enqueueSnackbar({ message: "Deleted!", options: { variant: "success" }}));
}

export function* notifyDeleteOneError() {
  yield put(enqueueSnackbar({ message: "An error occurred during delete calculation", options: { variant: "error" }}));
}

export function* saveChanges(action) {
    try {
        yield call(CalculationsApi.saveCalculationInputs, action.savedCalculationInputs);
        yield put(saveChangesSuccess(action.savedCalculationInputs));
    } catch (error) {
        yield put(saveChangesError(error.message));
    }
}

export function* notifySaveChangesSuccess() {
  yield put(enqueueSnackbar({ message: "Saved!", options: { variant: "success" }}));
}

export function* notifySaveChangesError() {
  yield put(enqueueSnackbar({ message: "An error when saving changes to calculation", options: { variant: "error" }}));
}

export function* userAutocompleteTags() {
    try {
        const userId = yield select(makeSelectUserId());
        const response = yield call(CalculationsApi.getUserAutocomplete, userId);
        yield put(GetUserAutocompleteTagsSuccess(response.data));
    } catch (error) {
        yield put(GetUserAutocompleteTagsError(error.message));
    }
}

export default function* savedCalculationsData() {
    yield all([
        userAutocompleteTags(),
        takeLatest(SAVED_CALCULATION_LIST_LOAD_ALL, getCalculations),
        takeLatest(SAVED_CALCULATION_LIST_DELETE_ONE, deleteOneCalculation),
        takeLatest(SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS, notifyDeleteOneSuccess),
        takeLatest(SAVED_CALCULATION_LIST_DELETE_ONE_ERROR, notifyDeleteOneError),
        takeLatest(SAVED_CALCULATION_LIST_SAVE_CHANGES, saveChanges),
        takeLatest(SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS, notifySaveChangesSuccess),
        takeLatest(SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR, notifySaveChangesError),
        takeLatest(CALCULATION_INPUTS_TAGS_AUTOCOMPLETE, userAutocompleteTags)
    ]);
}
