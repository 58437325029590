import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DimensionDataRow from "./DimensionDataRow";
import { useTranslation } from 'react-i18next';

function DimensionalData({data, sketches}) {
  const { t } = useTranslation();

  if (!data) {
    return <span>No data available</span>;
  }

  //console.log(data);
  let sketch = null;
  if (data.sketch) {
    let passes = data.calcResult.find(i => i.name === 'Passes').siValue;
    switch (data.sketch) {
      case "200_sketch":
      case "400_sketch":
      case "30L2_sketch":
      case "B30L0x50_1P-SC-H_sketch":
      case "B60_sketch":
      case "E_sketch":
      case "F10T_sketch":
      case "F80_sketch":
      case "M_sketch_Tpressure":
      case "U_sketch":
        if (data.xpc.execution === "D") {
          sketch = sketches[`${data.sketch}_${data.xpc.execution}.png`];
        } else {
          sketch = sketches[`${data.sketch}_${passes}P.png`];
        }
        break;
      case "B_sketch":
        if (data.xpc.execution === "D" || data.xpc.execution === "2S") {
          sketch = sketches[`${data.sketch}_${data.xpc.execution}.png`];
        } else if (data.xpc.execution === "SP") {
          sketch = sketches[`B_sketch_1P.png`];
        } else {
          sketch = sketches[`B_sketch_${passes}P.png`];
        }
        break;
      case "B35TM2_sketch_190":
        break;
      default:
        break;
    }
    if (!sketch) {
      sketch = sketches[`${data.sketch}.png`];
    }
  }

  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-auto">
          <img className="img-fluid m-3" src={sketch ? sketch : sketches['Empty_sketch.png']} alt="sketch"/>
        </div>
        <div className="col-auto">
          <table className="table table-condensed">
            <thead>
            <tr className="text-uppercase">
              <th/>
              <th>{t("SWEP.SSP.Calculation_Dimensions.DataUnit")}</th>
              <th>{t("SWEP.SSP.Calculation_Dimensions.DataValue")}</th>
              <th>{t("SWEP.SSP.Calculation_Dimensions.DataTolerance")}</th>
            </tr>
            </thead>
            <tbody>{data.dimensionInfo.map(item => {
              return <DimensionDataRow key={item.name} rowData={item}/>;
            })}</tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <i>* Dimensions depend on the selected product.</i>
          <br/>
          <i>* This is a schematic sketch. For correct drawings please use the order drawing function or contact your SWEP representative.</i>
        </div>
      </div>
    </div>
  );
}

DimensionalData.propTypes = {
    data: PropTypes.object,
    sketches: PropTypes.object
};

function mapStateToProps(state){
    return {
      sketches: state.loadItems.sketches,
    };
  }

export default connect(mapStateToProps)(DimensionalData);

