import { createSelector } from 'reselect';
import {initialState} from '../reducers/externalSourcesReducer';

const selectExternalSources = state => state.externalSources || initialState;

const makeSelectNews = () =>
  createSelector(
    selectExternalSources,
    externalSourcesState => externalSourcesState.news
  );

const makeSelectCaseStories = () =>
  createSelector(
    selectExternalSources,
    externalSourcesState => externalSourcesState.caseStories
  );

const makeSelectCarousel = () =>
  createSelector(
    selectExternalSources,
    state => state.carousel
  );

const makeSelectWikiMessages = () =>
  createSelector(
    selectExternalSources,
    state => state.wiki
  );

export {
  makeSelectNews,
  makeSelectCaseStories,
  makeSelectWikiMessages,
  makeSelectCarousel
};
