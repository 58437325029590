import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";

const UnitInput = ({
  units,
  value,
  onChange,
  className,
  readonly = false
}) => {
    const handleChange = useCallback((unit) => {
        if (onChange) {
            onChange(unit);
        }
    }, [onChange]);

    return (
      <div className={clsx("dropdown", className)}>
        <button
          className="btn btn-light dropdown-toggle quantity-inputs"
          tabIndex={-1}
          type="button" id="quantityInputUnitPart"
          data-bs-toggle="dropdown" aria-expanded="false"
          disabled={readonly}>
          {value}
        </button>
        <ul className="dropdown-menu" aria-labelledby="quantityInputUnitPart">
          {units.map((unit) => {
            return (
              <li key={unit}>
                <button className="dropdown-item" type="button" onClick={() => handleChange(unit)}>{unit}</button>
              </li>
            );
          })}
        </ul>
      </div>
    );
};

UnitInput.propTypes = {
    units: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    readonly: PropTypes.bool
};

export default React.memo(UnitInput);
