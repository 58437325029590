import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import  {
  Wrapper as Wrap,
  UnitSystemAwareValue1 as Usav,
  UnitSystemAwareUnit as Usau
} from "./TechnicalData/createDisplayObject";


const TotalsDataRow = ({dataRow}) => {
    const { t } = useTranslation("printouts");

    if(!dataRow){
      return null;
    }
    return (
        <tr>
            <td>{t(dataRow.name)}</td>
            <td className="tdr-unit"><Usau sspValue={{ unitGroup: dataRow.unitType }} /></td>
            {dataRow.minValue !== dataRow.maxValue
              ? <td className="tdr-value"><Wrap><Usav unitGroup={dataRow.unitType} value={dataRow.minValue} unit={dataRow.unit} /> - <Usav unitGroup={dataRow.unitType} value={dataRow.maxValue} unit={dataRow.unit} /></Wrap></td>
              : <td className="tdr-value">{<Usav unitGroup={dataRow.unitType} value={Math.max(dataRow.minValue, dataRow.maxValue)} unit={dataRow.unit} />}</td>
          }
        </tr>
    );
};

TotalsDataRow.propTypes = {
    dataRow: PropTypes.object.isRequired
};

export default TotalsDataRow;
