import React from "react";
import PropTypes from 'prop-types';
import {format} from "./TechnicalData/useUnitConversion";
import {styled} from 'styled-components';

const ToolTipTable = styled.table`
  --bs-table-bg: transparent;
  --bs-table-color: #FFF;
`;

const AllUnitsTooltipContent = ({ quantities }) => {


  return (
    <ToolTipTable className="table table-sm text-light" >
      <thead className="">
        <tr>
          <th scope="col">Unit</th>
          <th scope="col">Amount</th>
        </tr>
      </thead>
      <tbody>
        {quantities.map(q =>
          (<tr key={q.unit}>
            <th scope="row">{q.unit}</th>
            <td>{format(q.unitGroup, q.amount)}</td>
          </tr>)
        )}
      </tbody>
    </ToolTipTable>
  );
};

AllUnitsTooltipContent.propTypes = {
  quantities: PropTypes.array.isRequired
};

export default AllUnitsTooltipContent;
