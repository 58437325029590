import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';
import OpenInNewTabButton from "components/common/OpenInNewTabButton";
import './wikidialog.scss';
import LoadingPanel from 'components/LoadingPanel';
import {getWikiPage} from "actions/externalSourcesActions";
import {makeSelectWikiMessages} from "selectors/externalSourcesSelectors";
import {createStructuredSelector} from "reselect";
import {WIKI_BASE} from "../../constants/links";

const WikiDialog = ({anchorElement, setAnchorElement, wikiMessage, getWikiPage, wikiMessages}) => {
  const [isLoading, setIsLoading] = useState(true);

  const dMessage = wikiMessage ? wikiMessages[wikiMessage.messageId] : null;

  const handleClose = () => setAnchorElement(null);

  useEffect(() => {
    if(wikiMessage && wikiMessages) {
      if (!wikiMessages[wikiMessage.messageId]) {
        setIsLoading(true);
        getWikiPage(wikiMessage.pageId);
      } else {
        setIsLoading(false);
      }
    }
  },[wikiMessage, wikiMessages, getWikiPage]);

  const onEnter = () => {
    if(wikiMessage && wikiMessages && !dMessage && isLoading) {
      setIsLoading(false);
    }
    if(!wikiMessage){
      setIsLoading(false);
    }
  };

  const open = Boolean(anchorElement);
  const id = open ? 'wiki-popover' : undefined;
  const messageSrc = wikiMessage ? `${WIKI_BASE}/${wikiMessage.page}#${wikiMessage.messageId}`: '';

  if(!anchorElement) {
    return null;
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionProps={{
        onEnter
      }}>
      <div className="wiki-dialog">
        <p className="wiki-header">DThermx Wiki<OpenInNewTabButton buttonClass="btn-newtab" size="small" path={messageSrc} /></p>
        <hr/>
        <div>
          <div className="wiki-iframe-wrapper">
            <LoadingPanel isLoading={isLoading} >
              {dMessage && (<>
                  <h6 className="message-header">{dMessage.title}</h6>
                  {dMessage.texts.map(d => <p key={d}>{d}</p>)}
                </>
              ) || <h6 className="message-header">Wiki message not found</h6>
              }
            </LoadingPanel>
          </div>
        </div>
      </div>
    </Popover>
  );
};

WikiDialog.propTypes = {
  anchorElement: PropTypes.object,
  setAnchorElement: PropTypes.func,
  getWikiPage: PropTypes.func,
  wikiMessage: PropTypes.object,
  wikiMessages: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    wikiMessages: makeSelectWikiMessages()
});

const mapDispatchToProps = (dispatch) => {
  return {
    getWikiPage: (pageId) => dispatch(getWikiPage(pageId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WikiDialog);
