import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import useUserProfile from '../../../components/common/hooks/useUserProfile';
import {ALLOW_CALCULATE_INVALID_INPUTS} from '../../../constants/LicenseFeatures.ts';

const CalculateButton = ({ disabled, onCalculate }) => {
  const { t } = useTranslation();
  const { features } = useUserProfile();

  const allowCalculateInvalidInputs = useMemo(() => {
    return features.includes(ALLOW_CALCULATE_INVALID_INPUTS);
  }, [features]);

  const x = (allowCalculateInvalidInputs ? false : !disabled);

  return (
    <div>
      <Button tabIndex={100} color="primary" variant="contained" size="small" fullWidth={true} disabled={x} onClick={onCalculate}>{t("CalcForm_Calculate")}</Button>
      {allowCalculateInvalidInputs && <Typography variant="subtitle2" paragraph={true}>Calculation allowed with invalid inputs!</Typography>}
    </div>
  );
};

CalculateButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onCalculate: PropTypes.func.isRequired,
};

export default CalculateButton;
