import { all, put, call, takeLatest } from 'redux-saga/effects';
import externalSourcesApi from 'api/externalSourceApi';
import {
  LATEST_NEWS_LOAD,
  CASE_STORIES_LOAD,
  CASE_STORIES_LOAD_SUCESS,
  CAROUSEL_ITEMS_LOAD,
  WIKI_GET_PAGE,
  WIKI_SAVE_MESSAGES
} from 'constants/actionTypes';
import {
  loadNewsSuccess,
  loadNewsError,
  processWikiRsponse
} from 'actions/externalSourcesActions';
import {enqueueSnackbar} from "../containers/Notifier/actions";
import {loadCarouselItemsError, loadCarouselItemsSuccess} from "../actions/externalSourcesActions";

export function* loadLatestNews() {
  try {
    const response = yield call(externalSourcesApi.getLatestNews);
    yield put(loadNewsSuccess(response.data));
  } catch (e) {
    yield put(loadNewsError(e));
  }
}

export function* loadCaseStories() {
  try {
    const response = yield call(externalSourcesApi.getCaseStories);
    yield put({
      type: CASE_STORIES_LOAD_SUCESS,
      stories: response.data
    });
  } catch (e) {
    yield put(enqueueSnackbar({ message: "Error loading case stories", options: { variant: "error" }}));
  }
}

export function* loadCarouselItems() {
  try {
    const response = yield call(externalSourcesApi.getCarouselItems);
    yield put(loadCarouselItemsSuccess(response.data));
  } catch (e) {
    yield put(loadCarouselItemsError(e.message));
  }
}

export function* loadWikiPage(action) {
  try {
    const response = yield call(externalSourcesApi.getWikiPage, action.pageId);
    yield put({
      type: WIKI_SAVE_MESSAGES,
      value: processWikiRsponse(response.data)
    });
  } catch {
    yield put(enqueueSnackbar({ message: "Error loading wiki page. Wiki page not found or not accessible", options: { variant: "error" }}));
  }
}

export default function* externalSourcesSaga() {
  yield all([
    takeLatest(LATEST_NEWS_LOAD, loadLatestNews),
    takeLatest(CASE_STORIES_LOAD, loadCaseStories),
    takeLatest(CAROUSEL_ITEMS_LOAD, loadCarouselItems),
    takeLatest(WIKI_GET_PAGE, loadWikiPage),
  ]);
}
