import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FlowTypeInput = ({ coCurrent, onChange, tabIndex, disabled }) => {
  const { t } = useTranslation();

  let value = coCurrent ? 1 : 0;
  return (
    <select
      className="form-control"
      name={'coCurrent'}
      value={value}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value === '1')}
      tabIndex={tabIndex}>
        <option value="0">{t("CalcForm_CounterCurrent")}</option>
        <option value="1">{t("CalcForm_CoCurrent")}</option>
    </select>);
};

FlowTypeInput.propTypes = {
    coCurrent: PropTypes.bool,
    onChange: PropTypes.func,
    tabIndex: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    disabled: PropTypes.bool,
};

export default React.memo(FlowTypeInput);
