import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import FilterInputsBase from '../../components/calculation/inputForms/FilterInputsBase';

const CalculationFilters = ({ calculationType, selectedFilters, onSelectedFiltersChange }) => {

  const handleFiltersChange = useCallback((calculationType, filterName, selection) => {
    onSelectedFiltersChange(filterName, selection);
  }, [onSelectedFiltersChange]);


  return (
    <FilterInputsBase
      calculationType={calculationType.toLowerCase()}
      filterSettings={selectedFilters}
      changeFilters={handleFiltersChange}/>
  );
};

CalculationFilters.propTypes = {
  calculationType: PropTypes.string.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  onSelectedFiltersChange: PropTypes.func.isRequired,
};

export default React.memo(CalculationFilters);
