/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSavedCalculationList = state => state.savedCalculationList || initialState;

const makeSelectSavedCalculationList = () =>
    createSelector(
        selectSavedCalculationList,
        selectSavedCalculationListState => selectSavedCalculationListState.calculations
    );

const makeSelectLoading = () => 
    createSelector(
        selectSavedCalculationList,
        selectSavedCalculationListState => selectSavedCalculationListState.loading
    );

const makeSelectAutocompleteTags = () => 
    createSelector(
        selectSavedCalculationList,
        selectSavedCalculationListState => selectSavedCalculationListState.autocompleteTags
    );

export { makeSelectSavedCalculationList, makeSelectLoading, makeSelectAutocompleteTags };