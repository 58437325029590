import {
    SAVED_CALCULATION_LIST_LOAD_ALL,
    SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS,
    SAVED_CALCULATION_LIST_LOAD_ALL_ERROR,
    SAVED_CALCULATION_LIST_DELETE_ONE,
    SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS,
    SAVED_CALCULATION_LIST_DELETE_ONE_ERROR,
    SAVED_CALCULATION_LIST_SAVE_CHANGES,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS,
    SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS,
    CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR
} from './constants';

export function loadCalculationList(userId) {
    return {
        type: SAVED_CALCULATION_LIST_LOAD_ALL,
        userId: userId
    };
}

export function loadCalculationListSuccess(calculations) {
    return {
        type: SAVED_CALCULATION_LIST_LOAD_ALL_SUCCESS,
        calculations: calculations
    };
}

export function loadCalculationListError(error) {
    return {
        type: SAVED_CALCULATION_LIST_LOAD_ALL_ERROR,
        error: error
    };
}

export function deleteOneCalculation(id) {
    return {
        type: SAVED_CALCULATION_LIST_DELETE_ONE,
        id: id
    };
}

export function deleteOneCalculationSuccess(id) {
    return {
        type: SAVED_CALCULATION_LIST_DELETE_ONE_SUCCESS,
        id: id
    };
}

export function deleteOneCalculationError(error) {
    return {
        type: SAVED_CALCULATION_LIST_DELETE_ONE_ERROR,
        error: error
    };
}

export function saveChanges(savedCalculationInputs) {
    return {
        type: SAVED_CALCULATION_LIST_SAVE_CHANGES,
        savedCalculationInputs: savedCalculationInputs
    };
}

export function saveChangesSuccess(savedCalculationInputs) {
    return {
        type: SAVED_CALCULATION_LIST_SAVE_CHANGES_SUCCESS,
        savedCalculationInputs: savedCalculationInputs
    };
}

export function saveChangesError(error) {
    return {
        type: SAVED_CALCULATION_LIST_SAVE_CHANGES_ERROR,
        error: error
    };
}

export function GetUserAutocompleteTags(userId) {
    return {
        type: CALCULATION_INPUTS_TAGS_AUTOCOMPLETE,
        userId: userId
    };
}

export function GetUserAutocompleteTagsSuccess(tags) {
    return {
        type: CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_SUCCESS,
        tags: tags
    };
}

export function GetUserAutocompleteTagsError(error) {
    return {
        type: CALCULATION_INPUTS_TAGS_AUTOCOMPLETE_ERROR,
        error: error
    };
}