import PropTypes from 'prop-types';

export default PropTypes.shape({
  fluidId: PropTypes.number.isRequired,
  inputValue: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired
  }),
  isGas: PropTypes.bool
});
