import React from "react";
import PropTypes from 'prop-types';
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';

const ValidationTooltip = ({
  messages,
  children
}) => {
  const { t } = useTranslation("printouts");
  let title = '';
  if (messages && messages.length > 0) {
    title = messages.map(m => m.composeString(t)).join(`\n\r`);
    title = <span style={{whiteSpace: "pre-line"}}>{title}</span>;
  }
  return (
    <Tooltip title={title} placement="top">
      {children}
    </Tooltip>
  );
};

ValidationTooltip.propTypes = {
  messages: PropTypes.array,
  children: PropTypes.node.isRequired
};

export default ValidationTooltip;
