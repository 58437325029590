import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

const OpenInNewTabButton = ({ tabIndex, path, size  }) => {

  return (
  <IconButton aria-describedby={"filters-actions"} size={size} tabIndex={tabIndex} title={"Open in new tab"} onClick={()=> {
    window.open(path, "_blank");
  }}>
    <LaunchIcon/>
  </IconButton>
  );
};

OpenInNewTabButton.propTypes = {
  tabIndex: PropTypes.number,
  path: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"])
};

export default OpenInNewTabButton;
