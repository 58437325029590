import axios from 'axios';

import {
    UNIT_CONVERTOR_CONVERT_FLOW_UNITS
}  from '../constants/links';
import {dthermXBaseAddress} from './baseAddresses';

class UnitsConvertorApi {
    static convertFlowUnits(flow, unitFrom, unitTo, fluidId, inputValue, isGas, refTemp) {
        return axios({
            method: 'POST',
            url: `${dthermXBaseAddress()}${UNIT_CONVERTOR_CONVERT_FLOW_UNITS}`,
            data: {
                flow: flow,
                unitFrom: unitFrom,
                unitTo: unitTo,
                fluidId: fluidId,
                inputValue: inputValue,
                isGas: isGas,
                refTemp: refTemp
            }
        });
    }
}

export default UnitsConvertorApi;
