import {createSelector} from 'reselect';
import get from 'lodash/get';

const selectUserProfile = state => state.userProfile;

const makeSelectUserProfile = () => selectUserProfile;

const makeSelectUserName = () =>
  createSelector(
    selectUserProfile,
    userProfileState => {
      return {
        firstname: userProfileState.firstName,
        lastname: userProfileState.lastName
      };
    }
  );

const makeSelectUserId = () =>
  createSelector(
    selectUserProfile,
    userProfileState => get(userProfileState, 'userId', null)
  );

const makeSelectIsTelemetryEnabled = () =>
  createSelector(
    selectUserProfile,
    userProfileState => get(userProfileState, 'isTelemetryEnabled', null)
  );

const makeSelectIsExportToCRMEnabled = () =>
  createSelector(
    selectUserProfile,
    userProfileState => get(userProfileState, 'isExportToCMREnabled', null)
  );

const makeSelectUserAllowedCalculations = () =>
  createSelector(
    selectUserProfile,
    userProfileState => userProfileState.allowedCalculationTypes
  );

const makeSelectAllowedPrintoutFormats = () =>
  createSelector(
    selectUserProfile,
    state => state.allowedPrintoutFormats
  );

const makeSelectTenderPrintoutEnabled = () =>
  createSelector(
    selectUserProfile,
    userProfileState => get(userProfileState, 'isTenderPrintoutEnabled', null)
  );

const makeSelectUserProfileFeatures = () =>
  createSelector(selectUserProfile, userProfileState => get(userProfileState, 'features', []));

const makeSelectUserProfilePackages = () =>
  createSelector(selectUserProfile, userProfileState => get(userProfileState, 'packages', []));

const makeSelectFilters = (calculationType) =>
  createSelector(
    selectUserProfile,
    userProfileState => {
      const calculationTypeFilterSetting = get(userProfileState, 'settings.filterSettings.calculationTypeFilterSettings', []).find(x => x.calculationType === calculationType);
      if (calculationTypeFilterSetting) return calculationTypeFilterSetting.calculationFilterSettingsData;

      const defaultCalculationFilterSettings = get(userProfileState, 'settings.filterSettings.defaultCalculationFilterSettings', null);
      if (defaultCalculationFilterSettings) return defaultCalculationFilterSettings;

      return null;
    }
  );


export {
  makeSelectUserProfile,
  makeSelectUserId,
  makeSelectIsExportToCRMEnabled,
  makeSelectIsTelemetryEnabled,
  makeSelectUserAllowedCalculations,
  makeSelectAllowedPrintoutFormats,
  makeSelectTenderPrintoutEnabled,
  makeSelectFilters,
  makeSelectUserName,
  makeSelectUserProfileFeatures,
  makeSelectUserProfilePackages
};
