import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import {Button, Fade, Paper, Popper} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useTranslation} from "react-i18next";
import PressureQuantityInput from "../../common/QuantityInputs/PressureQuantityInput";
import {UnitGroups, UnitGroupsNames, Pressure } from "../../../utils/unitGroups.ts";
import get from 'lodash/get';
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import VaporPropertiesPropTypes from "../PropTypes/VaporPropertiesPropTypes";
import LiquidPropertiesPropTypes from "../PropTypes/LiquidPropertiesPropTypes";
// import useConstant from "../../common/hooks/useConstant";
import RangeInfo from "./RangeInfo";

const LiquidVaporPopover = ({
  isOpen,
  userSelection,
  onSubmit,
  // onAbort,
                              // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  liquidProperties,
  vaporProperties,
  anchorEl
}) => {
  const [isGas, setIsGas] = useState(false);
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(userSelection.inputValue);
  }, [userSelection.inputValue]);
  useEffect(() => {
    setIsGas(userSelection.isGas);
  }, [userSelection.isGas]);
  const {t} = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({...userSelection, inputValue: value, isGas: isGas });
  };

  const hasValueError = useCallback((quantity) => {
    if (isGas === true) {
      const value = get(quantity, 'amount', null);
      if (value === "") return true;
      if (value == null) return true;

      const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Pressure);
      const unit = unitGroup.getUnit(quantity.unit);
      const siValue = unit.convertToSI(quantity.amount);

      return !((vaporProperties.pVapMin <= Number(siValue)) && (Number(siValue) <= vaporProperties.pVapMax));
    } else {
      return false;
    }
  }, [isGas, vaporProperties.pVapMin, vaporProperties.pVapMax]);

  const handleLiquidClick = () => {
    setIsGas(false);
    setValue(null);
  };

  const handleGasClick = () => {
    setIsGas(true);
  };

  const quantityInput = {
    quantity: value,
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const minQuantityInput = {
    quantity: {
      amount: vaporProperties.pVapMin,
      unit: Pressure.Pa
    },
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const maxQuantityInput = {
    quantity: {
      amount: vaporProperties.pVapMax,
      unit: Pressure.Pa
    },
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const handleQuantityInputChange = (quantityInput) => {
    setValue(quantityInput.quantity);
  };

  return (
    <Popper container={document.getElementById("additional-info")} open={isOpen} anchorEl={anchorEl} transition placement="right-start" style={{zIndex:1500}}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <Paper sx={{ p: 1 }}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <div className="p-1">
                  <Typography variant="button" color="primary" display="block">{t("Set")} {t("Phase")}</Typography>
                </div>
                <div className="p-1">
                  <ButtonGroup variant="contained" color="primary">
                    <Button color={isGas === false ? "primary" : "inherit"} size="small" onClick={handleLiquidClick}>Liquid</Button>
                    <Button color={isGas === true ? "primary" : "inherit"} size="small" onClick={handleGasClick}>Gas</Button>
                  </ButtonGroup>
                </div>
                <div className="p-1">
                  <PressureQuantityInput
                    tabIndex={0}
                    readonly={!isGas}
                    // amountComponentProps={useConstant({ autoFocus: true})}
                    quantityInput={quantityInput}
                    onChange={handleQuantityInputChange}
                    isValid={!hasValueError(value)}
                    messages={[]} />
                </div>
                <div className="p-1">
                  <RangeInfo minQuantityInput={minQuantityInput} maxQuantityInput={maxQuantityInput} />
                </div>
                <div className="p-1 d-flex justify-content-end">
                  <Button tabIndex={0} type="submit" color="primary" variant={"contained"} size="small" disabled={hasValueError(value)}>OK</Button>
                </div>
              </div>
              </form>
          </Paper>
      </Fade>
      )}
    </Popper>
  );
};

LiquidVaporPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userSelection: UserSelectionPropTypes,
  onSubmit: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  liquidProperties: LiquidPropertiesPropTypes.isRequired,
  vaporProperties: VaporPropertiesPropTypes.isRequired,
  anchorEl: PropTypes.object
};

export default LiquidVaporPopover;
