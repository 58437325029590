import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { produce } from 'immer';
import LiquidVaporPopover from "./LiquidVaporPopover";
import PressureQuantityInput from "../../common/QuantityInputs/PressureQuantityInput";
import {UnitGroups, UnitGroupsNames, Pressure } from "../../../utils/unitGroups.ts";
import get from 'lodash/get';
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import VaporPropertiesPropTypes from "../PropTypes/VaporPropertiesPropTypes";
import LiquidPropertiesPropTypes from "../PropTypes/LiquidPropertiesPropTypes";

const buttonStyle = {
  height: "25px",
  fontSize: "14px",
  padding: "1px 6px"
};

const LiquidVaporInfoSection = ({
  liquidProperties,
  vaporProperties,
  userSelection,
  onUserSelectionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSubmit = (value) => {
    setIsOpen(false);
    onUserSelectionChange({...value});
  };

  const handleAbort = () => {
    setIsOpen(false);
  };

  const hasValueError = useCallback((userSelection) => {
    if (userSelection.isGas === true) {
      const value = get(userSelection, 'inputValue.amount', null);
      if (value === "") return true;
      if (value == null) return true;

      const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Pressure);
      const unit = unitGroup.getUnit(userSelection.inputValue.unit);
      const siValue = unit.convertToSI(userSelection.inputValue.amount);

      return !((vaporProperties.pVapMin <= Number(siValue)) && (Number(siValue) <= vaporProperties.pVapMax));
    } else {
      return false;
    }
  }, [vaporProperties.pVapMax, vaporProperties.pVapMin]);

  const quantityInput = {
    quantity: userSelection.inputValue,
    unitGroup: UnitGroupsNames.Pressure,
    defaultMetricUnit: Pressure.bar,
    defaultUsUnit: Pressure.psi,
    defaultUserSelectedUnit: get(userSelection, 'inputValue.unit', null)
  };

  const handleQuantityInputChange = (quantityInput) => {
    onUserSelectionChange(produce(userSelection, draft => {
      draft.inputValue = quantityInput.quantity;
    }));
  };

  return (
    <div ref={anchorEl} className="fs-add-info">
      <div style={{width: '70%'}}>
        <div className={clsx('input-group input-group-sm')}>
          <div className={clsx({ 'd-none': userSelection.isGas === false })}>
            <PressureQuantityInput
              quantityInput={quantityInput}
              onChange={handleQuantityInputChange}
              isValid={!hasValueError(userSelection)}
              messages={[]} />
          </div>

        </div>
      </div>
      <div style={{width: '30%'}}>
        <button
          className="btn btn-light quantity-inputs"
          style={buttonStyle}
          onClick={handleClick}
          type="button">
          {userSelection.isGas === true ? 'gas': 'liquid'}</button>
      </div>
      {isOpen && <LiquidVaporPopover
        anchorEl={anchorEl.current}
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        isOpen={isOpen}
        userSelection={userSelection}
        liquidProperties={liquidProperties}
        vaporProperties={vaporProperties} />}
    </div>
  );
};


LiquidVaporInfoSection.propTypes = {
  liquidProperties: LiquidPropertiesPropTypes.isRequired,
  vaporProperties: VaporPropertiesPropTypes.isRequired,
  userSelection: UserSelectionPropTypes,
  onUserSelectionChange: PropTypes.func.isRequired,
};

export default React.memo(LiquidVaporInfoSection);
