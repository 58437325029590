import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import {Button, Fade, Paper, Popper} from "@mui/material";
import {useTranslation} from "react-i18next";
import PercentageQuantityInput from "../../common/QuantityInputs/PercentageQuantityInput";
import get from 'lodash/get';
import UserSelectionPropTypes from "../PropTypes/UserSelectionPropTypes";
import ConcentrationPropertiesPropTypes from "../PropTypes/ConcentrationPropertiesPropTypes";
import {UnitGroups, UnitGroupsNames, Percentage } from "../../../utils/unitGroups.ts";
import RangeInfo from "./RangeInfo";

const ConcentrationPopover = ({
  isOpen,
  userSelection,
  onSubmit,
  // onAbort,
  concentrationProperties,
  anchorEl
}) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(userSelection.inputValue);
  }, [userSelection.inputValue]);
  const {t} = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({...userSelection, inputValue: value});
  };

  const hasValueError = useCallback((quantity) => {
    const value = get(quantity, 'amount', null);
    if (value === "") return true;
    if (value == null) return true;

    const unitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Percentage);
    const unit = unitGroup.getUnit(quantity.unit);
    const siValue = unit.convertToSI(quantity.amount);

    return !((concentrationProperties.xMin <= Number(siValue)) && (Number(siValue) <= concentrationProperties.xMax));
  }, [concentrationProperties.xMax, concentrationProperties.xMin]);

  const quantityInput = {
    quantity: value,
    unitGroup: UnitGroupsNames.Percentage,
    defaultMetricUnit: Percentage.percent,
    defaultUsUnit: Percentage.percent
  };

  const minQuantityInput = {
    quantity: {
      amount: concentrationProperties.xMin,
      unit: Percentage.percent
    },
    unitGroup: UnitGroupsNames.Percentage,
    defaultMetricUnit: Percentage.percent,
    defaultUsUnit: Percentage.percent
  };

  const maxQuantityInput = {
    quantity: {
      amount: concentrationProperties.xMax,
      unit: Percentage.percent
    },
    unitGroup: UnitGroupsNames.Percentage,
    defaultMetricUnit: Percentage.percent,
    defaultUsUnit: Percentage.percent
  };

  const handleQuantityInputChange = (quantityInput) => {
    setValue(quantityInput.quantity);
  };

  return (
    <Popper container={document.getElementById("additional-info")} open={isOpen} anchorEl={anchorEl} transition placement="right-start" style={{zIndex:1500}}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <Paper sx={{ p: 1 }}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <div className="p-1">
                  <Typography variant="button" color="primary" display="block">{t("Set")} {concentrationProperties.concentrationType} {t("Concentration")}</Typography>
                </div>
                <div className="p-1">
                  <PercentageQuantityInput
                    quantityInput={quantityInput}
                    tabIndex={0}
                    // amountComponentProps={useConstant({ autoFocus: true})}
                    onChange={handleQuantityInputChange}
                    isValid={!hasValueError(value)}
                    messages={[]} />
                </div>
                <div className="p-1">
                  <RangeInfo minQuantityInput={minQuantityInput} maxQuantityInput={maxQuantityInput} />
                </div>
                <div className="p-1 d-flex justify-content-end">
                  <Button tabIndex={0} type="submit" color="primary" variant={"contained"} size="small" disabled={hasValueError(value)}>OK</Button>
                </div>
              </div>
            </form>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

ConcentrationPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userSelection: UserSelectionPropTypes,
  onSubmit: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  concentrationProperties: ConcentrationPropertiesPropTypes.isRequired,
  anchorEl: PropTypes.object
};

export default React.memo(ConcentrationPopover);
