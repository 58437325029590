import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AutocompleteTagInput from "../../AutocompleteTagInput/AutocompleteTagInput";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

const SaveCalculationInputsDialog = ({ initialData, autocompleteTags, isOpen, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const [customCalculationName, setCustomCalculationName] = useState("");
    const [note, setNote] = useState("");
    const [tags, setTags] = useState([]);

    useEffect(() => {
        setCustomCalculationName(initialData ? initialData.customName : "");
        setNote(initialData ? initialData.note : "");
        setTags(initialData ? initialData.tags : []);
    }, [initialData]);

    const handleCustomCalculationNameOnChange = (e) => {
        setCustomCalculationName(e.target.value);
    };
    const handleNoteOnChange = (e) => {
        setNote(e.target.value);
    };
    const handleTagsChange = (selectedTags) => {
        setTags(selectedTags);
    };

    const handleSubmit = () => onSubmit({
         customName: customCalculationName,
         note: note,
         tags: tags
    });

    return (
        <Dialog open={isOpen} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"} >
            <DialogTitle id="simple-dialog-title">{t('Calculation.SaveCalculation.Header.Title', 'Save calculation')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  {t('Calculation.SaveCalculation.Content.Text', 'Please enter custom calculation name')}
                </DialogContentText>
                <Box sx={{ mt: 1 }}>
                  <Grid
                    container item
                    direction="column"
                    alignItems="stretch"
                    justifyContent="space-between"
                    spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label={t('Calculation.SaveCalculation.Content.Name.Label.Text', 'Name')}
                        autoFocus={true}
                        fullWidth
                        value={customCalculationName}
                        error={!(customCalculationName && customCalculationName.length > 0)}
                        onChange={handleCustomCalculationNameOnChange} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label={t('Calculation.SaveCalculation.Content.Comment.Label.Text', 'Comment')}
                        fullWidth
                        maxRows="7"
                        value={note}
                        onChange={handleNoteOnChange}
                        multiline />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteTagInput
                        options={autocompleteTags}
                        values={tags}
                        onChange={handleTagsChange} />
                    </Grid>
                  </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={!(customCalculationName && customCalculationName.length > 0)} color="primary">{t('Calculation.SaveCalculation.Footer.SubmitButton.text', 'Submit')}</Button>
                <Button onClick={onCancel} color="secondary">{t('Calculation.SaveCalculation.Footer.CancelButton.text', 'Cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

SaveCalculationInputsDialog.propTypes = {
    initialData: PropTypes.shape({
        customName: PropTypes.string.isRequired,
        note: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string)
    }),
    autocompleteTags: PropTypes.arrayOf(PropTypes.string),
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default SaveCalculationInputsDialog;
