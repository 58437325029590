import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ErrorPanel = ({ children, error }) => {
  if (error) {
   return (
       <Accordion>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header">
           <div className="w-100">
             <Alert severity="error">
               <Typography
                 sx={{
                   fontSize: theme => theme.typography.pxToRem(15),
                   fontWeight: theme => theme.typography.fontWeightRegular
                 }}>Something went wrong!</Typography>
             </Alert>
           </div>
         </AccordionSummary>
         <AccordionDetails>
           <Typography>
             {error}lists
           </Typography>
         </AccordionDetails>
       </Accordion>
   );
  } else {
    return children;
  }
};

ErrorPanel.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
};

export default ErrorPanel;
