import React from 'react';
import PropTypes from 'prop-types';
import QuantityInput from './QuantityInput';
import UnitsConvertorApi from '../../../api/unitsConversionApi';
import {UnitGroups, UnitGroupsNames} from "../../../utils/unitGroups.ts";
import mean from 'lodash/mean';

const getRefTempInCelsius = (fluidTemperatures) => {
  const tempToBeAveraged = [];
  const temperaturesUnitGroup = UnitGroups.getUnitGroup(UnitGroupsNames.Temperatures);
  fluidTemperatures.forEach(temperatureQuantityInput => {
    if (temperatureQuantityInput.quantity) {
      const unit = temperaturesUnitGroup.getUnit(temperatureQuantityInput.quantity.unit);
      tempToBeAveraged.push(unit.convertToSI(temperatureQuantityInput.quantity.amount));
    }
  });

  return mean(tempToBeAveraged) || null;
};

const FlowQuantityInput = ({quantityInput, isValid, fluid, fluidTemperatures, onChange, ...others}) => {
    const handleConversion = (amount, sourceUnit, targetUnit) => {
        const refTempInCelsius = getRefTempInCelsius(fluidTemperatures) || 20;
        const convertFlow = async (amount, sourceUnit, targetUnit) => {
            const response = await UnitsConvertorApi.convertFlowUnits(amount, sourceUnit, targetUnit, fluid.fluidId, fluid.inputValue, fluid.isGas, refTempInCelsius);
            return response.data;
        };
        const convert = convertFlow(amount, sourceUnit, targetUnit);
        return Promise.resolve(convert);
    };

    return (
        <QuantityInput
            quantityInput={quantityInput}
            isValid={isValid}
            onChange={onChange}
            conversionFunction={handleConversion}
            {...others}/>
    );
};

FlowQuantityInput.propTypes = {
  quantityInput: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fluid: PropTypes.object.isRequired,
  fluidTemperatures: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  readonly: PropTypes.bool,
  amountComponentProps: PropTypes.object,
  unitComponentProps: PropTypes.object
};

export default React.memo(FlowQuantityInput);
