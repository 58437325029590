//user profile
export const USERPROFILE_GET = '/userProfile/getUserProfile/';
export const USERPROFILE_SAVE = '/userProfile/saveUserProfile/';
export const USERPROFILE_SETTINGS_SAVE = '/userProfile/saveUserSettings/';
export const USERPROFILE_CHANGE_PWD = '/profile/changepassword';
export const USERPROFILE_FILTER_SAVE = '/userProfile/filtersSave';
export const USERPROFILE_FILTER_RESET = '/userProfile/filtersReset';
export const USERPROFILE_DEFAULT_CALCULATION_INPUTS_SAVE = '/userProfile/saveDefaultCalculationInputs';
export const USERPROFILE_DEFAULT_CALCULATION_TYPE_CALCULATION_INPUTS_DELETE = '/userProfile/deleteCalculationTypeDefaultCalculationInputs';
export const USERPROFILE_DEFAULT_ALL_CALCULATION_INPUTS_DELETE = '/userProfile/deleteAllDefaultCalculationInputs';

//product basket
export const PRODUCT_BASKET_ADD_ITEM = '/productbasket/additem';
export const PRODUCT_BASKET_LOAD_ITEMS = '/productbasket/getitems';
export const PRODUCT_BASKET_GET_ITEM = '/productbasket/getitem';
export const PRODUCT_BASKET_UPDATE_QUANTITY = '/productbasket/updateitemquantity';
export const PRODUCT_BASKET_ITEM_UPDATE = '/productbasket/updateitem';
export const PRODUCT_BASKET_ITEM_DELETE = '/productbasket/deleteitem';

// units covnertor
export const UNIT_CONVERTOR_CONVERT_FLOW_UNITS = '/unitsConvertor/convertFlowUnits';

//auth
export const AUTH_LOGIN = '/login';
export const AUTH_FORGOT = '/forgot';
export const AUTH_REGISTER = '/register';
export const AUTH_VERIFY = '/verify';

//constants
export const CONSTANTS_COUNTRIES = '/constants/countries';

//connections
export const CONNECTIONS_CALCULATE = '/connections/connectionimpact/calculate';
export const CONNECTIONS_CALCULATE_FOR_ARTICLE = '/connections/connectionimpact/calculateforarticle';
export const CONNECTIONS_GETPORTINFO = '/connections/portinfo?artNo={0}&configNumber={1}';

//calculations
export const CALCULATIONS_CALCULATE = '/calculations/calculate';
export const CALCULATIONS_CALCULATE_EASYSELECT = '/calculations/es-calculate';
export const CALCULATIONS_INPUTS = '/calculations/calculationInputs';
export const CALCULATIONS_SAVED_INPUTS = '/calculations/getSavedCalculationInputs';
export const CALCULATIONS_INPUTS_TAGS_AUTOCOMPLETE = '/calculations/autocompleteTagsCalculationInputs';
export const CALCULATIONS_SAVED = '/calculations/savedCalculationInputs';
export const CALCULATIONS_SAVE = '/calculations/saveCalculationInputs';
export const CALCULATIONS_DELETE = '/calculations/deleteCalculationInput';
export const CALCULATION_OUTPUT_COLUMN_SETTINGS = '/calculations/calculationColumnSettings';

// other calculations
export const OTHER_CALCULATIONS_INPUTS_LOAD = '/otherCalculations/loadInputs';
export const OTHER_CALCULATIONS_ASYMMETRIC_CHANNELS_LIST = '/otherCalculations/asymmetricChannelsList';
export const OTHER_CALCULATIONS_CALCULATE = '/otherCalculations/calculate';
export const OTHER_CALCULATIONS_PRINT = '/otherCalculations/print';

// cip tool
export const CIP_TOOL_INPUTS_LOAD = '/cipTool/loadInputs';
export const CIP_TOOL_ASYMMETRIC_CHANNELS_LIST = '/cipTool/asymmetricChannelsList';
export const CIP_TOOL_CALCULATIONS_CALCULATE = '/cipTool/calculate';
export const CIP_TOOL_PRINT = '/cipTool/print';

//heatexchangers
export const HEATEXCHANGERS_PRODUCTNUMBER = '/heatexchangers/productnumber';
export const HEATEXCHANGERS_AVAILABLEXPCS = '/heatexchangers/availablexpcs';
export const HEATEXCHANGERS_LOAD = '/heatexchangers';

//fluids
export const FLUIDS = '/fluids';
export const FLUIDS_ALL = '/fluids/all';
export const FLUIDS_PROPERTIES = '/fluids/fluidProperties';
export const FLUIDS_CALC_PROPERTIES = '/fluids/calculateFluidProperties';

//xRef
export const XREF_PRODUCTS = '/xref/getxrefdata';
export const XREF_CALCULATE = '/xref/calculate';
export const XREF_MANUFS = '/xref/getcompetitormanufacturers';
export const XREF_PRODS_BY_MANUFS = '/xref/getproductsbymanufacturer';

export const WIKI_BASE = 'https://dthermx-wiki.swep.net';

//multicalc
export const MC_CALCULATIONS = '/calculation/multicalc/inputs';
export const MC_COLUMN_SETTINGS = '/profile/multicalc/columnsettings';
export const MC_CALCULATION_BYID = '/calculation/multicalc/inputs/bycalculation';

//feedback
export const FEEDBACK_SEND = '/feedback/sendFeedback';
