import {
  CALCULATION_LOAD,
  CALCULATION_CLONE,
  CALCULATION_XREF_LOAD,
  CALCULATION_ADD,
  CALCULATION_INPUTS_LOAD,
  CALCULATION_INPUTS_LOAD_ERROR,
  CALCULATION_INPUTS_LOAD_SUCCESS,
  CALCULATION_INPUTS_CHANGE,
  CALCULATION_RELOAD_HEAT_EXCHANGERS,
  CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS,
  CALCULATION_RELOAD_HEAT_EXCHANGERS_ERROR,
  CALCULATION_FILTERS_CHANGE,
  CALCULATION_SET_VALIDITY,
  CALCULATION_CALCULATE,
  CALCULATION_CALCULATE_SUCCESS,
  CALCULATION_CALCULATE_ERROR,
  CALCULATION_SELECTED_RESULT_CHANGE,
  CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD,
  CALCULATION_SELECTED_SUB_RESULT_CHANGE,
  CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS,
  CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE,
  CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS,
  CALCULATION_FROM_BASKET_LOAD,
  CALCULATION_FROM_BASKET_SUCCESS,
} from './constants';

export function loadCalculation(uuid, calculationType, saved) {
  return {
    type: CALCULATION_LOAD,
    uuid: uuid,
    calculationType: calculationType,
    saved: saved
  };
}

export function cloneCalculation(uuid, callback) {
  return {
    type: CALCULATION_CLONE,
    uuid: uuid,
    callback: callback
  };
}

export function loadCalculationXref(heatExchangerNames) {
  return {
    type: CALCULATION_XREF_LOAD,
    heatExchangerNames: heatExchangerNames
  };
}

export function addCalculation(calculation) {
  return {
    type: CALCULATION_ADD,
    calculation: calculation
  };

}

export function loadCalculationInputs(calculationType, uuid) {
  return {
    type: CALCULATION_INPUTS_LOAD,
    calculationType: calculationType,
    uuid: uuid,
  };
}

export function loadCalculationInputsSuccess(calculationType, uuid, inputs) {
  return {
    type: CALCULATION_INPUTS_LOAD_SUCCESS,
    calculationType: calculationType,
    uuid: uuid,
    inputs: inputs
  };
}

export function loadCalculationInputsError(uuid, error) {
  return {
      type: CALCULATION_INPUTS_LOAD_ERROR,
      uuid: uuid,
      error: error
  };
}

export function changeCalculationInputs(uuid, values){
  return {
    type: CALCULATION_INPUTS_CHANGE,
    uuid: uuid,
    values: values
  };
}

export function setCalculationValidity(uuid, isValid){
  return {
    type: CALCULATION_SET_VALIDITY,
    uuid: uuid,
    isValid: isValid
  };
}

export function reloadCalculationHeatExchangers(uuid, modelRestrictions, coCurrent, selectedFluidSide1, selectedFluidSide2){
  return {
    type: CALCULATION_RELOAD_HEAT_EXCHANGERS,
    uuid: uuid,
    modelRestrictions: modelRestrictions,
    coCurrent: coCurrent,
    selectedFluidSide1: selectedFluidSide1,
    selectedFluidSide2: selectedFluidSide2
  };
}

export function reloadCalculationHeatExchangersSuccess(uuid, heatExchangers){
  return {
    type: CALCULATION_RELOAD_HEAT_EXCHANGERS_SUCCESS,
    uuid: uuid,
    heatExchangers: heatExchangers
  };
}

export function reloadCalculationHeatExchangersError(uuid, error){
  return {
    type: CALCULATION_RELOAD_HEAT_EXCHANGERS_ERROR,
    uuid: uuid,
    error: error
  };
}

export function changeSelectedCalculationFilters(uuid, filterName, selections){
  return {
    type: CALCULATION_FILTERS_CHANGE,
    uuid: uuid,
    filterName: filterName,
    selections: selections
  };
}

export function calculate(uuid) {
  return {
    type: CALCULATION_CALCULATE,
    uuid: uuid
  };
}

export function calculateSuccess(uuid, data) {
  return {
    type: CALCULATION_CALCULATE_SUCCESS,
    uuid: uuid,
    data: data
  };
}

export function calculateError(uuid, error) {
  return {
    type: CALCULATION_CALCULATE_ERROR,
    uuid: uuid,
    error: error
  };
}

export function changeSelectedResult(uuid, selectedResult) {
  return {
    type: CALCULATION_SELECTED_RESULT_CHANGE,
    uuid: uuid,
    selectedResult: selectedResult
  };
}

export function changeSelectedSubResult(uuid, selectedResult, selectedSubResult) {
  return {
    type: CALCULATION_SELECTED_SUB_RESULT_CHANGE,
    uuid: uuid,
    selectedResult: selectedResult,
    selectedSubResult
  };
}

export function downloadProductSheet(xpc) {
  return {
    type: CALCULATION_SELECTED_RESULT_PRODUCT_SHEET_DOWNLOAD,
    xpc: xpc
  };
}

export function saveCalculationInputsToMyCalculations(uuid, customName, note, tags, callback) {
  return {
    type: CALCULATION_INPUTS_SAVE_TO_MY_CALCULATIONS,
    uuid: uuid,
    customName: customName,
    note: note,
    tags: tags,
    callback: callback
  };
}

export function saveCalculationInputsAsDefaultForCalculationType(uuid) {
  return {
    type: CALCULATION_INPUTS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
    uuid: uuid
  };
}

export function resetDefaultCalculationInputsForCalculationType(uuid) {
  return {
    type: CALCULATION_INPUTS_RESET_DEFAULT_FOR_CALCULATION_TYPE,
    uuid: uuid
  };
}

export function resetDefaultCalculationInputsForAllCalculations(uuid) {
  return {
    type: CALCULATION_INPUTS_RESET_DEFAULT_FOR_ALL_CALCULATIONS,
    uuid: uuid
  };
}

export function saveCalculationFiltersAsDefaultForCalculationType(uuid) {
  return {
    type: CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_CALCULATION_TYPE,
    uuid: uuid
  };
}

export function resetCalculationFiltersToDefaultForCalculationType(uuid) {
  return {
    type: CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_CALCULATION_TYPE,
    uuid: uuid
  };
}

export function saveCalculationFiltersAsDefaultForAllCalculations(uuid) {
  return {
    type: CALCULATION_FILTERS_SAVE_AS_DEFAULT_FOR_ALL_CALCULATIONS,
    uuid: uuid
  };
}

export function resetCalculationFiltersToDefaultForAllCalculations(uuid) {
  return {
    type: CALCULATION_FILTERS_RESET_TO_DEFAULT_FOR_ALL_CALCULATIONS,
    uuid: uuid
  };
}

export function loadCalculationFromBasket(uuid, basketCalculation, callback) {
  return {
    type: CALCULATION_FROM_BASKET_LOAD,
    uuid: uuid,
    basketCalculation: basketCalculation,
    callback: callback
  };
}

export function loadCalculationFromBasketSuccess(uuid, basketCalculation) {
  return {
    type: CALCULATION_FROM_BASKET_SUCCESS,
    uuid: uuid,
    basketCalculation: basketCalculation
  };
}
